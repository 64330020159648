import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { EmptyState } from "@/components/EmptyState";
import { Loader } from "@/components/Loader";
import { ProductRelationCreateDialog } from "@/components/Product/ProductDetails/ProductRelations/ProductRelationCreateDialog";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { groupByTypeName } from "@/helpers/product/groupProductsByTapeName";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

export const ProductRelatedProductsCard = ({ product }) => {
  const { id: productId } = useParams();
  const [productsGrouped, setProductsGrouped] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);

  const {
    isLoading,
    data: productRelations,
    error,
  } = useQuery({
    queryKey: ["product", productId, "relations"],
    queryFn: () => apiClient.getProductRelations(productId),
    staleTime: 1000 * 60 * 3,
  });

  useEffect(() => {
    if (!error && !isLoading && productRelations) {
      const relatedProductsMap = productRelations.data.flatMap((product) => product.relatedProduct);
      setRelatedProducts(relatedProductsMap);
      setProductsGrouped(groupByTypeName(relatedProductsMap));
    }
  }, [isLoading, productRelations, error]);

  return isLoading ? (
    <Loader />
  ) : (
    <Card className="flex-1 shadow-md h-fit">
      <CardHeader className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-1.5 w-2/3">
          <CardTitle>{i18n.t("Produkty powiązane")}</CardTitle>
          <CardDescription>{i18n.t("Produkty powiązane które są wyświetlane w kalkulatorach")}</CardDescription>
        </div>
        <ProductRelationCreateDialog product={product} relatedProducts={relatedProducts} />
      </CardHeader>
      <CardContent>
        {relatedProducts?.length === 0 && <EmptyState title="Brak produktów powiązanych" />}
        <Accordion type="multiple" collapsible defaultValue={["item-0"]}>
          {Object.keys(productsGrouped).map((group, index) => {
            return (
              <AccordionItem
                className="flex flex-col justify-center items-start mb-3 border-none"
                key={"group_" + index}
                value={`item-${index}`}
              >
                <AccordionTrigger className="hover:no-underline py-1">{group}</AccordionTrigger>
                <AccordionContent>
                  {productsGrouped[group].map((relatedProduct) => {
                    return (
                      <Link
                        key={relatedProduct.id}
                        to={`${routes.products}/${relatedProduct.id}`}
                        className="font-medium w-full"
                      >
                        <Button
                          variant="link"
                          className="h-fit max-w-[500px] truncate"
                          title={`${relatedProduct.name} (${relatedProduct.sku})`}
                        />
                      </Link>
                    );
                  })}
                </AccordionContent>
              </AccordionItem>
            );
          })}
        </Accordion>
      </CardContent>
    </Card>
  );
};
