import { Button } from "@/components/Button";
import { Badge } from "@/components/ui/badge";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { Check, CirclePlus } from "lucide-react";
import { forwardRef } from "react";

const MultipleSelect = forwardRef(
  ({ value, setValue, label, options, className, disabled, description, isLoading, ...rest }, ref) => {
    const selectedValues = new Set(value);

    const handleSelect = (optionValue) => {
      const newSelectedValues = new Set(selectedValues);
      if (newSelectedValues.has(optionValue)) {
        newSelectedValues.delete(optionValue);
      } else {
        newSelectedValues.add(optionValue);
      }
      setValue(Array.from(newSelectedValues));
    };

    if (!options || options?.length === 0) return null;

    return (
      <Popover>
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            variant="outline"
            disabled={isLoading || disabled}
            isLoading={isLoading}
            className={cn("w-full justify-start flex-1 lg:flex-initial h-fit", className)}
            leftIcon={selectedValues.size === 0 && <CirclePlus className="h-4 w-4" />}
          >
            {label}
            {selectedValues?.size > 0 && (
              <>
                <Separator orientation="vertical" className="h-4" />
                <Badge variant="secondary" className="rounded-sm px-1 font-normal lg:hidden">
                  {selectedValues.size}
                </Badge>
                <div className="hidden gap-1 lg:flex flex-wrap">
                  {selectedValues.size > 3 ? (
                    <>
                      {options
                        .filter((option) => selectedValues.has(option.value))
                        .slice(0, 3)
                        .map((option) => (
                          <Badge variant="secondary" key={option.value} className="rounded-sm px-1 font-normal h-fit">
                            {option.name}
                          </Badge>
                        ))}
                      <Badge
                        variant="secondary"
                        className="rounded-full flex flex-row justify-center items-center px-1 font-normal"
                      >
                        {`+${selectedValues.size - 3}`}
                      </Badge>
                    </>
                  ) : (
                    options
                      .filter((option) => selectedValues.has(option.value))
                      .map((option) => (
                        <Badge variant="secondary" key={option.value} className="rounded-sm px-1 font-normal h-fit">
                          {option.name}
                        </Badge>
                      ))
                  )}
                </div>
              </>
            )}
          </Button>
        </PopoverTrigger>
        {description && (
          <span className="text-sm text-muted-foreground mt-1">
            {typeof description === "string" ? i18n.t(description) : description}
          </span>
        )}
        <PopoverContent className="p-0" align="start">
          <Command
            filter={(v, search) => {
              const item = options.find((item) => item.value === v);
              if (!item) return 0;
              if (item.name.toLowerCase().includes(search.toLowerCase())) return 1;
            }}
            {...rest}
            onChange={() => {}}
          >
            <CommandInput placeholder={i18n.t("Wyszukaj...")} />
            <CommandList>
              <CommandEmpty>{i18n.t("Brak wyników")}</CommandEmpty>
              <CommandGroup>
                {options.map((option) => {
                  const isSelected = selectedValues.has(option.value);
                  return (
                    <CommandItem
                      value={option.value}
                      key={"option_" + option.name}
                      onSelect={() => handleSelect(option.value)}
                    >
                      <div
                        className={cn(
                          "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                          isSelected ? "bg-primary text-primary-foreground" : "opacity-50 [&_svg]:invisible",
                        )}
                      >
                        <Check className={cn("h-4 w-4")} />
                      </div>
                      {option.icon && <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />}
                      <span>{option.name}</span>
                    </CommandItem>
                  );
                })}
              </CommandGroup>
              {selectedValues.size > 0 && (
                <>
                  <CommandSeparator />
                  <CommandGroup>
                    <CommandItem onSelect={() => setValue([])} className="justify-center text-center">
                      {i18n.t("Wyczyść")}
                    </CommandItem>
                  </CommandGroup>
                </>
              )}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  },
);

MultipleSelect.displayName = "MultipleSelect";
export { MultipleSelect };
