import { ChartContainerWrapper } from "@/components/Charts/ChartContainerWrapper";
import { StatCardDescription } from "@/components/StatCardDescription";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ChartTooltip, ChartTooltipContent } from "@/components/ui/chart";
import { ContractProductContext } from "@/context/ContractProductContext";
import { slugify } from "@/helpers/stringHelpers";
import i18n from "@/i18n";
import { useContext } from "react";
import { Bar, BarChart, CartesianGrid, LabelList, Rectangle } from "recharts";

export const ContractProductStat = ({ dateRange, data, isLoading }) => {
  const max = { value: 0, index: 0 };
  const { products } = useContext(ContractProductContext);
  const chartData =
    data &&
    Object.keys(data).map((product, index) => {
      const value = data[product];
      if (data[product] > max.value) {
        max.index = index;
        max.value = value;
      }
      const contractProduct = products.find((p) => p.name === product);
      return {
        product: slugify(product),
        value,
        fill: contractProduct ? contractProduct.color ?? `var(--primary)` : `var(--primary)`,
        label: product,
      };
    });

  const chartConfig =
    data &&
    Object.keys(data).reduce(
      (config, key, index) => {
        const contractProduct = products.find((p) => p.name === key);
        config[slugify(key)] = {
          label: key,
          color: contractProduct ? contractProduct.color ?? `hsl(var(--chart-1))` : `hsl(var(--chart-${index + 1}))`,
        };
        return config;
      },
      { value: { label: i18n.t("Ilość") } },
    );

  return (
    <Card className="flex flex-col">
      <CardHeader className="items-center pb-0">
        <CardTitle>{i18n.t("Ilość umów per produkt")}</CardTitle>
        <StatCardDescription dateRange={dateRange} />
      </CardHeader>
      <CardContent className="flex-1">
        <ChartContainerWrapper
          isLoading={isLoading}
          data={chartData}
          config={chartConfig}
          className="mx-auto max-h-[350px] w-full"
        >
          <BarChart accessibilityLayer data={chartData}>
            <CartesianGrid vertical={false} />
            <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
            <Bar
              dataKey="value"
              strokeWidth={2}
              radius={8}
              activeIndex={max.index}
              activeBar={({ ...props }) => {
                return (
                  <Rectangle
                    {...props}
                    fillOpacity={0.8}
                    stroke={props.payload.fill}
                    strokeDasharray={4}
                    strokeDashoffset={4}
                  />
                );
              }}
            >
              <LabelList dataKey="label" offset={32} className="fill-foreground text-sm font-semibold" fontSize={12} />
            </Bar>
          </BarChart>
        </ChartContainerWrapper>
      </CardContent>
    </Card>
  );
};
