import apiClient from "@/api/apiClient";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { CalendarView } from "@/components/CalendarView";
import { Layout } from "@/components/layout/Layout";
import { Badge } from "@/components/ui/badge";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import { UsersContext } from "@/context/UsersContext";
import { getCalendarEvents } from "@/helpers/calendar/getCalendarEvents";
import { useAuth } from "@/hooks/useAuth";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { useUserPolicy } from "@/policies/user/useUserPolicy";
import { useQuery } from "@tanstack/react-query";
import { Check, XCircle } from "lucide-react";
import { useContext, useEffect, useState } from "react";

export const CalendarPage = () => {
  useDocumentTitle("Kalendarz");
  const { hasPermission, user: authUser } = useAuth();
  const [filters, setFilters] = useState({ user: authUser.id });
  const [events, setEvents] = useState([]);
  const { userOptions, isLoading: isLoadingUsers } = useContext(UsersContext);
  const [filteredUserOptions, setFilteredUserOptions] = useState([]);
  const userPolicy = useUserPolicy();

  const {
    isLoading,
    data: calendar,
    error,
  } = useQuery({
    queryKey: ["calendar", filters],
    queryFn: () => apiClient.getCalendar(filters),
    staleTime: 1000 * 60 * 5,
    enabled: hasPermission("access_calendar"),
  });

  useEffect(() => {
    if (!isLoadingUsers && userOptions) {
      setFilteredUserOptions(
        userOptions.map((userOption) => {
          if (userOption.value === authUser.id) {
            userOption.name = "Mój kalendarz";
          }
          return userOption;
        }),
      );
    }
  }, [isLoadingUsers, userOptions]);

  useEffect(() => {
    setEvents(() => []);
    if (!isLoading && calendar && !error) {
      setEvents(getCalendarEvents(calendar.data));
    }
  }, [isLoading, calendar, error]);

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col justify-between items-center sm:flex-row">
        <Breadcrumbs />
      </div>
      {hasPermission("access_calendar") && calendar && (
        <CalendarView
          events={events}
          Filters={
            userPolicy.viewCalendar() && (
              <>
                {filters.user && filters.user !== authUser.id && (
                  <Button
                    variant="outline"
                    leftIcon={<XCircle size={20} />}
                    size="icon"
                    onClick={() => setFilters({ user: authUser.id })}
                  />
                )}
                <div className="w-fit">
                  <CalendarUserFilter
                    title="Użytkownik"
                    isLoading={isLoadingUsers}
                    options={filteredUserOptions}
                    filters={filters}
                    value={filters.user}
                    setValue={(value) => setFilters({ user: value })}
                    placeholder="Użytkownik"
                    name="user"
                  />
                </div>
              </>
            )
          }
        />
      )}
    </Layout>
  );
};

export function CalendarUserFilter({ value, setValue, title, options, disabled = false, isLoading = false, ...rest }) {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  useEffect(() => {
    if (currentValue) setValue(currentValue);
  }, [currentValue]);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className={cn("flex-1 lg:flex-initial", currentValue && "border-primary")}
          disabled={disabled}
          isLoading={isLoading}
        >
          <>
            {title}
            {currentValue && (
              <>
                <Separator orientation="vertical" className="h-4" />
                <Badge variant="secondary" className="rounded-sm px-1 font-normal">
                  {options.find((option) => option.value === currentValue)?.name}
                </Badge>
              </>
            )}
          </>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0" align="start" {...rest}>
        <Command
          filter={(v, search) => {
            const item = options.find((item) => item.value === v);
            if (!item) return 0;
            if (item.name.toLowerCase().includes(search.toLowerCase())) return 1;
          }}
          {...rest}
          onChange={() => {}}
        >
          <CommandInput placeholder={i18n.t("Wyszukaj...")} />
          <CommandList>
            <CommandEmpty>{i18n.t("Brak wyników")}</CommandEmpty>
            <CommandGroup>
              {options.map((option) => {
                const isSelected = currentValue === option.value;
                return (
                  <CommandItem
                    key={option.value}
                    onSelect={() => {
                      setCurrentValue(option.value);
                      setValue(option.value);
                    }}
                  >
                    {isSelected && <Check className="mr-1 h-4 w-4" />}
                    {option.icon && <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />}
                    <span className={isSelected ? "" : "pl-5"}>{option.content ? option.content : option.name}</span>
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
