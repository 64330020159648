import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCreateSelect } from "@/components/forms/FormCreateSelect";
import { FormField } from "@/components/forms/FormField";
import { ErrorToast } from "@/components/toast/ErrorToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import i18n from "@/i18n";
import { useMetadataPolicy } from "@/policies/useMetadataPolicy";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Check, Plus, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object({
  metadata_id: Yup.string().required(i18n.t("Pole jest wymagane")),
  value: Yup.string().required(i18n.t("Pole jest wymagane")),
  unit: Yup.string().nullable(),
});

export const ProductMetadataCreateDialog = ({ product }) => {
  const queryClient = useQueryClient();
  const metadataPolicy = useMetadataPolicy();
  const { id: productId } = useParams();

  const [metadataOptions, setMetadataOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const handleCreateMetadata = (name) => {
    const data = { name };
    createMetadataMutation.mutate({ data });
  };

  const onSubmit = (data) => {
    createProductMetadataMutation.mutate({ productId, data });
  };

  const defaultValues = {
    metadata_id: undefined,
    value: "",
    unit: "",
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const {
    isLoading: isLoadingMetadata,
    data: metadata,
    error: errorMetadata,
  } = useQuery({
    queryKey: ["metadata"],
    queryFn: apiClient.getMetadata,
    staleTime: 1000 * 60 * 5,
    enabled: metadataPolicy.viewAny(),
  });

  const createMetadataMutation = useMutation({
    mutationFn: apiClient.createMetadata,
    onSuccess: (res) => {
      if (res.ok) {
        form.setValue("metadata_id", res.data.id);
        queryClient.invalidateQueries({ queryKey: ["metadata"] });
        queryClient.invalidateQueries({ queryKey: ["products"] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const createProductMetadataMutation = useMutation({
    mutationFn: apiClient.createProductMetadata,
    onSuccess: (res) => {
      if (res.ok) {
        setIsOpen(false);
        form.reset();
        queryClient.invalidateQueries({ queryKey: ["product", productId], exact: true });
        queryClient.invalidateQueries({ queryKey: ["products"] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  useEffect(() => {
    if (!isLoadingMetadata && !errorMetadata && metadata) {
      const productMetadataIds = product.metadata.map((metadata) => metadata.id);
      setMetadataOptions(
        metadata.data
          .filter((metadata) => !productMetadataIds.includes(metadata.id))
          .map((metadata) => ({ name: metadata.name, value: metadata.id })),
      );
    }
  }, [isLoadingMetadata, metadata, errorMetadata, isOpen]);

  if (!metadataPolicy.create()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <DialogTrigger asChild>
        <Button variant="outline" title="Dodaj" leftIcon={<Plus size={20} />} />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{i18n.t("Dodaj metadane")}</DialogTitle>
          <DialogDescription>{i18n.t("Tworzenie dodatkowych atrybutów produktu.")}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            <FormCreateSelect
              onCreate={handleCreateMetadata}
              name="metadata_id"
              isLoading={isLoadingMetadata}
              label="Nazwa"
              options={metadataOptions}
              placeholder="Wybierz atrybut"
            />
            <FormField name="value" label="Wartość" />
            <FormField name="unit" label="Jednostka" placeholder="np. szt. lub mb" />
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Zapisz"
              leftIcon={<Check size={20} />}
              isLoading={createProductMetadataMutation.isPending}
            />
            <Button
              title="Anuluj"
              type="button"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
