import apiClient from "@/api/apiClient";
import clientSignature from "@/assets/signatures/signature_client_dark.svg";
import companySignature from "@/assets/signatures/signature_company_dark.svg";
import { Button } from "@/components/Button";
import { SignatureWithBackground } from "@/components/Signature/SignatureWithBackground";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import i18n from "@/i18n";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Check, ChevronLeft, ChevronRight, Signature, X } from "lucide-react";
import { useState } from "react";
import { useParams } from "react-router-dom";

export const SurveySignatureDialog = ({ surveyEntryId }) => {
  const queryClient = useQueryClient();
  const { id: calculationId } = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const [clientSignatureData, setClientSignatureData] = useState("");
  const [companySignatureData, setCompanySignatureData] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  const handleSubmit = () => {
    const data = {
      company_signature: companySignatureData,
      client_signature: clientSignatureData,
    };
    createSurveyEntrySignatureMutation.mutate({ surveyEntryId, data });
  };

  const createSurveyEntrySignatureMutation = useMutation({
    mutationFn: apiClient.createSurveyEntrySignature,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["calculation", calculationId, "surveys"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Ankieta została podpisana." /> });
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <DialogTrigger asChild>
        <Button title="Podpisz ankietę" type="button" leftIcon={<Signature size={20} />} />
      </DialogTrigger>
      <DialogContent className="max-w-4xl">
        <DialogHeader>
          <DialogTitle>{i18n.t("Podpisywanie ankiety")}</DialogTitle>
          <DialogDescription>{i18n.t("Podpisanie ankiety przez obie strony.")}</DialogDescription>
        </DialogHeader>
        <div className="flex flex-col justify-between">
          <div className="flex flex-col gap-4 mb-5 justify-center items-center">
            {currentStep === 0 && (
              <SignatureWithBackground backgroundImage={companySignature} onSave={setCompanySignatureData} />
            )}
            {currentStep === 1 && (
              <SignatureWithBackground backgroundImage={clientSignature} onSave={setClientSignatureData} />
            )}
            {currentStep === 2 && (
              <div className="flex flex-row p-2 gap-2 justify-between items-center bg-white">
                <img className="flex-1 w-[49%]" src={companySignatureData} />
                <img className="flex-1 w-[49%]" src={clientSignatureData} />
              </div>
            )}
          </div>
        </div>
        <DialogFooter>
          {currentStep !== 0 && (
            <Button
              type="button"
              title="Powrót"
              leftIcon={<ChevronLeft size={20} />}
              onClick={() => setCurrentStep(currentStep - 1)}
            />
          )}
          {currentStep === 0 && (
            <Button
              type="button"
              title="Dalej"
              disabled={companySignatureData.length === 0}
              rightIcon={<ChevronRight size={20} />}
              onClick={() => setCurrentStep(currentStep + 1)}
            />
          )}
          {currentStep === 1 && (
            <Button
              type="button"
              title="Dalej"
              disabled={clientSignatureData.length === 0}
              rightIcon={<ChevronRight size={20} />}
              onClick={() => setCurrentStep(currentStep + 1)}
            />
          )}
          {currentStep === 2 && (
            <Button
              type="button"
              title="Zapisz podpisy"
              isLoading={createSurveyEntrySignatureMutation.isPending}
              leftIcon={<Check size={20} />}
              onClick={handleSubmit}
            />
          )}
          <Button
            type="button"
            title="Anuluj"
            leftIcon={<X size={20} />}
            variant="destructive"
            onClick={() => setIsOpen(false)}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
