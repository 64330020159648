import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCheckbox } from "@/components/forms/FormCheckbox";
import { FormNumberField } from "@/components/forms/FormNumberField";
import { FormSelect } from "@/components/forms/FormSelect";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { ContractStatusContext } from "@/context/ContractStatusContext";
import i18n from "@/i18n";
import { useBillingSettingPolicy } from "@/policies/billing/useBillingSettingPolicy";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Check, X } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object({
  contract_status_id: Yup.string().required(i18n.t("Pole jest wymagane")),
  amount: Yup.string().nullable(),
  percentage: Yup.string().nullable(),
  from_comission: Yup.boolean().required(i18n.t("Pole jest wymagane")),
});

export const BillingSettingRuleEditDialog = ({ billingSettingRule, trigger }) => {
  const queryClient = useQueryClient();
  const { id: billingSettingId } = useParams();
  const billingSettingPolicy = useBillingSettingPolicy();
  const { statusOptions } = useContext(ContractStatusContext);

  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = (data) => {
    if (type === "percentage") {
      data.amount = null;
      data.from_comission = false;
    } else if (type === "amount") {
      data.percentage = null;
    }
    updateBillingSettingRuleMutation.mutate({ billingSettingId, billingSettingRuleId: billingSettingRule.id, data });
  };

  const defaultValues = {
    contract_status_id: billingSettingRule.contractStatus.id,
    type: billingSettingRule.type,
    amount: billingSettingRule.amount,
    percentage: billingSettingRule.percentage,
    from_comission: billingSettingRule.from_comission,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onSubmit",
  });
  const { type, amount, percentage } = form.watch();

  const updateBillingSettingRuleMutation = useMutation({
    mutationFn: apiClient.updateBillingSettingRule,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["billingSetting", billingSettingId] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie zapisano." /> });
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  useEffect(() => {
    if (percentage) form.setValue("amount");
    if (amount) form.resetField("percentage");
  }, [type]);

  if (!billingSettingPolicy.update()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Edycja reguły")}</DialogTitle>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            <FormSelect label="Status" options={statusOptions} name="contract_status_id" autoComplete="off" />
            <FormSelect
              options={[
                { name: "Kwota", value: "amount" },
                { name: "Procent", value: "percentage" },
              ]}
              name="type"
              label="Rodzaj wartości rozliczenia"
            />
            {type === "percentage" && (
              <FormNumberField label="Wartość procentowa prowizji" placeholder="Np. 10%" name="percentage" />
            )}
            {type === "amount" && (
              <>
                <FormNumberField label="Kwota" placeholder="Np. 1000zł" name="amount" />
                <FormCheckbox
                  name="from_comission"
                  label="Wartość ma być brana z prowizji"
                  description="Jeśli opcja jest zaznaczona to wartość rozliczenia będzie się liczyć z prowizji na umowie"
                />
              </>
            )}
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Zapisz"
              leftIcon={<Check size={20} />}
              isLoading={updateBillingSettingRuleMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
