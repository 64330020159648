import { useAuth } from "@/hooks/useAuth";

export const useSurveyEntryPolicy = () => {
  const { hasPermission } = useAuth();

  const viewAny = () => {
    return hasPermission("access_survey_entries");
  };

  const create = () => {
    return hasPermission("create_survey_entry");
  };

  const update = () => {
    return hasPermission("update_survey_entry");
  };

  const destroy = () => {
    return hasPermission("delete_survey_entry");
  };

  return {
    viewAny,
    create,
    update,
    destroy,
  };
};
