import { NotFoundPage } from "@/pages/NotFoundPage";
import { AccountRoutes } from "@/routes/AccountRoutes";
import { ApplicationRoutes } from "@/routes/ApplicationRoutes";
import { AuditRoutes } from "@/routes/AuditRoutes";
import { AuthRoutes } from "@/routes/AuthRoutes";
import { BillingRoutes } from "@/routes/BillingRoutes";
import { CalculationRoutes } from "@/routes/CalculationRoutes";
import { CalculatorRoutes } from "@/routes/CalculatorRoutes";
import { CalendarRoutes } from "@/routes/CalendarRoutes";
import { ClientRoutes } from "@/routes/ClientRoutes";
import { ContractRoutes } from "@/routes/ContractRoutes";
import { DashboardRoutes } from "@/routes/DashboardRoutes";
import { DepartmentRoutes } from "@/routes/DepartmentRoutes";
import { FileRoutes } from "@/routes/FileRoutes";
import { InstallationRoutes } from "@/routes/InstallationRoutes";
import { LeadRoutes } from "@/routes/LeadRoutes";
import { NotificationRoutes } from "@/routes/NotificationRoutes";
import { OrderRoutes } from "@/routes/OrderRoutes";
import { ProductRoutes } from "@/routes/ProductRoutes";
import { ProtectedRoute } from "@/routes/ProtectedRoute";
import { SurveyRoutes } from "@/routes/SurveyRoutes";
import { TaskRoutes } from "@/routes/TaskRoutes";
import { UserRoutes } from "@/routes/UserRoutes";
import { WarehouseRoutes } from "@/routes/WarehouseRoutes";
import { Route, Routes } from "react-router-dom";

export const AppRoutes = () => {
  return (
    <Routes>
      {AuthRoutes()}
      <Route exact path="/" element={<ProtectedRoute />}>
        {AccountRoutes()}
        {DashboardRoutes()}
        {DepartmentRoutes()}
        {UserRoutes()}
        {LeadRoutes()}
        {CalendarRoutes()}
        {TaskRoutes()}
        {FileRoutes()}
        {ContractRoutes()}
        {CalculatorRoutes()}
        {CalculationRoutes()}
        {NotificationRoutes()}
        {WarehouseRoutes()}
        {ProductRoutes()}
        {OrderRoutes()}
        {BillingRoutes()}
        {InstallationRoutes()}
        {AuditRoutes()}
        {ApplicationRoutes()}
        {ClientRoutes()}
        {SurveyRoutes()}
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
