import apiClient from "@/api/apiClient";
import { useAuth } from "@/hooks/useAuth";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { createContext, useEffect, useState } from "react";

export const ContractFileCategoryContext = createContext();

export function ContractFileCategoryProvider({ children }) {
  const [contractFileCategories, setFileCategories] = useState([]);
  const [contractFileCategoryOptions, setFileCategoryOptions] = useState([]);
  const { user } = useAuth();

  const {
    isLoading,
    data: response,
    error,
  } = useQuery({
    queryKey: ["contractFileCategories"],
    queryFn: apiClient.getContractFileCategories,
    staleTime: 1000 * 60 * 30,
    enabled: user !== null,
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    if (!isLoading && !error && response) {
      setFileCategories(response.data);
      setFileCategoryOptions(response.data.map((status) => ({ name: status.name, value: status.id })));
    }
  }, [response, isLoading, error]);

  const getFileCategoriesForProducts = (productIds) => {
    return contractFileCategories.filter(
      (contractFileCategory) =>
        contractFileCategory.contractProduct === null || productIds.includes(contractFileCategory.contractProduct?.id),
    );
  };

  return (
    <ContractFileCategoryContext.Provider
      value={{ contractFileCategories, isLoading, contractFileCategoryOptions, getFileCategoriesForProducts }}
    >
      {children}
    </ContractFileCategoryContext.Provider>
  );
}
