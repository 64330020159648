class SurveyEntryBuilder {
  constructor(survey) {
    this.survey = survey;
    this.questions = survey.sections.flatMap((section) => section.questions);
  }

  setValues(values) {
    this.survey.sections.forEach((section) => {
      section.questions.forEach((question) => {
        if (values[question.identifier] !== undefined) {
          question.value = values[question.identifier];
        }
      });
    });
    return this;
  }

  getQuestions() {
    return this.survey.sections.flatMap((section) => section.questions);
  }

  build() {
    return this.survey;
  }
}

export default SurveyEntryBuilder;
