import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { formatMoney } from "@/helpers/formatMoney";
import i18n from "@/i18n";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Trash } from "lucide-react";
import { useParams } from "react-router-dom";

export const CalculatorDiscount = ({ discount }) => {
  const queryClient = useQueryClient();
  const { id: calculatorId } = useParams();

  const deleteCalculatorDiscountMutation = useMutation({
    mutationFn: apiClient.deleteCalculatorDiscount,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["calculator", calculatorId] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const handleDelete = () => {
    deleteCalculatorDiscountMutation.mutate({ calculatorDiscountId: discount.id, calculatorId });
  };

  return (
    <Card className="w-full h-fit break-inside-avoid">
      <CardHeader className="flex flex-row justify-between">
        <div className="flex flex-col gap-1.5">
          <CardTitle>{i18n.t("Rabat")}</CardTitle>
          <CardDescription>{discount.name}</CardDescription>
        </div>
        <div className="flex flex-row justify-end items-start">
          <DeleteAlertDialog
            trigger={
              <Button
                leftIcon={<Trash size={16} />}
                title="Usuń rabat"
                variant="outline"
                isLoading={deleteCalculatorDiscountMutation.isPending}
              />
            }
            onConfirm={handleDelete}
            message="Czy na pewno chcesz usunąć ten rabat? Tej operacji nie można cofnąć"
          />
        </div>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col gap-1">
          <div className="flex flex-row gap-1 items-center">
            <p className="flex flex-row w items-center font-medium">{i18n.t("Jeżeli wartość pola")}</p>
            <p className="font-medium border py-1 px-1 rounded-md text-primary">{discount.range_conditions.field}</p>
          </div>
          <div className="flex flex-col">
            {discount.range_conditions.ranges.map((range, i) => (
              <div key={"range" + i} className="flex flex-row gap-2 items-center">
                <p>{i18n.t("W zakresie od")}</p>
                <p className="font-medium border py-1 px-1 rounded-md text-primary">{range.from}</p>
                <p>{i18n.t("do")}</p>
                <p className="font-medium border py-1 px-1 rounded-md text-primary">{range.to}</p>
                <p>{i18n.t("to wtedy rabat")}</p>
                <p className="font-medium border py-1 px-1 rounded-md text-primary">{formatMoney(range.amount)}</p>
              </div>
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
