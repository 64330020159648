import apiClient from "@/api/apiClient";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { InstallationFileCreateDialog } from "@/components/Installations/InstallationDetails/Files/InstallationFileCreateDialog";
import { InstallationDetails } from "@/components/Installations/InstallationDetails/InstallationDetails";
import { InstallationEditDialog } from "@/components/Installations/InstallationEditDialog";
import { Layout } from "@/components/layout/Layout";
import { TaskCreateDialog } from "@/components/Tasks/TaskCreateDialog";
import { ButtonContext } from "@/context/ButtonContext";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useInstallationPolicy } from "@/policies/installation/useInstallationPolicy";
import { routes } from "@/routes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Pencil, Trash } from "lucide-react";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const InstallationDetailsPage = () => {
  const { id: installationId } = useParams();
  const navigate = useNavigate();
  const { buttons } = useContext(ButtonContext);
  const installationPolicy = useInstallationPolicy();
  const queryClient = useQueryClient();

  const { isLoading, data: response } = useQuery({
    queryKey: ["installation", installationId],
    queryFn: () => apiClient.getInstallation(installationId),
  });
  useDocumentTitle(response?.data ? `Montaż ${response?.data?.name}` : null);

  const deleteInstallationMutation = useMutation({
    mutationFn: apiClient.deleteInstallation,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["installations"] });
      if (res.ok) {
        return navigate(routes.installations);
      }
    },
  });

  const handleDelete = () => {
    deleteInstallationMutation.mutate(installationId);
  };

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs idAlias={response?.data?.name !== " " ? response?.data?.name : "Brak"} />
        <div
          id="installation_buttons"
          className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5"
        >
          {buttons["addInstallationFiles"] && <InstallationFileCreateDialog />}
          <TaskCreateDialog
            taskableId={installationId}
            taskableType="Installation"
            withUser={true}
            buttonVariant="outline"
          />
          <InstallationEditDialog
            installation={response?.data}
            trigger={<Button variant="outline" title="Edytuj montaż" leftIcon={<Pencil size={20} />} />}
          />
          {installationPolicy.destroy(response?.data) && (
            <DeleteAlertDialog
              trigger={
                <Button
                  variant="destructive"
                  title="Usuń montaż"
                  isLoading={deleteInstallationMutation.isPending}
                  leftIcon={<Trash size={20} />}
                />
              }
              onConfirm={handleDelete}
              message="Czy na pewno chcesz usunąć ten montaż? Tej operacji nie można cofnąć"
            />
          )}
        </div>
      </div>
      <InstallationDetails installation={response?.data} />
    </Layout>
  );
};
