import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import { getFileExtensionIcon } from "@/helpers/files/getFileExtensionIcon";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { CloudUpload, X } from "lucide-react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";

export const FileUploadInput = ({
  id = "dropzone-file",
  accept = {},
  multiple = true,
  onFilesSelect = () => {},
  selectedFiles = [],
  ...rest
}) => {
  const [files, setFiles] = useState(selectedFiles);

  const onDrop = (newFiles) => {
    const updatedFiles = multiple ? [...files, ...newFiles] : newFiles;
    setFiles(updatedFiles);
    onFilesSelect(updatedFiles);
  };

  const removeFile = (fileToRemove) => {
    const updatedFiles = files.filter((file) => file !== fileToRemove);
    setFiles(updatedFiles);
    onFilesSelect(updatedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple,
  });

  return (
    <Card className="flex flex-col sm:flex-row sm:items-center justify-start w-full p-3 gap-3">
      <div className="w-fit">
        <label
          {...getRootProps()}
          className={cn(
            "flex flex-row gap-2 px-4 py-2.5 items-center justify-center border-2 border-border bg-card rounded-lg cursor-pointer hover:bg-secondary whitespace-nowrap text-sm",
            isDragActive && "bg-card border-dashed border-primary",
          )}
        >
          <CloudUpload size={24} />
          {multiple ? i18n.t("Wybierz pliki") : i18n.t("Wybierz plik")}
        </label>

        <Input {...getInputProps()} {...rest} id={id} className="hidden" />
      </div>
      <div className="w-full">
        <p className="font-medium text-muted-foreground text-sm">{i18n.t("Przesłane pliki")}</p>
        <ScrollArea className="w-full flex max-h-36 flex-col overflow-y-auto">
          <div className="space-y-2">
            {files.length === 0 && i18n.t("Brak")}
            {files.map((file, index) => (
              <div
                key={file.name + index}
                className="flex justify-between gap-2 rounded-lg overflow-hidden border border-border group hover:pr-0 pr-2"
              >
                <div className="flex items-center flex-1 p-2">
                  <div className="text-gray-600">
                    <img className="w-6 h-6" src={getFileExtensionIcon(file.type.split("/")[1])} alt="" />
                  </div>

                  <div className="w-full ml-2 space-y-1">
                    <p className="text-sm text-foreground truncate">{file.name}</p>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => removeFile(file)}
                  className="bg-destructive text-white transition-all items-center justify-center cursor-pointer px-2 hidden group-hover:flex"
                >
                  <X size={20} />
                </button>
              </div>
            ))}
          </div>
        </ScrollArea>
      </div>
    </Card>
  );
};
