export const groupByTypeName = (records) => {
  return records.reduce((acc, record) => {
    const key = record?.type?.name || "undefined";
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(record);
    return acc;
  }, {});
};
