import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { FileViewer } from "@/components/File/FileViewer";
import { EditableSelectCell } from "@/components/Table/EditableSelectCell";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { UserAvatarLink } from "@/components/Users/UserAvatarLink";
import { BillingGroupStatus } from "@/enums/BillingGroupStatusEnum";
import { getFileExtensionIcon } from "@/helpers/files/getFileExtensionIcon";
import { formatMoney } from "@/helpers/formatMoney";
import { useAuth } from "@/hooks/useAuth";
import i18n from "@/i18n";
import { useBillingGroupPolicy } from "@/policies/billing/useBillingGroupPolicy";
import { useFilePolicy } from "@/policies/file/useFilePolicy";
import { format, parse } from "date-fns";
import { MoreHorizontal, Trash } from "lucide-react";

export const useBillingGroupsTableColumns = (handleDelete, handleEdit) => {
  const billingGroupPolicy = useBillingGroupPolicy();
  const { hasPermission } = useAuth();
  const filePolicy = useFilePolicy();

  const columns = [
    {
      id: "status",
      name: "Status",
      accessorKey: "status",
      header: <span>{i18n.t("Status")}</span>,
      cell: ({ row }) => {
        const billingGroup = row.original;
        const handleChangeStatus = (value) => {
          if (value !== billingGroup.status) handleEdit(billingGroup.id, { status: value });
        };
        return hasPermission("update_billing_group") && billingGroup.status !== BillingGroupStatus.SETTLED.value ? (
          <div className="w-full">
            <EditableSelectCell
              className={BillingGroupStatus[billingGroup.status].color}
              onSave={handleChangeStatus}
              options={BillingGroupStatus.getValues()}
              value={billingGroup.status}
            />
          </div>
        ) : (
          <span className="flex flex-row gap-1 items-center font-medium">
            {BillingGroupStatus[billingGroup.status].icon()}
            {BillingGroupStatus[billingGroup.status].name}
          </span>
        );
      },
    },
    {
      id: "amount",
      name: "Kwota",
      accessorKey: "amount",
      header: <span>{i18n.t("Kwota")}</span>,
      cell: ({ row }) => {
        const billingGroup = row.original;
        return (
          <span className="flex flex-row gap-1 items-center">
            {billingGroup?.amount ? formatMoney(billingGroup?.amount) : null}
          </span>
        );
      },
    },
    {
      id: "files",
      name: "Pliki",
      accessorKey: "files",
      header: <span>{i18n.t("Pliki")}</span>,
      cell: ({ row }) => {
        const files = row.original.files;
        const fileIds = files?.map((file) => file.id);
        return (
          <div className="flex flex-col gap-3">
            {files?.length === 0 && i18n.t("Brak")}
            {files.map((file) => (
              <div key={file.id} className="flex flex-row gap-1">
                <img className="w-6 h-6" src={getFileExtensionIcon(file.extension)} />
                {filePolicy.download() ? (
                  <FileViewer fileIds={fileIds} currentFile={row.index}>
                    <a className="font-bold flex flex-row gap-1 items-center cursor-pointer hover:underline">
                      {row.getValue("original_name")}
                    </a>
                  </FileViewer>
                ) : (
                  <span>{row.getValue("original_name")}</span>
                )}
              </div>
            ))}
          </div>
        );
      },
    },
    hasPermission("access_all_billing_groups")
      ? {
          id: "user_id",
          name: "Użytkownik",
          accessorKey: "user_id",
          enableSorting: false,
          header: () => <div className="px-4">{i18n.t("Użytkownik")}</div>,
          cell: ({ row }) => {
            const user = row.original.user;
            return <UserAvatarLink user={user} />;
          },
        }
      : { id: "user_id" },
    {
      id: "created_at",
      name: "Data utworzenia",
      accessorKey: "created_at",
      header: <span className="text-right"> {i18n.t("Data utworzenia")} </span>,
      cell: ({ row }) => (
        <span className="text-right">
          {format(parse(row.getValue("created_at"), "yyyy-MM-dd HH:mm:ss", new Date()), "dd LLL yyyy HH:mm")}
        </span>
      ),
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const billingGroup = row.original;
        if (!billingGroupPolicy.destroy(billingGroup)) return null;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {billingGroupPolicy.destroy(billingGroup) && (
                <DeleteAlertDialog
                  onConfirm={() => handleDelete(billingGroup.id)}
                  message="Czy na pewno chcesz usunąć to rozliczenie? Tej operacji nie można cofnąć"
                  trigger={
                    <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                      <Trash size={16} className="mr-1 text-primary" />
                      <span className="font-semibold">{i18n.t("Usuń")}</span>
                    </DropdownMenuItem>
                  }
                />
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
