import { EmptyState } from "@/components/EmptyState";
import { InstallationServiceInformationCard } from "@/components/Installations/InstallationServiceInformationCard";
import { ButtonContext } from "@/context/ButtonContext";
import { useContext, useEffect } from "react";

export const ContractInstallationServicesTab = ({ contract }) => {
  const { showButton, hideButton } = useContext(ButtonContext);

  useEffect(() => {
    showButton("addInstallationService");

    return () => {
      hideButton("addInstallationService");
    };
  }, []);

  return (
    <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-5">
      {contract.installationServices?.length === 0 && <EmptyState title="Brak serwisów" />}
      {contract.installationServices &&
        contract.installationServices.map((installationService) => (
          <InstallationServiceInformationCard key={installationService.id} installationService={installationService} />
        ))}
    </div>
  );
};
