import { SurveyQuestion } from "@/components/Surveys/Layout/Questions/SurveyQuestion";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";

export const SurveySection = ({ section }) => {
  const questions = section.questions;

  return (
    <Card className="w-full h-fit break-inside-avoid mb-5">
      <CardHeader>
        <CardTitle>{section.name}</CardTitle>
        <CardDescription>{section.description}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col space-y-6">
          {questions.map((question) => (
            <SurveyQuestion question={question} key={question.id} />
          ))}
        </div>
      </CardContent>
    </Card>
  );
};
