import { SurveyProvider } from "@/context/SurveyContext";
import { CalculationDetailsPage } from "@/pages/Calculations/CalculationDetailsPage";
import { CalculationSurveysPage } from "@/pages/Calculations/CalculationSurveysPage";
import { routes } from "@/routes";
import { Route } from "react-router-dom";

export const CalculationRoutes = () => (
  <>
    <Route exact path={routes.calculation} element={<CalculationDetailsPage />} />
    <Route
      exact
      path={routes.calculationSurveys}
      element={
        <SurveyProvider>
          <CalculationSurveysPage />
        </SurveyProvider>
      }
    />
  </>
);
