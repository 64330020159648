import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCheckbox } from "@/components/forms/FormCheckbox";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { toast } from "@/components/ui/use-toast";
import i18n from "@/i18n";
import { useCalculatorPolicy } from "@/policies/useCalculatorPolicy";
import { useSurveyPolicy } from "@/policies/useSurveyPolicy";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Check, ClipboardPen, Plus, X } from "lucide-react";
import { useEffect, useState } from "react";
import { Controller, useForm, useFormContext } from "react-hook-form";
import * as Yup from "yup";

const validationSchema = Yup.object({
  surveys: Yup.array().of(
    Yup.object({
      survey_id: Yup.string().required(i18n.t("Pole jest wymagane")),
      required: Yup.boolean().required(i18n.t("Pole jest wymagane")),
    }).required(i18n.t("Pole jest wymagane")),
  ),
});

export const CalculatorSurveyDialog = ({ calculator }) => {
  const queryClient = useQueryClient();
  const calculatorPolicy = useCalculatorPolicy();
  const surveyPolicy = useSurveyPolicy();

  const [isOpen, setIsOpen] = useState(false);
  const [surveyOptions, setSurveyOptions] = useState([]);

  const onSubmit = (data) => {
    updateCalculatorSurveyMutation.mutate({ calculatorId: calculator.id, data });
  };

  const {
    isLoading,
    data: surveys,
    error,
  } = useQuery({
    queryKey: ["surveys"],
    queryFn: apiClient.getSurveys,
    staleTime: 1000 * 60 * 5,
    enabled: surveyPolicy.viewAny(),
  });

  const defaultValues = {
    surveys: calculator.surveys.map((survey) => ({ survey_id: survey.id, required: survey.required })),
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onSubmit",
  });

  const updateCalculatorSurveyMutation = useMutation({
    mutationFn: apiClient.updateCalculatorSurvey,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["calculators"] });
      queryClient.invalidateQueries({ queryKey: ["calculator", calculator.id] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie zapisano." /> });
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  useEffect(() => {
    if (!isLoading && surveys && !error) {
      setSurveyOptions(
        surveys.data.map((survey) => ({
          name: survey.name,
          value: survey.id,
        })),
      );
    }
  }, [isLoading, surveys, error]);

  if (!calculatorPolicy.update() || !surveyPolicy.viewAny()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <DialogTrigger asChild>
        <Button variant="outline" title="Ankiety" leftIcon={<ClipboardPen size={20} />} />
      </DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Ankiety")}</DialogTitle>
          <DialogDescription>{i18n.t("Ankiety związane z tym kalkulatorem")}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            <FormSurveyOptions
              placeholder="Ankiety"
              name="surveys"
              autoComplete="off"
              surveyOptions={surveyOptions}
              isLoading={isLoading}
            />
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Zapisz"
              leftIcon={<Check size={20} />}
              isLoading={updateCalculatorSurveyMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

const FormSurveyOptions = ({ name, label, surveyOptions, isLoading, className }) => {
  const {
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
  } = useFormContext();

  const emptyOption = { survey_id: "", required: false };

  const options = watch(name) || [emptyOption];

  const addOption = () => {
    const currentOptions = getValues(name) || [emptyOption];
    setValue(name, [...currentOptions, emptyOption]);
  };

  const removeOption = (index) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setValue(name, updatedOptions.length > 0 ? updatedOptions : [emptyOption]);
  };

  const handleOptionChange = (index, field, value) => {
    const updatedOptions = [...options];
    updatedOptions[index][field] = value;
    setValue(name, updatedOptions);
  };

  useEffect(() => {
    if (!options || options.length === 0) {
      setValue(name, [emptyOption]);
    }
  }, []);

  const getFilteredProductOptions = (currentIndex) => {
    const selectedProductIds = options.map((option, index) => (index !== currentIndex ? option.survey_id : null));
    return surveyOptions.filter((option) => !selectedProductIds.includes(option.value));
  };

  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <div className={className}>
          <Label htmlFor={name} className={errors[name] && "text-destructive"}>
            {i18n.t(label)}
          </Label>
          <div className="space-y-5 mt-1">
            {options.map((option, index) => (
              <div key={index} className="flex gap-3 items-center">
                <div className="flex-1">
                  <FormCombobox
                    name={`${name}[${index}].survey_id`}
                    options={getFilteredProductOptions(index)}
                    isLoading={isLoading}
                    setCurrentValue={(value) => handleOptionChange(index, "survey_id", value)}
                  />
                </div>
                <div className="">
                  <FormCheckbox
                    label="Wymagana"
                    name={`${name}[${index}].required`}
                    value={option.required}
                    setCurrentValue={(value) => handleOptionChange(index, "required", value)}
                  />
                </div>
                {options.length > 1 && (
                  <Button
                    type="button"
                    variant="ghost"
                    className="text-destructive px-3 shrink-0"
                    leftIcon={<X size={16} className="shrink-0" />}
                    onClick={() => removeOption(index)}
                  />
                )}
              </div>
            ))}
          </div>
          <Button
            type="button"
            variant="link"
            onClick={addOption}
            className="m-0 pl-2 gap-1"
            leftIcon={<Plus size={16} />}
            title="Dodaj ankietę"
          />
        </div>
      )}
    />
  );
};
