import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { Select } from "@/components/forms/Select";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { CardRow } from "@/components/ui/CardRow";
import { toast } from "@/components/ui/use-toast";
import { UserTooltip } from "@/components/Users/UserTooltip";
import { ContractStatusContext } from "@/context/ContractStatusContext";
import { isAddressNotEmpty } from "@/helpers/address/isAddressNotEmpty";
import { capitalize } from "@/helpers/capitalize";
import { formatPhoneNumber } from "@/helpers/formatPhoneNumber";
import i18n from "@/i18n";
import { useContractPolicy } from "@/policies/contracts/useContractPolicy";
import { useLeadPolicy } from "@/policies/lead/useLeadPolicy";
import { routes } from "@/routes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { format, parse } from "date-fns";
import { useContext } from "react";
import { Link, useParams } from "react-router-dom";

export const ContractInformationCard = ({ contract }) => {
  const { id: contractId } = useParams();
  const queryClient = useQueryClient();
  const leadPolicy = useLeadPolicy();
  const contractPolicy = useContractPolicy();
  const { statusOptions, isLoading } = useContext(ContractStatusContext);

  const handleStatusClick = (value) => {
    if (value) {
      const data = { contract_status_id: value };
      updateContractStatusMutation.mutate({ contractId, data });
    }
  };

  const updateContractStatusMutation = useMutation({
    mutationFn: apiClient.updateContractsStatus,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["contracts"] });
      queryClient.invalidateQueries({ queryKey: ["contract", contract.id], exact: true });
      if (!res.ok) {
        toast({ title: <ErrorToast title="Błąd podczas zapisywania." /> });
      }
    },
  });

  return (
    <Card className="flex-1 h-fit shadow-md">
      <CardHeader>
        <CardTitle>{i18n.t("info")}</CardTitle>
        <CardDescription>{i18n.t("contract.contract_info")}</CardDescription>
      </CardHeader>
      <CardContent className="divide-y divide-y-border">
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("contract.identifier")}</p>
          <p>{contract.identifier}</p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("contract.name")}</p>
          <p>{contract.name}</p>
        </CardRow>
        {contract.lead && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("contract.client")}</p>
            <Link to={`${routes.clients}/${contract.lead.id}`}>
              <Button variant="link" size="xs">
                {contract.lead.name}
              </Button>
            </Link>
          </CardRow>
        )}
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("contract.created_at")}</p>
          <p title={contract.created_at}>
            {format(parse(contract.created_at, "yyyy-MM-dd HH:mm:ss", new Date()), "dd MMM yyyy")}
          </p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("contract.signed_at")}</p>
          <p>{format(parse(contract.signed_at, "yyyy-MM-dd", new Date()), "dd MMM yyyy")}</p>
        </CardRow>
        {leadPolicy.assignUserLeads() && contract.created_by && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("contract.created_by")}</p>
            <UserTooltip user={contract.created_by} />
          </CardRow>
        )}
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("contract.status")}</p>
          {contractPolicy.updateStatus() || contract.processes.length === 0 ? (
            <Select
              className="w-fit text-primary-foreground dark:text-foreground dark:bg-opacity-70"
              style={{ backgroundColor: contract.status.color }}
              options={statusOptions}
              setValue={handleStatusClick}
              value={contract.status.id}
              isLoading={updateContractStatusMutation.isPending || isLoading}
            />
          ) : (
            <Badge className="h-8 rounded-md" style={{ backgroundColor: contract.status.color }}>
              {contract.status.name}
            </Badge>
          )}
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("contract.personal_identity_number")}</p>
          <p>{contract.personal_identity_number}</p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("contract.address")}</p>
          {isAddressNotEmpty(contract) ? (
            <div className="flex flex-col justify-end sm:items-end">
              <span className="flex gap-1 items-center">
                <p className="italic text-xs font-bold">{i18n.t("contract.street_number")}</p>
                {contract?.address?.street_number ?? i18n.t("none")}
              </span>
              <span className="flex gap-1 items-center">
                <p className="italic text-xs font-bold">{i18n.t("contract.postal_code")}</p>
                {contract?.address?.postal_code ?? i18n.t("none")}
              </span>
              <span className="flex gap-1 items-center">
                <p className="italic text-xs font-bold">{i18n.t("contract.city")}</p>
                {contract?.address?.city ?? i18n.t("none")}
              </span>
              <span className="flex gap-1 items-center">
                <p className="italic text-xs font-bold">{i18n.t("contract.voivodeship")}</p>
                {contract?.address?.voivodeship ? capitalize(contract.address.voivodeship) : i18n.t("none")}
              </span>
            </div>
          ) : (
            <p>{i18n.t("none")}</p>
          )}
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("contract.phone_number")}</p>
          {contract.phone_number ? (
            <a className="font-semibold" href={`tel:${contract.phone_number}`}>
              {formatPhoneNumber(contract.phone_number)}
            </a>
          ) : (
            <p>{i18n.t("none")}</p>
          )}
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("contract.email")}</p>
          {contract.email ? (
            <a className="font-semibold" href={`mailto:${contract.email}`}>
              {contract.email}
            </a>
          ) : (
            <p>{i18n.t("none")}</p>
          )}
        </CardRow>
        {contract.calculation && (
          <>
            <CardRow>
              <p className="text-xs sm:text-base font-medium">{i18n.t("Kalkulacja")}</p>
              <Link to={`${routes.contracts}/${contract.id}/kalkulacja`} state={{ contract }}>
                <Button variant="link" size="xs">
                  {contract.calculation.name}
                </Button>
              </Link>
            </CardRow>
          </>
        )}
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("contract.products")}</p>
          <div className="flex flex-row gap-2 items-center justify-end flex-wrap">
            {contract.products.map((product) => (
              <p
                key={product.id}
                style={{ backgroundColor: product.color }}
                className="px-2 py-1 w-fit rounded-md font-medium whitespace-nowrap text-primary-foreground dark:text-foreground dark:bg-opacity-60"
              >
                {product.name}
              </p>
            ))}
          </div>
        </CardRow>
        {contract.description && (
          <CardRow className="flex lg:flex-col lg:items-start gap-1">
            <p className="text-xs sm:text-base font-medium">{i18n.t("contract.description")}</p>
            <p className="text-muted-foreground">{contract.description}</p>
          </CardRow>
        )}
      </CardContent>
    </Card>
  );
};
