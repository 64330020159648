import { Button } from "@/components/Button";
import { ErrorMessage } from "@/components/forms/ErrorMessage";
import { FormCheckbox } from "@/components/forms/FormCheckbox";
import { FormField } from "@/components/forms/FormField";
import { Label } from "@/components/ui/label";
import { ScrollArea } from "@/components/ui/scroll-area";
import i18n from "@/i18n";
import { Plus, X } from "lucide-react";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

export const FormList = ({ name, label, className, withDefaults = true }) => {
  const {
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
  } = useFormContext();
  const emptyOption = { name: "", default: false };

  const options = watch(name) || [emptyOption];

  const addOption = () => {
    const currentOptions = getValues(name) || [emptyOption];
    setValue(name, [...currentOptions, emptyOption]);
  };

  const removeOption = (index) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setValue(name, updatedOptions.length > 0 ? updatedOptions : [emptyOption]);
  };

  const handleOptionChange = (index, field, value) => {
    const updatedOptions = [...options];
    updatedOptions[index][field] = value;

    if (field === "default" && value) {
      updatedOptions.forEach((option, i) => {
        if (i !== index) {
          option.default = false;
        }
      });
    }

    setValue(name, updatedOptions);
  };

  useEffect(() => {
    if (!options || options.length === 0) {
      setValue(name, [emptyOption]);
    }
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <div className={className}>
          <Label htmlFor={name} className={errors[name] && "text-destructive"}>
            {i18n.t(label)}
          </Label>
          <ScrollArea className="flex max-h-56 flex-col">
            <div className="space-y-2 mt-1 ml-1">
              {options.map((option, index) => (
                <div key={index} className="flex gap-2 items-center">
                  <FormField
                    name={`${name}[${index}].name`}
                    placeholder={i18n.t("Nazwa")}
                    value={option.name}
                    onChange={(e) => handleOptionChange(index, "name", e.target.value)}
                  />
                  {withDefaults && (
                    <div>
                      <FormCheckbox
                        name={`${name}[${index}].default`}
                        id={option.name + "_" + index}
                        label="Domyślna"
                        checked={option.default}
                        onCheckedChange={(checked) => handleOptionChange(index, "default", checked)}
                      />
                    </div>
                  )}
                  {options.length > 1 && (
                    <Button
                      type="button"
                      variant="ghost"
                      className="text-destructive px-2 shrink-0"
                      leftIcon={<X size={16} className="shrink-0" />}
                      onClick={() => removeOption(index)}
                    />
                  )}
                </div>
              ))}
            </div>
          </ScrollArea>
          <Button
            type="button"
            variant="link"
            onClick={addOption}
            className="m-0 pl-2 gap-1"
            leftIcon={<Plus size={16} />}
            title="Dodaj opcję"
          />
          <ErrorMessage error={errors[name]?.message} />
        </div>
      )}
    />
  );
};
