export const formatMoney = (value, currency = "zł") => {
  if (value === null || value === undefined) {
    return null;
  }
  const amount = typeof value === "number" ? value : parseFloat(value.replace(/,/g, "."));

  if (isNaN(amount)) {
    return null;
  }

  if (amount % 1 === 0) {
    return amount.toLocaleString("pl-PL") + " " + currency;
  }

  const formatterNumber = amount.toLocaleString("pl-PL", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  return formatterNumber + " " + currency;
};
