import { FileUploadInput } from "@/components/forms/FileUploadInput";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { ContractFileCategoryContext } from "@/context/ContractFileCategoryContext";
import i18n from "@/i18n";
import { Asterisk } from "lucide-react";
import { useContext } from "react";

export const ContractFilesCreateForm = ({ productIds, files, setFiles }) => {
  const { getFileCategoriesForProducts } = useContext(ContractFileCategoryContext);

  const contractFileCategories = getFileCategoriesForProducts(productIds);

  const requiredContractFiles = contractFileCategories
    .filter(({ required }) => required)
    .map(({ fileCategory }) => fileCategory);
  const optionalContractFiles = contractFileCategories
    .filter(({ required }) => !required)
    .map(({ fileCategory }) => fileCategory);

  return (
    <Card className="h-fit">
      <CardHeader>
        <CardTitle className="text-base text-primary">{i18n.t("Pliki")}</CardTitle>
        <CardDescription>{i18n.t("Pliki z audytu zostaną dołączone automatycznie.")}</CardDescription>
      </CardHeader>
      <CardContent className="space-y-5">
        {requiredContractFiles.map((category, index) => (
          <div className="space-y-2" key={`contract_required_file_${index}`}>
            <Label htmlFor={category.id} className="flex flex-row items-center mb-1">
              {category.name}
              <Asterisk size={20} className="text-destructive" />
            </Label>
            <FileUploadInput id={category.id} onFilesSelect={(f) => setFiles({ ...files, [category.id]: f })} />
          </div>
        ))}
        {optionalContractFiles.map((category, index) => (
          <div className="space-y-2" key={`contract_optional_file_${index}`}>
            <Label htmlFor={category.id} className="flex flex-row items-center mb-1">
              {category.name}
            </Label>
            <FileUploadInput id={category.id} onFilesSelect={(f) => setFiles({ ...files, [category.id]: f })} />
          </div>
        ))}
      </CardContent>
    </Card>
  );
};
