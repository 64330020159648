import { ContractStatusContext } from "@/context/ContractStatusContext";
import { FileCategoryContext } from "@/context/FileCategoryContext";
import { Settings } from "@/enums/SettingsEnum";
import i18n from "@/i18n";
import { useContext } from "react";

export const useSettingValueTableColum = (setting) => {
  const { statuses: contractStatuses } = useContext(ContractStatusContext);
  const { categories: fileCategories } = useContext(FileCategoryContext);

  const key = setting.key;
  if (key === Settings.CONTRACT_POINTS_STATUS) {
    const status = contractStatuses.find((s) => s.id === setting.value);
    return (
      <span
        className="rounded-md px-2 py-1 font-semibold whitespace-nowrap text-primary-foreground dark:text-foreground dark:bg-opacity-60"
        style={{ backgroundColor: status.color }}
      >
        {status.name}
      </span>
    );
  } else if (key === Settings.CONTRACT_ORDER_STATUS) {
    const status = contractStatuses.find((s) => s.id === setting.value);
    return (
      <span
        className="rounded-md px-2 py-1 font-semibold whitespace-nowrap text-primary-foreground dark:text-foreground dark:bg-opacity-60"
        style={{ backgroundColor: status.color }}
      >
        {status.name}
      </span>
    );
  } else if (key === Settings.APPLICATION_CONTRACT_FILE_CATEGORIES) {
    return setting.value.length > 0 ? (
      <div className="flex flex-col gap-1">
        {setting.value.map((v, index) => (
          <span key={`file_category_${index}`}>{fileCategories.find((category) => category.id === v)?.name}</span>
        ))}
      </div>
    ) : (
      i18n.t("Brak")
    );
  } else if (key === Settings.INSTALLATION_CONTRACT_FILE_CATEGORIES) {
    return setting.value.length > 0 ? (
      <div className="flex flex-col gap-1">
        {setting.value.map((v, index) => (
          <span key={`file_category_${index}`}>{fileCategories.find((category) => category.id === v)?.name}</span>
        ))}
      </div>
    ) : (
      i18n.t("Brak")
    );
  } else return i18n.t("Nieobsługiwane ustawienie, skontaktuj się z administratorem");
};
