import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Layout } from "@/components/layout/Layout";
import { ProductImportForm } from "@/components/Product/ProductImportForm";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";

export const ProductImportPage = () => {
  useDocumentTitle("Import produktów");
  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
      </div>
      <div>
        <ProductImportForm />
      </div>
    </Layout>
  );
};
