import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormGroupMultipleSelect } from "@/components/forms/FormGroupMultipleSelect";
import { ErrorToast } from "@/components/toast/ErrorToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { ProductsContext } from "@/context/ProductsContext";
import i18n from "@/i18n";
import { useProductPolicy } from "@/policies/useProductPolicy";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Check, Plus, X } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object({
  related_product_ids: Yup.array().optional(),
});

export const ProductRelationCreateDialog = ({ product, relatedProducts }) => {
  const queryClient = useQueryClient();
  const productPolicy = useProductPolicy();
  const { id: productId } = useParams();
  const { products, isLoading: isLoadingProducts } = useContext(ProductsContext);
  const [productOptions, setProductOptions] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [localFormData, setLocalFormData] = useState({
    related_product_ids: relatedProducts.map((prod) => prod?.id),
  });

  const onSubmit = (data) => {
    createProductRelationMutation.mutate({ productId, data });
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: localFormData,
    mode: "onBlur",
  });

  const createProductRelationMutation = useMutation({
    mutationFn: apiClient.createProductRelation,
    onSuccess: (res) => {
      if (res.ok) {
        setIsOpen(false);
        queryClient.invalidateQueries({ queryKey: ["products"] });
        queryClient.invalidateQueries({ queryKey: ["product", productId, "relations"] });
        queryClient.invalidateQueries({ queryKey: ["product", productId], exact: true });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  useEffect(() => {
    if (products && !isLoadingProducts) {
      const grouped = products
        .filter((prod) => prod.id !== product.id)
        .reduce((acc, product) => {
          const groupName = product.type.name;
          if (!acc[groupName]) {
            acc[groupName] = [];
          }
          acc[groupName].push({ name: `${product.name} (${product.sku})`, value: product.id });
          return acc;
        }, {});
      setProductOptions(grouped);
    }
  }, []);

  useEffect(() => {
    setLocalFormData({
      related_product_ids: relatedProducts.map((prod) => prod?.id),
    });
  }, [relatedProducts]);

  useEffect(() => {
    if (isOpen) {
      form.reset(localFormData);
    } else {
      setLocalFormData(form.getValues());
    }
  }, [isOpen]);

  if (!productPolicy.update()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <DialogTrigger asChild>
        <Button variant="outline" title="Dodaj" leftIcon={<Plus size={20} />} />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{i18n.t("Dodaj produkty powiązane")}</DialogTitle>
          <DialogDescription>{product.name}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            <FormGroupMultipleSelect
              name="related_product_ids"
              isLoading={isLoadingProducts}
              label="Produkty"
              groupOptions={productOptions}
              placeholder="Wybierz produkty powiązane"
            />
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Zapisz"
              leftIcon={<Check size={20} />}
              isLoading={createProductRelationMutation.isPending}
            />
            <Button
              title="Anuluj"
              type="button"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
