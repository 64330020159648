import apiClient from "@/api/apiClient";
import { TaskFinishedStat } from "@/components/Tasks/Stats/TaskFinishedStat";
import { TaskUnfinishedStat } from "@/components/Tasks/Stats/TaskUnfinishedStat";
import { useTaskPolicy } from "@/policies/useTaskPolicy";
import { useQuery } from "@tanstack/react-query";

export const TasksStats = ({ dateRange }) => {
  const taskPolicy = useTaskPolicy();

  const { isLoading, data: response } = useQuery({
    queryKey: ["tasksStats", dateRange],
    queryFn: () => apiClient.getTasksStats(dateRange),
    staleTime: 1000 * 60 * 5,
    enabled: taskPolicy.viewStats(),
  });

  return (
    <div className="w-full flex flex-col gap-5">
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-5">
        <TaskFinishedStat dateRange={dateRange} data={response?.data?.finished_stat} isLoading={isLoading} />
        <TaskUnfinishedStat dateRange={dateRange} data={response?.data?.unfinished_stat} isLoading={isLoading} />
      </div>
    </div>
  );
};
