import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { OrderItemEditDialog } from "@/components/Order/OrderDetails/Items/OrderItemEditDialog";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { toast } from "@/components/ui/use-toast";
import { OrderStatus } from "@/enums/OrderStatusEnum";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { useWarehousePolicy } from "@/policies/warehouse/useWarehousePolicy";
import { routes } from "@/routes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Pencil, X } from "lucide-react";
import { Link, useParams } from "react-router-dom";

export const OrderItem = ({ order, item }) => {
  const { id: orderId } = useParams();
  const queryClient = useQueryClient();
  const warehousePolicy = useWarehousePolicy();

  const deleteOrderItemMutation = useMutation({
    mutationFn: apiClient.deleteOrderItem,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["order", orderId, "items"], exact: true });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const handleDelete = () => {
    deleteOrderItemMutation.mutate({ orderId, itemId: item.id });
  };

  return (
    <div className="flex flex-row gap-3 items-center">
      {order.status === OrderStatus.NEW.value && (
        <Button
          variant="destructive"
          size="icon"
          leftIcon={<X size={16} className="shrink-0" />}
          onClick={handleDelete}
        />
      )}
      <div className="grid grid-cols-5 items-end gap-3 w-full font-medium">
        <Link to={`${routes.products}/${item.product.id}`} className="w-fit col-span-3">
          <Button
            title={`${item.product.name} (${item.product.sku})`}
            variant="link"
            className="m-0 p-0 h-fit truncate max-w-[100px] sm:max-w-[200px] xl:max-w-[500px]"
          />
        </Link>
        <span>{item.quantity}</span>
        <span className={cn(!item.warehouse && "text-destructive")}>
          {item.warehouse ? (
            warehousePolicy.view() ? (
              <Link to={`${routes.warehouses}/${item.warehouse?.id}`}>
                <Button title={item.warehouse.name} variant="link" className="h-fit m-0 p-0" />
              </Link>
            ) : (
              <span>{item.warehouse.name}</span>
            )
          ) : (
            <span className="text-destructive">{i18n.t("Brak magazynu")}</span>
          )}
        </span>
      </div>
      {order.status === OrderStatus.NEW.value && (
        <OrderItemEditDialog
          trigger={<Button variant="outline" size="icon" leftIcon={<Pencil size={16} />} />}
          item={item}
        />
      )}
    </div>
  );
};
