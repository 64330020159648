import { Button } from "@/components/Button";
import { CardRow } from "@/components/ui/CardRow";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import i18n from "@/i18n";
import { useInstallationCrewPolicy } from "@/policies/installation/useInstallationCrewPolicy";
import { useInstallationServicePolicy } from "@/policies/installation/useInstallationServicePolicy";
import { routes } from "@/routes";
import { format, parse } from "date-fns";
import { ArrowRight } from "lucide-react";
import { Link } from "react-router-dom";

export const InstallationServiceInformationCard = ({ installationService }) => {
  const installationCrewPolicy = useInstallationCrewPolicy();
  const installationServicePolicy = useInstallationServicePolicy();

  return (
    <Card className="shadow-md">
      <CardHeader className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-1.5">
          <CardTitle>{installationService.name}</CardTitle>
          <CardDescription>{i18n.t("Informacje o serwisie")}</CardDescription>
        </div>
        {installationServicePolicy.view() && (
          <Link to={`${routes.installationServices}/${installationService.id}`}>
            <Button variant="outline" title="Przejdź do serwisu" rightIcon={<ArrowRight size={16} />} />
          </Link>
        )}
      </CardHeader>
      <CardContent className="divide-y divide-y-border">
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Nazwa")}</p>
          <p>{installationService.name}</p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Data serwisu")}</p>
          <p>
            {installationService.service_date
              ? format(parse(installationService.service_date, "yyyy-MM-dd", new Date()), "dd MMM yyyy")
              : i18n.t("Nie ustalono")}
          </p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Powtarza się co")}</p>
          <p>{`${installationService.repeat_interval} ${i18n.t("miesięcy")}`}</p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Ekipa montażowa")}</p>
          <p>
            {installationService.installationCrew ? (
              installationCrewPolicy.view() ? (
                <Link to={`${routes.installationCrews}/${installationService.installationCrew.id}`}>
                  <Button variant="link" size="xs" title={installationService.installationCrew.name} />
                </Link>
              ) : (
                <p>{installationService.installationCrew.name}</p>
              )
            ) : (
              i18n.t("Nie przypisano")
            )}
          </p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Data utworzenia serwisu")}</p>
          <p title={installationService.created_at}>
            {format(parse(installationService.created_at, "yyyy-MM-dd HH:mm:ss", new Date()), "dd MMM yyyy")}
          </p>
        </CardRow>
        {installationService.description && (
          <CardRow className="flex lg:flex-col lg:items-start gap-1">
            <p className="text-xs sm:text-base font-medium">{i18n.t("Opis")}</p>
            <p className="text-muted-foreground">{installationService.description}</p>
          </CardRow>
        )}
      </CardContent>
    </Card>
  );
};
