import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { EditableCell } from "@/components/Table/EditableCell";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { formatMoney } from "@/helpers/formatMoney";
import i18n from "@/i18n";
import { useLeadSourcePolicy } from "@/policies/lead/useLeadSourcePolicy";
import { ArrowDown, ArrowUp, ArrowUpDown, MoreHorizontal, Trash } from "lucide-react";

export const useLeadSourcesTableColumns = (handleEdit, handleDelete) => {
  const leadSourcePolicy = useLeadSourcePolicy();
  const columns = [
    {
      id: "name",
      name: "Nazwa",
      size: 10,
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Nazwa")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => (
        <div className="flex-1 ms-3 truncate" title={row.original.name}>
          {row.getValue("name")}
        </div>
      ),
    },
    {
      id: "cost",
      name: "Koszt",
      accessorKey: "cost",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Koszt")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const leadSource = row.original;
        const onSave = (cost) => {
          handleEdit(leadSource.id, { cost });
        };

        if (leadSourcePolicy.update())
          return <EditableCell formatter={formatMoney} value={leadSource.cost} onSave={onSave} />;
        else return leadSource.cost !== null ? formatMoney(leadSource.cost) : 0;
      },
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const leadSource = row.original;
        if (!leadSourcePolicy.destroy()) return null;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {leadSourcePolicy.destroy() && (
                <DeleteAlertDialog
                  onConfirm={() => handleDelete(leadSource.id)}
                  message="Czy na pewno chcesz usunąć ten status? Tej operacji nie można cofnąć"
                  trigger={
                    <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                      <Trash size={16} className="mr-1 text-primary" />
                      <span className="font-semibold">{i18n.t("Usuń")}</span>
                    </DropdownMenuItem>
                  }
                />
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
