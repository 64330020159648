import { CalculatorField } from "@/components/Calculators/Layout/Fields/CalculatorField";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";

export const CalculatorSection = ({ section }) => {
  const fields = section.fields;

  return (
    <Card className="w-full h-fit break-inside-avoid mb-5">
      <CardHeader>
        <CardTitle>{section.name}</CardTitle>
        <CardDescription>{section.description}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col space-y-6">
          {fields.map((field) => (
            <CalculatorField field={field} sectionFields={fields} key={field.id} />
          ))}
        </div>
      </CardContent>
    </Card>
  );
};
