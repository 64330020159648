import apiClient from "@/api/apiClient";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { EmptyState } from "@/components/EmptyState";
import { Layout } from "@/components/layout/Layout";
import { Survey } from "@/components/Surveys/Survey";
import { SurveyCreateDialog } from "@/components/Surveys/SurveyCreateDialog";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useSurveyPolicy } from "@/policies/useSurveyPolicy";
import { useQuery } from "@tanstack/react-query";

export const SurveysPage = () => {
  useDocumentTitle("Ankiety");
  const surveyPolicy = useSurveyPolicy();

  const { data: surveys, isLoading } = useQuery({
    queryKey: ["surveys"],
    queryFn: apiClient.getSurveys,
    staleTime: 1000 * 60 * 5,
    enabled: surveyPolicy.viewAny(),
  });

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          <SurveyCreateDialog />
        </div>
      </div>
      {surveys?.data?.length === 0 && <EmptyState title="Brak ankiet" />}
      {surveys?.data?.map((survey) => {
        return <Survey key={survey.id} survey={survey} />;
      })}
    </Layout>
  );
};
