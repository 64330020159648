import { Button } from "@/components/Button";
import { SettingEditDialog } from "@/components/Settings/SettingEditDialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useSettingValueTableColum } from "@/hooks/table/useSettingValueTableColum";
import i18n from "@/i18n";
import { useSettingPolicy } from "@/policies/useSettingPolicy";
import { ArrowDown, ArrowUp, ArrowUpDown, MoreHorizontal, Pencil } from "lucide-react";

export const useSettingsTableColumns = () => {
  const settingPolicy = useSettingPolicy();
  const columns = [
    {
      id: "key",
      name: "Ustawienie",
      size: 10,
      accessorKey: "key",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Ustawienie")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => i18n.t(`settings.${row.original.key}`),
    },
    {
      id: "value",
      name: "Wartość",
      size: 10,
      accessorKey: "value",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Wartość")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => useSettingValueTableColum(row.original),
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const setting = row.original;
        if (!settingPolicy.update()) return null;

        return (
          <DropdownMenu modal={true}>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <SettingEditDialog
                setting={setting}
                trigger={
                  <DropdownMenuItem className="flex flex-row items-center w-full" onSelect={(e) => e.preventDefault()}>
                    <Pencil size={16} className="mr-1 text-primary" />
                    <span className="font-semibold">{i18n.t("Edytuj")}</span>
                  </DropdownMenuItem>
                }
              />
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
