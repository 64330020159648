import apiClient from "@/api/apiClient";
import { CalendarView } from "@/components/CalendarView";
import { FullPageLoader } from "@/components/FullPageLoader";
import { routes } from "@/routes";
import { useQuery } from "@tanstack/react-query";
import { parse } from "date-fns";
import { CalendarClock, UserCog, Wrench } from "lucide-react";
import { useEffect, useState } from "react";

export const InstallationsCalendar = () => {
  const [events, setEvents] = useState([]);
  const filters = {
    columnFilters: [{ id: "withActions", value: true }],
  };

  const {
    isLoading: isLoadingInstallations,
    data: installations,
    error: errorInstallations,
  } = useQuery({
    queryKey: ["installations", filters],
    queryFn: () => apiClient.getInstallations(filters),
  });

  const {
    isLoading: isLoadingInstallationServices,
    data: installationServices,
    error: errorInstallationServices,
  } = useQuery({
    queryKey: ["installationServices", filters],
    queryFn: () => apiClient.getInstallationServices(filters),
  });

  useEffect(() => {
    setEvents(() => []);
    if (!isLoadingInstallations && installations && !errorInstallations) {
      if (installations.data.length) {
        const actions = installations.data.flatMap((installation) => installation.actions);
        setEvents((prevEvents) => [
          ...prevEvents,
          ...actions.map((action) => ({
            title: `${action.name} - ${
              installations.data.find((ins) => ins.id === action.actionable_id)?.contract?.name
            }`,
            link: `${routes.installations}/${action?.actionable?.id}`,
            icon: (props) => <CalendarClock {...props} />,
            date: parse(action.action_at, "yyyy-MM-dd HH:mm:ss", new Date()),
            description: action.description,
            name: "Działanie montażu",
            className: "bg-yellow-600 hover:bg-yellow-600/90",
          })),
          ...installations.data
            .filter((installation) => installation.date)
            .map((installation) => ({
              title: `Montaż - ${installation.name}`,
              link: `${routes.installations}/${installation?.id}`,
              icon: (props) => <Wrench {...props} />,
              date: parse(installation.date, "yyyy-MM-dd", new Date()),
              description: installation.description,
              name: "Montaż",
              className: "bg-blue-500 hover:bg-blue-500/90",
            })),
        ]);
      }
      if (!isLoadingInstallationServices && installationServices && !errorInstallationServices) {
        if (installationServices.data.length) {
          setEvents((prevEvents) => [
            ...prevEvents,
            ...installationServices.data
              .filter((installation) => installation.service_date)
              .map((installationService) => ({
                title: `Serwis - ${installationService.name}`,
                link: `${routes.installationServices}/${installationService?.id}`,
                icon: (props) => <UserCog {...props} />,
                date: parse(installationService.service_date, "yyyy-MM-dd", new Date()),
                description: installationService.description,
                name: "Serwis",
                className: "bg-indigo-500 hover:bg-indigo-500/90",
              })),
          ]);
        }
      }
    }
  }, [
    isLoadingInstallations,
    installations,
    errorInstallations,
    isLoadingInstallationServices,
    installationServices,
    errorInstallationServices,
  ]);

  return isLoadingInstallations ? <FullPageLoader /> : <CalendarView events={events} />;
};
