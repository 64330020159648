import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { formatMoney } from "@/helpers/formatMoney";
import i18n from "@/i18n";
import { useProductPolicy } from "@/policies/useProductPolicy";
import { routes } from "@/routes";
import { ArrowDown, ArrowUp, ArrowUpDown, Eye, EyeOff, MoreHorizontal, Pencil, Trash } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";

export const useProductsTableColumns = (handleDelete) => {
  const productPolicy = useProductPolicy();
  const navigate = useNavigate();

  const columns = [
    {
      id: "sku",
      name: "SKU",
      accessorKey: "sku",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("SKU")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        if (productPolicy.view(row.original)) {
          return (
            <Link
              to={`${routes.products}/${row.original.id}`}
              className="flex flex-row items-center gap-3 font-medium hover:underline"
            >
              {row.getValue("sku")}
            </Link>
          );
        } else {
          return <span>{row.getValue("name")}</span>;
        }
      },
    },
    {
      id: "name",
      name: "Nazwa produktu",
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Nazwa produktu")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        if (productPolicy.view(row.original)) {
          return (
            <Link
              to={`${routes.products}/${row.original.id}`}
              className="flex flex-row items-center gap-3 font-medium hover:underline"
            >
              {row.getValue("name")}
            </Link>
          );
        } else {
          return <span>{row.getValue("name")}</span>;
        }
      },
    },
    {
      id: "type",
      name: "Typ",
      accessorKey: "type",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Typ")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const product = row.original;
        return <span>{product.type.name}</span>;
      },
    },
    {
      id: "total_quantity",
      name: "Ilość produktu w magazynach",
      accessorKey: "total_quantity",
      header: i18n.t("Ilość produktu w magazynach"),
      cell: ({ row }) => {
        const product = row.original;
        if (product.warehouses_count) {
          if (product.total_quantity !== null) {
            return parseInt(row.getValue("total_quantity"));
          } else {
            return i18n.t("W magazynie zewnętrznym");
          }
        } else {
          return i18n.t("Nie dodany");
        }
      },
    },
    {
      id: "price",
      name: "Cena",
      accessorKey: "price",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Cena")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => formatMoney(row.getValue("price")),
    },
    {
      id: "available",
      name: "Produkt widoczny",
      accessorKey: "available",
      header: i18n.t("Produkt widoczny"),
      cell: ({ row }) => {
        const product = row.original;
        return product.available ? (
          <span className="flex flex-row items-center gap-2 text-green-500">
            <Eye size={16} />
            {i18n.t("Tak")}
          </span>
        ) : (
          <span className="flex flex-row items-center gap-2 text-destructive">
            <EyeOff size={16} />
            {i18n.t("Nie")}
          </span>
        );
      },
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const product = row.original;
        if (!productPolicy.update() && !productPolicy.destroy(product)) return null;

        return (
          <DropdownMenu modal={true}>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {productPolicy.update() && (
                <DropdownMenuItem
                  className="flex flex-row items-center w-full"
                  onClick={() => navigate(`${routes.products}/${product.id}/edytuj`)}
                >
                  <Pencil size={16} className="mr-1 text-primary" />
                  <span className="font-semibold">{i18n.t("Edytuj")}</span>
                </DropdownMenuItem>
              )}
              {productPolicy.destroy(product) && (
                <>
                  <DropdownMenuSeparator />
                  <DeleteAlertDialog
                    onConfirm={() => handleDelete(product.id)}
                    message="Czy na pewno chcesz usunąć ten produkt? Tej operacji nie można cofnąć"
                    trigger={
                      <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                        <Trash size={16} className="mr-1 text-primary" />
                        <span className="font-semibold">{i18n.t("Usuń")}</span>
                      </DropdownMenuItem>
                    }
                  />
                </>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
