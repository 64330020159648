import apiClient from "@/api/apiClient";
import { EmptyState } from "@/components/EmptyState";
import { FullPageLoader } from "@/components/FullPageLoader";
import { InstallationInformationCard } from "@/components/Installations/InstallationInformationCard";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const ClientInstallationsTab = () => {
  const { id: clientId } = useParams();

  const { isLoading, data: installations } = useQuery({
    queryKey: ["client", clientId, "installations"],
    queryFn: () => apiClient.getClientInstallations(clientId),
  });

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-5">
      {installations.data?.length === 0 && <EmptyState title="Brak montaży" />}
      {installations.data.map((installation) => (
        <InstallationInformationCard key={installation.id} installation={installation} />
      ))}
    </div>
  );
};
