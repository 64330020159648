import apiClient from "@/api/apiClient";
import { Table } from "@/components/Table/Table";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { useContractTemplatesTableColumns } from "@/hooks/table/useContractTemplatesTableColumns";
import { useContractTemplatePolicy } from "@/policies/contracts/useContractTemplatePolicy";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { saveAs } from "file-saver";

export const ContractTemplates = () => {
  const contractTemplatePolicy = useContractTemplatePolicy();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const queryClient = useQueryClient();

  const { isLoading, data: templates } = useQuery({
    queryKey: ["contractTemplates"],
    queryFn: apiClient.getContractTemplates,
    enabled: contractTemplatePolicy.viewAny(),
  });

  const deleteContractTemplateMutation = useMutation({
    mutationFn: apiClient.deleteContractTemplate,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["contractTemplates"] });
        toast({ title: <SuccessToast title="Pomyślnie usunięto" /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const handleDelete = (contractTemplateId) => {
    deleteContractTemplateMutation.mutate({ contractTemplateId });
  };

  const handleDownload = async (template) => {
    const response = await apiClient.downloadContractTemplate(template.id);
    if (response.ok) {
      saveAs(response.data, template.filename);
      toast({ title: <SuccessToast title="Szablon został pobrany" /> });
    } else {
      toast({ title: <ErrorToast title="Błąd podczas pobierania pliku" /> });
    }
  };

  const { columns } = useContractTemplatesTableColumns(handleDownload, handleDelete);
  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: isLoading ? [] : templates?.data,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
    rowCount: templates?.data?.length,
  });

  return <Table table={table} isLoading={isLoading} />;
};
