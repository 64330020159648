import apiClient from "@/api/apiClient";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { InstallationServiceDetails } from "@/components/Installations/InstallationDetails/InstallationServiceDetails";
import { InstallationServiceEditDialog } from "@/components/Installations/Services/InstallationServiceEditDialog";
import { Layout } from "@/components/layout/Layout";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useInstallationServicePolicy } from "@/policies/installation/useInstallationServicePolicy";
import { routes } from "@/routes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Pencil, Trash } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";

export const InstallationServiceDetailsPage = () => {
  const { id: installationServiceId } = useParams();
  const navigate = useNavigate();
  const installationServicePolicy = useInstallationServicePolicy();
  const queryClient = useQueryClient();

  const { isLoading, data: response } = useQuery({
    queryKey: ["installationService", installationServiceId],
    queryFn: () => apiClient.getInstallationService(installationServiceId),
  });
  useDocumentTitle(response?.data ? `Serwis ${response?.data?.name}` : null);

  const deleteInstallationServiceMutation = useMutation({
    mutationFn: apiClient.deleteInstallationService,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["installations"] });
      queryClient.invalidateQueries({ queryKey: ["installationServices"] });
      if (res.ok) {
        return navigate(routes.installationsAndServices);
      }
    },
  });

  const handleDelete = () => {
    deleteInstallationServiceMutation.mutate(installationServiceId);
  };

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs idAlias={response?.data?.name !== " " ? response?.data?.name : "Brak"} />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          <InstallationServiceEditDialog
            installationService={response?.data}
            trigger={<Button variant="outline" title="Edytuj serwis" leftIcon={<Pencil size={20} />} />}
          />
          {installationServicePolicy.destroy(response?.data) && (
            <DeleteAlertDialog
              trigger={
                <Button
                  variant="destructive"
                  title="Usuń serwis"
                  isLoading={deleteInstallationServiceMutation.isPending}
                  leftIcon={<Trash size={20} />}
                />
              }
              onConfirm={handleDelete}
              message="Czy na pewno chcesz usunąć ten serwis? Tej operacji nie można cofnąć"
            />
          )}
        </div>
      </div>
      <InstallationServiceDetails installationService={response?.data} />
    </Layout>
  );
};
