import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormDatePicker } from "@/components/forms/FormDatePicker";
import { FormField } from "@/components/forms/FormField";
import { FormNumberField } from "@/components/forms/FormNumberField";
import { FormTextarea } from "@/components/forms/FormTextarea";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import i18n from "@/i18n";
import { useContractPolicy } from "@/policies/contracts/useContractPolicy";
import { useInstallationCrewPolicy } from "@/policies/installation/useInstallationCrewPolicy";
import { useInstallationServicePolicy } from "@/policies/installation/useInstallationServicePolicy";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Plus, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

export const InstallationServiceCreateDialog = ({ contract = null, buttonVariant = "outline" }) => {
  const queryClient = useQueryClient();
  const contractPolicy = useContractPolicy();
  const installationCrewPolicy = useInstallationCrewPolicy();
  const installationServicePolicy = useInstallationServicePolicy();

  const [isOpen, setIsOpen] = useState(false);
  const [contractOptions, setContractOptions] = useState([]);
  const [installationCrewOptions, setInstallationCrewOptions] = useState([]);
  const contractFilters = {
    columnFilters: [{ id: "withoutInstallationServices", value: true }],
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(i18n.t("Pole jest wymagane")),
    service_date: Yup.date().required(i18n.t("Pole jest wymagane")),
    contract_id: Yup.string().required(i18n.t("Pole jest wymagane")),
    description: Yup.string().nullable(),
    repeat_interval: Yup.number().integer().min(1).nullable().optional(),
    installation_crew_id: Yup.string().required(i18n.t("Pole jest wymagane")),
  });

  const {
    isLoading: isLoadingContracts,
    data: contracts,
    error: errorContracts,
  } = useQuery({
    queryKey: ["contracts", contractFilters],
    queryFn: () => apiClient.getContracts(contractFilters),
    staleTime: 1000 * 60 * 5,
    enabled: contractPolicy.viewAny() && isOpen && !contract,
  });

  const onSubmit = (data) => {
    createInstallationMutation.mutate({ data });
  };

  const defaultValues = {
    contract_id: contract ? contract.id : undefined,
    user_id: undefined,
    description: "",
    name: "",
    repeat_interval: undefined,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onSubmit",
  });
  const watch = form.watch();

  const [installationCrewFilters, setInstallationCrewFilters] = useState({});

  const {
    isLoading: isLoadingInstallationCrews,
    data: installationCrews,
    error: errorInstallationCrews,
  } = useQuery({
    queryKey: ["installationCrews", installationCrewFilters],
    queryFn: () => apiClient.getInstallationCrews(installationCrewFilters),
    staleTime: 1000 * 60 * 5,
    enabled: installationCrewPolicy.viewAny() && isOpen && !!watch.contract_id,
  });

  const createInstallationMutation = useMutation({
    mutationFn: apiClient.createInstallationService,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["installationServices"] });
      queryClient.invalidateQueries({ queryKey: ["contracts", contractFilters] });
      if (contract) {
        queryClient.invalidateQueries({ queryKey: ["contract", contract.id], exact: true });
      }
      if (res.ok) {
        toast({ title: <SuccessToast title="Serwis dodany." /> });
        form.reset();
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  useEffect(() => {
    if (!isLoadingContracts && !errorContracts && contracts) {
      setContractOptions(
        contracts.data.map((contract) => ({ name: `${contract.identifier} - ${contract.name}`, value: contract.id })),
      );
    }
  }, [isLoadingContracts, contracts, errorContracts]);

  useEffect(() => {
    if (!isLoadingInstallationCrews && !errorInstallationCrews && installationCrews) {
      setInstallationCrewOptions(installationCrews.data.map((crew) => ({ name: crew.name, value: crew.id })));
    }
  }, [isLoadingInstallationCrews, installationCrews, errorInstallationCrews]);

  useEffect(() => {
    if (watch.contract_id && !contract) {
      setInstallationCrewFilters({
        voivodeships: [contracts.data.find((c) => c.id === watch.contract_id).address?.voivodeship],
      });
    } else if (watch.contract_id && contract) {
      setInstallationCrewFilters({
        voivodeships: [contract.address?.voivodeship],
      });
    }
  }, [watch.contract_id]);

  if (!installationServicePolicy.create()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <DialogTrigger asChild>
        <Button variant={buttonVariant} title="Dodaj serwis" leftIcon={<Plus size={20} />} />
      </DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Dodaj serwis")}</DialogTitle>
          <DialogDescription>{i18n.t("Dodawanie nowego serwisu.")}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            <FormField name="name" label="Nazwa" />
            <FormDatePicker name="service_date" label="Data serwisu" />
            <FormNumberField name="repeat_interval" label="Interwał czasowy w miesiącach" placeholder="Np. 12" />
            {!contract && <FormCombobox label="Umowa" name="contract_id" options={contractOptions} />}
            <FormCombobox
              name="installation_crew_id"
              label="Ekipa"
              options={installationCrewOptions}
              disabled={!watch.contract_id}
              placeholder={watch.contract_id ? "Wybierz z listy" : "Najpierw wybierz umowę"}
            />
            <FormTextarea name="description" label="Opis" />
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Dodaj"
              leftIcon={<Plus size={20} />}
              isLoading={createInstallationMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
