export const fillPdfForm = (form, schema, values) => {
  if (!form || !schema || !values) return null;

  const fields = form.getFields();
  const unfilledFields = [];

  fields.forEach((field) => {
    const fieldName = field.getName();

    if (field.setText) {
      let value = null;

      if (schema[fieldName]) {
        value = schema[fieldName](values);
      } else if (values[fieldName]) {
        value = values[fieldName];
      }

      if (value) {
        field.setText(value.toString());
      } else {
        unfilledFields.push({
          key: fieldName,
          type: "TEXT",
          description: field.getToolTip?.() || null,
          placeholder: field.getText?.() || null,
        });
      }
    } else if (field.check && field.uncheck) {
      const value = schema[fieldName]?.(values) ?? values[fieldName];

      if (value === true) {
        field.check();
      } else if (value === false) {
        field.uncheck();
      } else {
        unfilledFields.push({
          key: fieldName,
          type: "CHECKBOX",
          description: field.getToolTip?.() || null,
          placeholder: null,
        });
      }
    }
  });

  return {
    filledForm: form,
    unfilledFields,
  };
};
