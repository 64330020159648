import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { FileViewer } from "@/components/File/FileViewer";
import { FileCategoryCell } from "@/components/Table/FileCategoryCell";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { UserAvatarLink } from "@/components/Users/UserAvatarLink";
import { downloadFile } from "@/helpers/files/downloadFile";
import { formatFileSize } from "@/helpers/files/formatFileSize";
import { getFileExtensionIcon } from "@/helpers/files/getFileExtensionIcon";
import i18n from "@/i18n";
import { useAuditFilePolicy } from "@/policies/audit/useAuditFilePolicy";
import { useFilePolicy } from "@/policies/file/useFilePolicy";
import { format, parse } from "date-fns";
import { ArrowDown, ArrowUp, ArrowUpDown, Download, MoreHorizontal, Trash } from "lucide-react";

export const useAuditFilesTableColumns = (handleDelete) => {
  const auditFilePolicy = useAuditFilePolicy();
  const filePolicy = useFilePolicy();

  const columns = [
    {
      id: "file_icon",
      enableHiding: false,
      enableSorting: false,
      cell: ({ row }) => {
        const extension = row.original.extension;
        return <img className="w-8 h-8" src={getFileExtensionIcon(extension)} />;
      },
    },
    {
      name: "Nazwa pliku",
      accessorKey: "original_name",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Nazwa pliku")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row, table }) => {
        const fileIds = table.getRowModel().rows.map((r) => r.original.id);
        return filePolicy.download() ? (
          <FileViewer fileIds={fileIds} currentFile={row.index}>
            <a className="font-bold flex flex-row gap-1 items-center cursor-pointer hover:underline">
              {row.getValue("original_name")}
            </a>
          </FileViewer>
        ) : (
          <span>{row.getValue("original_name")}</span>
        );
      },
    },
    {
      id: "category",
      name: "Kategoria",
      accessorKey: "category",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Kategoria")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const file = row.original;
        return <FileCategoryCell file={file} />;
      },
    },
    {
      id: "created_by",
      name: "Dodany przez",
      accessorKey: "created_by",
      enableSorting: false,
      header: () => <div className="px-4">{i18n.t("Dodany przez")}</div>,
      cell: ({ row }) => {
        const creator = row.original.user;
        return <UserAvatarLink user={creator} />;
      },
    },
    {
      name: "Rozmiar pliku",
      accessorKey: "size",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Rozmiar pliku")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => <span className="font-semibold">{formatFileSize(row.getValue("size"))}</span>,
    },
    {
      id: "created_at",
      name: "Data utworzenia",
      accessorKey: "created_at",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Data utworzenia")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => (
        <span className="text-right">
          {format(parse(row.getValue("created_at"), "yyyy-MM-dd HH:mm:ss", new Date()), "dd LLL yyyy HH:mm")}
        </span>
      ),
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const auditFile = row.original;
        if (!auditFilePolicy.destroy(auditFile) && !filePolicy.download()) return null;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">{i18n.t("Otwórz menu")}</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {filePolicy.download() && (
                <DropdownMenuItem onClick={() => downloadFile(auditFile)} className="flex flex-row items-center">
                  <Download size={16} className="mr-1 text-primary" />
                  <span className="font-semibold">{i18n.t("Pobierz")}</span>
                </DropdownMenuItem>
              )}
              {auditFilePolicy.destroy(auditFile) && (
                <>
                  <DropdownMenuSeparator />
                  <DeleteAlertDialog
                    onConfirm={() => handleDelete(auditFile.id)}
                    message="Czy na pewno chcesz usunąć ten plik? Tej operacji nie można cofnąć"
                    trigger={
                      <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                        <Trash size={16} className="mr-1 text-primary" />
                        <span className="font-semibold">{i18n.t("Usuń")}</span>
                      </DropdownMenuItem>
                    }
                  />
                </>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
