import { ClearFiltersButton } from "@/components/Filters/ClearFiltersButton";
import { ColumnFilter } from "@/components/Filters/ColumnFilter";
import { MultipleSelectFilter } from "@/components/Filters/MultipleSelectFilter";
import { SearchFilter } from "@/components/Filters/SearchFilter";
import { ContractProductContext } from "@/context/ContractProductContext";
import { FileCategoryContext } from "@/context/FileCategoryContext";
import { appendEmptyOption } from "@/helpers/appendEmptyOption";
import { useFilters } from "@/hooks/table/useFilters";
import { useContext } from "react";

export const ContractFileCategoryFilters = ({ table }) => {
  const { filter, setFilter } = useFilters(table);
  const { categoryOptions, isLoading } = useContext(FileCategoryContext);
  const { productOptions, isLoading: isLoadingProducts } = useContext(ContractProductContext);

  return (
    <div className="flex flex-row flex-wrap gap-3 mb-3">
      <div className="flex flex-row gap-3 w-full lg:max-w-sm">
        <ClearFiltersButton table={table} />
        <SearchFilter value={filter("search")} onChange={(value) => setFilter("search", value)} />
      </div>
      <MultipleSelectFilter
        options={appendEmptyOption(productOptions, "Globalne")}
        isLoading={isLoadingProducts}
        setValue={(value) => setFilter("product", value)}
        title="Produkt umowy"
        value={filter("product")}
      />
      <MultipleSelectFilter
        options={categoryOptions}
        isLoading={isLoading}
        setValue={(value) => setFilter("category", value)}
        title="Kategoria pliku"
        value={filter("category")}
      />
      <ColumnFilter table={table} />
    </div>
  );
};
