import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormMultipleSelect } from "@/components/forms/FormMultipleSelect";
import { FormSelect } from "@/components/forms/FormSelect";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { ContractStatusContext } from "@/context/ContractStatusContext";
import { FileCategoryContext } from "@/context/FileCategoryContext";
import { Settings } from "@/enums/SettingsEnum";
import i18n from "@/i18n";
import { useSettingPolicy } from "@/policies/useSettingPolicy";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Check, X } from "lucide-react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  value: Yup.mixed().test("validate-setting-value", i18n.t("Pole jest wymagane"), function (value) {
    const { type } = this.parent;

    switch (type) {
      case "json":
        if (!Array.isArray(value) || value.length === 0) {
          return this.createError({
            message: i18n.t("Wartość musi być tablicą zawierającą co najmniej jeden element."),
          });
        }
        break;

      case "string":
        if (typeof value !== "string" || value.trim() === "") {
          return this.createError({
            message: i18n.t("Wartość musi być tekstem."),
          });
        }
        break;

      case "number":
        if (typeof value !== "number" || isNaN(value)) {
          return this.createError({
            message: i18n.t("Wartość musi być liczbą."),
          });
        }
        break;

      default:
        return this.createError({
          message: i18n.t("Nieobsługiwany typ ustawienia."),
        });
    }

    return true;
  }),
});

export const SettingEditDialog = ({ trigger, setting }) => {
  const queryClient = useQueryClient();
  const settingPolicy = useSettingPolicy();

  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = (data) => {
    updateSettingMutation.mutate({ settingId: setting.id, data });
  };

  const defaultValues = {
    type: setting.type,
    key: setting.key,
    value: setting.value,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const updateSettingMutation = useMutation({
    mutationFn: apiClient.updateSetting,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["settings"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie zapisano." /> });
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  if (!settingPolicy.update()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Edycja ustawienia")}</DialogTitle>
          <DialogDescription>{i18n.t(`settings.${setting.key}`)}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            <SettingValueInput setting={setting} />
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Zapisz"
              leftIcon={<Check size={20} />}
              isLoading={updateSettingMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

const SettingValueInput = ({ setting }) => {
  const { statusOptions, isLoading: isLoadingStatusOptions } = useContext(ContractStatusContext);
  const { categoryOptions, isLoading: isLoadingCategoryOptions } = useContext(FileCategoryContext);

  switch (setting.key) {
    case Settings.CONTRACT_POINTS_STATUS:
      return (
        <FormSelect options={statusOptions} label="Status umowy" name="value" isLoading={isLoadingStatusOptions} />
      );
    case Settings.CONTRACT_ORDER_STATUS:
      return (
        <FormSelect options={statusOptions} label="Status umowy" name="value" isLoading={isLoadingStatusOptions} />
      );
    case Settings.APPLICATION_CONTRACT_FILE_CATEGORIES:
    case Settings.INSTALLATION_CONTRACT_FILE_CATEGORIES:
      return (
        <FormMultipleSelect
          options={categoryOptions}
          isLoading={isLoadingCategoryOptions}
          name="value"
          label="Wartość"
        />
      );
    default:
      return i18n.t("Nieobsługiwane ustawienie, skontaktuj się z administratorem serwisu.");
  }
};
