import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormField } from "@/components/forms/FormField";
import { FormSelect } from "@/components/forms/FormSelect";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { UserRole } from "@/enums/UserRoleEnum";
import { useAuth } from "@/hooks/useAuth";
import i18n from "@/i18n";
import { useBillingSettingPolicy } from "@/policies/billing/useBillingSettingPolicy";
import { routes } from "@/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Plus, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

export const UserCreateForm = () => {
  const billingSettingPolicy = useBillingSettingPolicy();
  const { hasPermission } = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { state } = useLocation();

  const [billingSettingOptions, setBillingSettingOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState(UserRole.getByPermission(hasPermission));

  const validationSchema = Yup.object({
    first_name: Yup.string().required(i18n.t("Pole jest wymagane")),
    last_name: Yup.string().required(i18n.t("Pole jest wymagane")),
    email: Yup.string().required(i18n.t("Pole jest wymagane")),
    phone_number: Yup.string().required(i18n.t("Pole jest wymagane")),
    role: Yup.string().required(i18n.t("Pole jest wymagane")),
    billing_setting_id: Yup.string().optional().nullable(),
  });

  const defaultValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    department_id: state?.departmentId,
    role: undefined,
    billing_setting_id: undefined,
  };

  const onSubmit = (data) => {
    createUserMutation.mutate({ data });
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const createUserMutation = useMutation({
    mutationFn: apiClient.createUser,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      if (res.ok) {
        if (state?.departmentId) {
          return navigate(`${routes.departments}/${state.departmentId}`);
        }
        return navigate(routes.users);
      } else {
        if (
          res.status === 422 &&
          res.data.errors?.email &&
          res.data.errors?.email[0] === "The email has already been taken."
        ) {
          toast({ title: <ErrorToast title="Użytkownik o takim adresie email już istnieje" /> });
        } else if (res.status === 400) {
          toast({ title: <ErrorToast title="Oddziały mogą mieć tylko jednego managera" /> });
        } else {
          toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
        }
      }
    },
  });

  const {
    isLoading: isLoadingBillingSettings,
    data: billingSettings,
    error: errorBillingSettings,
  } = useQuery({
    queryKey: ["billingSettings"],
    queryFn: apiClient.getBillingSettings,
    staleTime: 1000 * 60 * 5,
    enabled: billingSettingPolicy.viewAny(),
  });

  useEffect(() => {
    if (state?.departmentId) {
      setRoleOptions(UserRole.getForDepartment());
    } else {
      setRoleOptions(UserRole.getByPermission(hasPermission).filter((role) => role.value !== UserRole.MANAGER.value));
    }
  }, [state]);

  useEffect(() => {
    if (!isLoadingBillingSettings && !errorBillingSettings && billingSettings) {
      setBillingSettingOptions(billingSettings.data.map((setting) => ({ name: setting.name, value: setting.id })));
    }
  }, [isLoadingBillingSettings, billingSettings, errorBillingSettings]);

  return (
    <Card className="mt-5 w-full xl:w-1/2">
      <Form form={form} onSubmit={onSubmit}>
        <CardContent className="pt-5">
          <div className="flex flex-col gap-4">
            <FormField name="first_name" label="Imię" autoComplete="first_name" />
            <FormField name="last_name" label="Nazwisko" autoComplete="last_name" />
            <FormField name="email" type="email" autoComplete="email" label="Email" />
            <FormField name="phone_number" autoComplete="phone_number" label="Numer telefonu" inputMode="numeric" />
            <FormField
              name="password_hidden"
              label="Hasło"
              disabled={true}
              description="Hasło zostanie wysłane na email podany powyżej"
            />
            <FormSelect name="role" label="Rola" options={roleOptions} />
            {billingSettingPolicy.viewAny() && (
              <FormCombobox
                name="billing_setting_id"
                label="Model rozliczeń"
                required={false}
                options={billingSettingOptions}
                isLoading={isLoadingBillingSettings}
              />
            )}
          </div>
        </CardContent>
        <CardFooter className="gap-3 items-center border-t px-6 py-4">
          <Button type="submit" title="Dodaj" leftIcon={<Plus size={20} />} isLoading={createUserMutation.isPending} />
          <Button
            title="Anuluj"
            type="button"
            leftIcon={<X size={20} />}
            variant="destructive"
            onClick={() => navigate(-1)}
          />
        </CardFooter>
      </Form>
    </Card>
  );
};
