import apiClient from "@/api/apiClient";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { ClientDetails } from "@/components/Clients/ClientDetails/ClientDetails";
import { ClientFileCreateDialog } from "@/components/Clients/ClientDetails/Files/ClientFileCreateDialog";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { Layout } from "@/components/layout/Layout";
import { TaskCreateDialog } from "@/components/Tasks/TaskCreateDialog";
import { ButtonContext } from "@/context/ButtonContext";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useClientPolicy } from "@/policies/lead/useClientPolicy";
import { routes } from "@/routes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Pencil, Trash } from "lucide-react";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const ClientDetailsPage = () => {
  const { id: clientId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const clientPolicy = useClientPolicy();
  const { buttons } = useContext(ButtonContext);

  const { isLoading, data: response } = useQuery({
    queryKey: ["client", clientId],
    queryFn: () => apiClient.getClient(clientId),
  });
  useDocumentTitle(response?.data ? `Klient ${response?.data?.name}` : null);

  const deleteClientMutation = useMutation({
    mutationFn: apiClient.deleteClient,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["clients"] });
      if (res.ok) {
        return navigate(routes.clients);
      }
    },
  });

  const handleDelete = () => {
    if (clientPolicy.destroy()) {
      deleteClientMutation.mutate(clientId);
    }
  };

  const handleEdit = () => {
    if (clientPolicy.update()) {
      return navigate(`${routes.clients}/${clientId}/edytuj`);
    }
  };

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs idAlias={response?.data?.name !== " " ? response?.data?.name : "Brak"} />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {buttons["addClientFiles"] && <ClientFileCreateDialog />}
          <TaskCreateDialog taskableId={clientId} taskableType="Client" withUser={true} buttonVariant="outline" />
          {clientPolicy.update() && (
            <Button title="Edytuj" leftIcon={<Pencil size={20} />} onClick={handleEdit} variant="outline" />
          )}
          {clientPolicy.destroy() && (
            <DeleteAlertDialog
              trigger={
                <Button
                  variant="outline"
                  title="Usuń"
                  isLoading={deleteClientMutation.isPending}
                  leftIcon={<Trash size={20} />}
                />
              }
              onConfirm={handleDelete}
              message="Czy na pewno chcesz usunąć tego klienta? Tej operacji nie można cofnąć"
            />
          )}
        </div>
      </div>
      <ClientDetails client={response?.data} />
    </Layout>
  );
};
