import { ErrorMessage } from "@/components/forms/ErrorMessage";
import { Input } from "@/components/forms/Input";
import { Controller, useFormContext } from "react-hook-form";

export const FormNumberField = ({
  name,
  label,
  description,
  placeholder = null,
  integerOnly = false,
  positiveOnly = false,
  ...rest
}) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className="w-full">
          <Input
            {...field}
            type="text"
            inputMode="decimal"
            pattern={
              positiveOnly
                ? integerOnly
                  ? "[0-9]*"
                  : "[0-9]*[.,]?[0-9]*"
                : integerOnly
                ? "-?[0-9]*"
                : "-?[0-9]*[.,]?[0-9]*"
            }
            placeholder={placeholder}
            label={label}
            isError={errors[name]}
            description={description}
            onKeyPress={(e) => {
              const charCode = e.which || e.keyCode;
              const charStr = String.fromCharCode(charCode);

              if (
                (positiveOnly && integerOnly && !/[\d]/.test(charStr)) ||
                (positiveOnly && !integerOnly && !/[\d.]/.test(charStr)) ||
                (!positiveOnly && integerOnly && !/[\d-]/.test(charStr)) ||
                (!positiveOnly && !integerOnly && !/[\d.-]/.test(charStr))
              ) {
                e.preventDefault();
              }

              if (
                (positiveOnly && charStr === "-") ||
                (!positiveOnly && charStr === "-" && e.target.value.includes("-")) ||
                (!integerOnly && charStr === "." && e.target.value.includes("."))
              ) {
                e.preventDefault();
              }
            }}
            onWheel={(e) => e.target.blur()}
            {...rest}
          />
          <ErrorMessage error={errors[name]?.message} />
        </div>
      )}
    />
  );
};
