import { Button as ShadButton } from "@/components/ui/button";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { Loader2 } from "lucide-react";
import { forwardRef } from "react";

const Button = forwardRef(
  (
    {
      children,
      disabled = false,
      title = null,
      isLoading = false,
      leftIcon,
      rightIcon,
      className,
      size,
      tooltip = null,
      ...otherProps
    },
    ref,
  ) => {
    return isLoading ? (
      <ShadButton
        ref={ref}
        className={cn("flex flex-row items-center gap-2", className)}
        disabled
        size={size}
        {...otherProps}
      >
        <Loader2 className="h-4 w-4 animate-spin" />
        {title || children ? i18n.t("Ładowanie...") : null}
      </ShadButton>
    ) : (
      <ShadButton
        ref={ref}
        title={title || tooltip}
        size={size}
        className={cn("flex flex-row items-center gap-2", className, disabled && "disabled:cursor-not-allowed")}
        disabled={disabled}
        {...otherProps}
      >
        {leftIcon && leftIcon}
        {title && size !== "icon" ? <span className="truncate">{i18n.t(title)}</span> : children}
        {rightIcon && rightIcon}
      </ShadButton>
    );
  },
);

Button.displayName = "Button";
export { Button };
