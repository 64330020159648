import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormField } from "@/components/forms/FormField";
import { FormSelect } from "@/components/forms/FormSelect";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { ApplicationTypeContext } from "@/context/ApplicationTypeContext";
import { InstallationTypeContext } from "@/context/InstallationTypeContext";
import { ContractPaymentType } from "@/enums/ContractPaymentTypeEnum";
import i18n from "@/i18n";
import { useProcessPolicy } from "@/policies/useProcessPolicy";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Plus, X } from "lucide-react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

const validationSchema = Yup.object({
  applies_to: Yup.string().required(i18n.t("Pole jest wymagane")),
  name: Yup.string().required(i18n.t("Pole jest wymagane")),
  type: Yup.string()
    .optional()
    .when("applies_to", {
      is: (val) => val === "Contract" || val === "Application" || val === "Installation",
      then: () => Yup.string().required(i18n.t("Pole jest wymagane")),
    }),
});

export const ProcessCreateDialog = ({ appliesTo }) => {
  const queryClient = useQueryClient();
  const processPolicy = useProcessPolicy();
  const { typeOptions: applicationTypeOptions } = useContext(ApplicationTypeContext);
  const { typeOptions: installationTypeOptions } = useContext(InstallationTypeContext);

  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = (data) => {
    createProcessMutation.mutate({ data });
  };

  const defaultValues = {
    applies_to: appliesTo,
    name: undefined,
    type: undefined,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const createProcessMutation = useMutation({
    mutationFn: apiClient.createProcess,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["processes"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Proces został dodany" /> });
        form.reset();
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const getTypeOptions = () => {
    if (appliesTo === "Application") {
      return applicationTypeOptions;
    }
    if (appliesTo === "Contract") {
      return ContractPaymentType.getValues();
    }
    if (appliesTo === "Installation") {
      return installationTypeOptions;
    }
    return [];
  };

  const renderTypeField = () => {
    if (appliesTo === "Contract" || appliesTo === "Application" || appliesTo === "Installation") {
      return <FormSelect label="Typ" name="type" options={getTypeOptions()} />;
    }
    return null;
  };

  if (!processPolicy.create()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <DialogTrigger asChild>
        <Button variant="outline" title="Dodaj proces" leftIcon={<Plus size={20} />} />
      </DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Dodaj proces")}</DialogTitle>
          <DialogDescription>{i18n.t("Nowy proces który będzie widoczny na kontakcie lub umowie.")}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            <FormField label="Nazwa" placeholder="Nazwa procesu" name="name" autoComplete="off" />
            {renderTypeField()}
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Dodaj"
              leftIcon={<Plus size={20} />}
              isLoading={createProcessMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
