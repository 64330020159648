import parse from "rehype-parse";
import sanitize from "rehype-sanitize";
import stringify from "rehype-stringify";
import { unified } from "unified";

export const sanitizeMarkdown = async (markdown, sanitizeSchema) => {
  const processor = unified()
    .use(parse, { fragment: true }) // Parsuj HTML jako fragment
    .use(sanitize, sanitizeSchema) // Zastosuj sanitizację
    .use(stringify); // Przekształć z powrotem na HTML

  const sanitized = await processor.process(markdown);
  return sanitized.toString();
};
