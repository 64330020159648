import { ContractStatus } from "@/enums/ContractStatusEnum";
import { useAuth } from "@/hooks/useAuth";

export const useContractPolicy = () => {
  const { hasPermission, hasAnyPermission } = useAuth();

  const viewAny = () => {
    return hasAnyPermission(["access_all_contracts", "access_department_contracts", "access_own_contracts"]);
  };

  const view = () => {
    return hasAnyPermission(["show_any_contract", "show_department_contract", "show_own_contract"]);
  };

  const create = () => {
    return hasPermission("create_contract");
  };

  const update = (contract) => {
    if (hasPermission("update_any_contract")) return true;
    if (hasAnyPermission(["update_department_contract", "update_own_contract"]))
      return contract?.status?.name === ContractStatus.NEW;
    return false;
  };

  const updateStatus = () => {
    return hasPermission("update_contracts_status");
  };

  const destroy = () => {
    return hasAnyPermission(["delete_any_contract", "delete_department_contract", "delete_own_contract"]);
  };

  const destroyAny = () => {
    return hasPermission("delete_any_contract");
  };

  const viewStats = () => {
    return hasPermission("access_contract_stats");
  };

  const viewProcesses = () => {
    return hasPermission("access_contract_processes");
  };

  const updateProcess = () => {
    return hasPermission("update_contract_process");
  };

  const updateComission = () => {
    return hasPermission("update_contract_comission");
  };

  const viewMargin = () => {
    return hasPermission("access_contract_margin");
  };

  return {
    viewAny,
    view,
    create,
    update,
    updateStatus,
    destroy,
    viewStats,
    viewProcesses,
    updateProcess,
    updateComission,
    destroyAny,
    viewMargin,
  };
};
