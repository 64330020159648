import { InstallationCrewDetailsPage } from "@/pages/Installation/InstallationCrewDetailsPage";
import { InstallationDetailsPage } from "@/pages/Installation/InstallationDetailsPage";
import { InstallationsAndServicesPage } from "@/pages/Installation/InstallationsAndServicesPage";
import { InstallationServiceDetailsPage } from "@/pages/Installation/InstallationServiceDetailsPage";
import { routes } from "@/routes";
import { Navigate, Route } from "react-router-dom";

export const InstallationRoutes = () => (
  <>
    <Route exact path={routes.installationsAndServices} element={<InstallationsAndServicesPage />} />
    <Route exact path={routes.installation} element={<InstallationDetailsPage />} />
    <Route exact path={routes.installationService} element={<InstallationServiceDetailsPage />} />
    <Route exact path={routes.installationCrew} element={<InstallationCrewDetailsPage />} />

    {/* Redirections for installations and services */}
    <Route exact path={routes.installations} element={<Navigate to={routes.installationsAndServices} replace />} />
    <Route
      exact
      path={routes.installationServices}
      element={<Navigate to={routes.installationsAndServices} replace />}
    />
  </>
);
