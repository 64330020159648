import { UserAvatar } from "@/components/Users/UserAvatar";
import { useAuth } from "@/hooks/useAuth";
import i18n from "@/i18n";
import { useUserPolicy } from "@/policies/user/useUserPolicy";
import { routes } from "@/routes";
import { Link } from "react-router-dom";

export const UserAvatarLink = ({ user, fallback = "Użytkownik usunięty" }) => {
  const { user: authUser } = useAuth();
  const userPolicy = useUserPolicy();

  if (user) {
    if (authUser.id !== user.id && userPolicy.view(user)) {
      return (
        <Link
          to={`${routes.users}/${user.id}`}
          className="flex flex-row items-center gap-3 font-medium hover:underline"
        >
          <UserAvatar user={user} />
          {user.name}
        </Link>
      );
    } else {
      return (
        <div className="flex flex-row items-center gap-3 font-medium">
          <UserAvatar user={user} />
          {user.name}
        </div>
      );
    }
  } else {
    return i18n.t(fallback);
  }
};
