import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import i18n from "@/i18n";
import { useContractFileCategoryPolicy } from "@/policies/contracts/useContractFileCategoryPolicy";
import { ArrowDown, ArrowUp, ArrowUpDown, MoreHorizontal, Trash } from "lucide-react";

export const useContractFileCategoriesTableColumns = (handleDelete) => {
  const contractFileCategoryPolicy = useContractFileCategoryPolicy();
  const columns = [
    {
      id: "fileCategory",
      name: "Kategoria",
      size: 10,
      accessorKey: "fileCategory",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Kategoria")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const contractFileCategory = row.original;
        return <div className="flex-1 ms-3 truncate">{contractFileCategory.fileCategory.name}</div>;
      },
    },
    {
      id: "contractProduct",
      name: "Produkt umowy",
      size: 10,
      accessorKey: "contractProduct",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Produkt umowy")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const contractFileCategory = row.original;
        return (
          <div className="flex-1 ms-3 truncate">
            {contractFileCategory.contractProduct ? contractFileCategory.contractProduct.name : i18n.t("-")}
          </div>
        );
      },
    },
    {
      id: "required",
      name: "Wymagana",
      accessorKey: "required",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Wymagana")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const contractFileCategory = row.original;
        return contractFileCategory.required ? i18n.t("Tak") : i18n.t("Nie");
      },
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const contractFileCategory = row.original;
        if (!contractFileCategoryPolicy.destroy(contractFileCategory)) return null;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {contractFileCategoryPolicy.destroy(contractFileCategory) && (
                <DeleteAlertDialog
                  onConfirm={() => handleDelete(contractFileCategory.id)}
                  message="Czy na pewno chcesz usunąć występowanie tej kategorii podczas tworzenia umowy? Tej operacji nie można cofnąć"
                  trigger={
                    <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                      <Trash size={16} className="mr-1 text-primary" />
                      <span className="font-semibold">{i18n.t("Usuń")}</span>
                    </DropdownMenuItem>
                  }
                />
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
