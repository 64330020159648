export const CalculatorGrantType = Object.freeze({
  FIXED: {
    value: "FIXED",
    name: "Kwotowa",
  },
  PERCENT: {
    value: "PERCENT",
    name: "Procentowa",
  },

  getValues() {
    return Object.values(this).filter((value) => typeof value === "object" && value !== null);
  },

  getNames() {
    return this.getValues().map((status) => status.name);
  },
});
