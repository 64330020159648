import { Button } from "@/components/Button";
import { ColorPicker } from "@/components/forms/ColorPicker";
import { Slider } from "@/components/ui/slider";
import { Brush, Eraser, Paintbrush, Redo, Undo } from "lucide-react";
import { useRef, useState } from "react";
import { ReactSketchCanvas } from "react-sketch-canvas";

export const SketchCanvas = ({ name, onChange, value, height = "500px", width = "100%", disabled }) => {
  const canvasRef = useRef();
  const [eraseMode, setEraseMode] = useState(false);
  const [strokeColor, setStrokeColor] = useState("#000000");
  const [strokeWidth, setStrokeWidth] = useState([5]);

  const handleStrokeColorChange = (e) => {
    setStrokeColor(e.target.value);
  };

  const handleStrokeWidthChange = (value) => {
    setStrokeWidth([value]);
  };

  const handleClear = () => {
    canvasRef.current?.resetCanvas();
    onChange(null);
  };

  const handleUndo = () => {
    canvasRef.current?.undo();
  };

  const handleRedo = () => {
    canvasRef.current?.redo();
  };

  const handlePenClick = () => {
    canvasRef.current?.eraseMode(false);
    setEraseMode(false);
  };

  const handleEraserClick = () => {
    canvasRef.current?.eraseMode(true);
    setEraseMode(true);
  };

  const handleSave = async () => {
    const data = await canvasRef.current.exportImage("png");
    onChange(data);
  };

  return (
    <div className="flex flex-col gap-2 w-full">
      {disabled ? (
        <img src={value} alt="Canvas Preview" width={width} height={height} />
      ) : (
        <>
          <div className="flex gap-2 w-full flex-wrap">
            <Button
              type="button"
              disabled={disabled}
              size="icon"
              variant={eraseMode ? "outline" : "default"}
              leftIcon={<Brush size={16} className="shrink-0" />}
              onClick={handlePenClick}
              title="Rysowanie"
            />
            <Button
              type="button"
              disabled={disabled}
              size="icon"
              variant={eraseMode ? "default" : "outline"}
              title="Gumka"
              leftIcon={<Eraser size={16} className="shrink-0" />}
              onClick={handleEraserClick}
            />
            <Button
              type="button"
              disabled={disabled}
              size="icon"
              variant="outline"
              title="Cofnij"
              leftIcon={<Undo size={16} className="shrink-0" />}
              onClick={handleUndo}
            />
            <Button
              type="button"
              disabled={disabled}
              size="icon"
              variant="outline"
              title="Powtórz"
              leftIcon={<Redo size={16} className="shrink-0" />}
              onClick={handleRedo}
            />
            <Button
              type="button"
              disabled={disabled}
              size="icon"
              variant="outline"
              title="Wyczyść"
              leftIcon={<Paintbrush size={16} className="shrink-0" />}
              onClick={handleClear}
            />
            <div className="w-1/4 mr-auto ml-1 flex flex-row items-center gap-2">
              {strokeWidth[0]}
              <Slider
                disabled={disabled}
                defaultValue={[strokeWidth]}
                value={strokeWidth}
                onValueChange={handleStrokeWidthChange}
                min={1}
                max={20}
                title="Grubość"
                step={1}
              />
            </div>
            <ColorPicker onChange={handleStrokeColorChange} value={strokeColor} className="w-10" />
          </div>
          <ReactSketchCanvas
            id={name}
            className="sketchCanvas"
            style={{}}
            canvasColor="white"
            ref={canvasRef}
            onStroke={handleSave}
            height={height}
            width={width}
            strokeColor={strokeColor}
            strokeWidth={strokeWidth[0]}
            eraserWidth={strokeWidth[0]}
          />
        </>
      )}
    </div>
  );
};
