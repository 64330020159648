import { InstallationCrewInformationCard } from "@/components/Installations/Crews/Details/InstallationCrewInformationCard";
import { InstallationCrewInstallationsCard } from "@/components/Installations/Crews/Details/InstallationCrewInstallationsCard";
import { InstallationCrewInstallationServicesCard } from "@/components/Installations/Crews/Details/InstallationCrewInstallationServicesCard";
import { useInstallationPolicy } from "@/policies/installation/useInstallationPolicy";
import { useInstallationServicePolicy } from "@/policies/installation/useInstallationServicePolicy";

export const InstallationCrewDetails = ({ installationCrew }) => {
  const installationPolicy = useInstallationPolicy();
  const installationServicePolicy = useInstallationServicePolicy();

  return (
    <div className="flex flex-col lg:flex-row w-full gap-5">
      <InstallationCrewInformationCard installationCrew={installationCrew} />
      <div className="flex flex-1 flex-col gap-5 h-fit">
        {installationPolicy.viewAny() && (
          <InstallationCrewInstallationsCard installations={installationCrew.installations} />
        )}
        {installationServicePolicy.viewAny() && (
          <InstallationCrewInstallationServicesCard installationServices={installationCrew.installationServices} />
        )}
      </div>
    </div>
  );
};
