import apiClient from "@/api/apiClient";
import latoFont from "@/assets/font/Lato-Regular.ttf";
import { Button } from "@/components/Button";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { loadFont } from "@/helpers/loadFont";
import { fillPdfForm } from "@/helpers/pdf/fillPdfForm";
import { useContractFillSchema } from "@/hooks/pdf/useContractFillSchema";
import fontkit from "@pdf-lib/fontkit";
import { useQuery } from "@tanstack/react-query";
import { FileDown, FileText } from "lucide-react";
import { PDFDocument } from "pdf-lib";
import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";

export const ContractTemplateFillButtons = ({ setUnfilledFields, calculation }) => {
  const calculatorId = calculation?.calculator?.id;
  const { flatSchema: contractFillSchema } = useContractFillSchema(calculation);
  const { getValues, formState } = useFormContext();
  const formValues = getValues();

  const [contractTemplate, setContractTemplate] = useState(null);
  const [fillingPdfState, setFillingPdfState] = useState("idle");
  const [filledPdf, setFilledPdf] = useState(null);
  const [isFormModified, setIsFormModified] = useState(false);
  const previousFormValuesRef = useRef(null);

  const {
    isLoading,
    data: response,
    error,
  } = useQuery({
    queryKey: ["calculator", calculatorId, "contractTemplates"],
    queryFn: () => apiClient.getCalculatorContractTemplate(calculatorId),
    enabled: calculatorId !== null && calculatorId !== undefined,
  });

  const fillContractTemplate = async () => {
    setFillingPdfState("loading");
    setFilledPdf(null);
    const arrayBuffer = await contractTemplate.arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuffer);
    const form = pdfDoc.getForm();
    pdfDoc.registerFontkit(fontkit);

    const fontBytes = await loadFont(latoFont);
    const customFont = await pdfDoc.embedFont(fontBytes);

    const rawUpdateFieldAppearances = form.updateFieldAppearances.bind(form);
    form.updateFieldAppearances = function () {
      return rawUpdateFieldAppearances(customFont);
    };
    const { unfilledFields } = fillPdfForm(form, contractFillSchema, formValues);
    const filledPdfBytes = await pdfDoc.save();

    setUnfilledFields(unfilledFields);
    setFilledPdf(filledPdfBytes);
    setIsFormModified(false);
    previousFormValuesRef.current = JSON.stringify(formValues);
    setFillingPdfState("idle");
  };

  const handleDownloadPdf = () => {
    if (filledPdf) {
      try {
        const blob = new Blob([filledPdf], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        if (formValues.first_name && formValues.last_name) {
          link.download = `Umowa ${formValues.first_name} ${formValues.last_name}.pdf`;
        } else {
          link.download = "Umowa.pdf";
        }
        link.click();
        toast({ title: <SuccessToast title="Umowa została pobrana" /> });
      } catch (e) {
        toast({ title: <ErrorToast title="Błąd podczas pobierania pliku" /> });
      }
    }
  };

  useEffect(() => {
    const previousFormValues = previousFormValuesRef.current;
    if (filledPdf && JSON.stringify(formValues) !== previousFormValues) {
      setIsFormModified(true);
    }
  }, [formValues, filledPdf]);

  useEffect(() => {
    if (!isLoading && !error && response) {
      setContractTemplate(response.data);
    }
  }, [isLoading, error, response]);

  return (
    calculatorId && (
      <div className="flex flex-row gap-3 items-center">
        <Button
          // isLoading={isLoading || fillingPdfState === "loading"}
          type="button"
          title={contractTemplate ? "Generuj umowę" : "Brak dostępnego szablonu"}
          disabled={response?.status === 204 || !formState.isValid}
          leftIcon={<FileText size={20} />}
          onClick={fillContractTemplate}
        />
        {filledPdf && !isFormModified && (
          <Button
            isLoading={isLoading || fillingPdfState === "loading"}
            type="button"
            className="dark:text-foreground dark:bg-opacity-70"
            variant="success"
            title="Pobierz PDF"
            leftIcon={<FileDown size={20} />}
            onClick={handleDownloadPdf}
          />
        )}
      </div>
    )
  );
};
