import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCheckbox } from "@/components/forms/FormCheckbox";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormDateTimePicker } from "@/components/forms/FormDateTimePicker";
import { FormField } from "@/components/forms/FormField";
import { FormNumberField } from "@/components/forms/FormNumberField";
import { FormSelect } from "@/components/forms/FormSelect";
import { FormTextarea } from "@/components/forms/FormTextarea";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { UsersContext } from "@/context/UsersContext";
import { TaskRecurrenceUnit } from "@/enums/TaskRecurrenceUnitEnum";
import { appendEmptyOption } from "@/helpers/appendEmptyOption";
import { useAuth } from "@/hooks/useAuth";
import i18n from "@/i18n";
import { useTaskPolicy } from "@/policies/useTaskPolicy";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Plus, X } from "lucide-react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { MdOutlineAddTask } from "react-icons/md";
import * as Yup from "yup";

const validationSchema = Yup.object({
  due_date: Yup.date(i18n.t("Pole musi być poprawną datą.")).nullable().optional(),
  description: Yup.string().nullable(),
  task: Yup.string().required(i18n.t("Pole jest wymagane")),
  user_id: Yup.string().nullable().optional(),
});

export const TaskCreateDialog = ({ taskableType, taskableId, withUser = false, buttonVariant = "default" }) => {
  const { user: authUser } = useAuth();
  const queryClient = useQueryClient();
  const taskPolicy = useTaskPolicy();
  const { userOptions, isLoading: isLoadingUsers } = useContext(UsersContext);

  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = (data) => {
    createTaskMutation.mutate({ data });
  };

  const defaultValues = {
    description: undefined,
    task: undefined,
    is_recurring: false,
    recurrence_interval: undefined,
    recurrence_unit: undefined,
    user_id: taskPolicy.assignTask() ? undefined : authUser.id,
    taskable_id: taskableId,
    taskable_type: taskableType,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });
  const { is_recurring } = form.watch();

  const createTaskMutation = useMutation({
    mutationFn: apiClient.createTask,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["tasks", "delegated"] });
      queryClient.invalidateQueries({ queryKey: ["tasks"] });
      if (taskableType === "Contract") {
        queryClient.invalidateQueries({ queryKey: ["contract", taskableId, "tasks"] });
      }
      if (res.ok) {
        toast({ title: <SuccessToast title="Zadanie dodane." /> });
        setIsOpen(false);
        form.reset();
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <DialogTrigger asChild>
        <Button variant={buttonVariant} title="Dodaj zadanie" leftIcon={<MdOutlineAddTask size={20} />} />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{i18n.t("Dodaj zadanie")}</DialogTitle>
          <DialogDescription>{i18n.t("Dodaj zadanie sobie lub innemu użytkownikowi.")}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            <FormField label="Zadanie" placeholder="Treść zadania" name="task" />
            <FormDateTimePicker name="due_date" placeholder="Wybierz datę" label="Termin zadania" />
            <FormCheckbox name="is_recurring" label="Zadanie powtarzalne" />
            {is_recurring === true && (
              <div className="flex flex-row gap-3 items-center">
                <FormNumberField integerOnly={true} label="Zadanie powtarza się co" name="recurrence_interval" />
                <FormSelect options={TaskRecurrenceUnit.getValues()} label="Jednostka" name="recurrence_unit" />
              </div>
            )}
            <FormTextarea name="description" label="Opis zadania" placeholder="Opis zadania (opcjonalne)" />
            {taskPolicy.assignTask() && withUser && (
              <FormCombobox
                label="Użytkownik"
                options={appendEmptyOption(userOptions)}
                isLoading={isLoadingUsers}
                description="Jeśli chcesz przydzielić zadanie użytkownikowi to wybierz go z listy, jeśli chcesz stworzyć zadanie dla siebie pozostaw opcję Brak"
                name="user_id"
              />
            )}
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Dodaj"
              leftIcon={<Plus size={20} />}
              isLoading={createTaskMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
