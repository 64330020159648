import { Combobox } from "@/components/forms/Combobox";
import { ErrorMessage } from "@/components/forms/ErrorMessage";
import { Label } from "@/components/ui/label";
import { isJSON } from "@/helpers/isJSON";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

export const FormCombobox = ({ name, options, parent, label, className, setCurrentValue = () => {}, ...rest }) => {
  const {
    formState: { errors },
    control,
    watch,
    setValue,
    getFieldState,
    trigger,
  } = useFormContext();

  const parentValue = watch(parent);
  let disabled = parent && !parentValue;

  const handleChange = (value) => {
    setValue(name, value);
    trigger(name);
    if (isJSON(value)) {
      value = JSON.parse(value);
    }
    setCurrentValue(value);
  };

  useEffect(() => {
    if (parent && getFieldState(parent)?.isDirty) {
      setValue(name, null);
    }
  }, [parentValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className={cn("grid", label && "gap-2")}>
          <Label className={errors[name] && "text-destructive"} htmlFor={name}>
            {label && (typeof label === "string" ? i18n.t(label) : label)}
          </Label>
          <Combobox
            id={name}
            name={name}
            setValue={handleChange}
            options={options}
            disabled={disabled}
            className={className}
            {...field}
            {...rest}
          />
          <ErrorMessage error={errors[name]?.message} />
        </div>
      )}
    />
  );
};
