import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { UserLevelEditDialog } from "@/components/Users/Levels/UserLevelEditDialog";
import i18n from "@/i18n";
import { useUserLevelPolicy } from "@/policies/user/useUserLevelPolicy";
import { Info, MoreHorizontal, Pencil, Trash } from "lucide-react";

export const useUserLevelsTableColumns = (handleDelete) => {
  const userLevelPolicy = useUserLevelPolicy();

  const columns = [
    {
      id: "name",
      name: "Nazwa poziomu",
      size: 10,
      accessorKey: "name",
      header: i18n.t("Nazwa poziomu"),
      cell: ({ row }) => row.getValue("name"),
    },
    {
      id: "required_points",
      name: "Wymagana ilość punktów",
      accessorKey: "required_points",
      header: () => {
        return (
          <div className="flex flex-row gap-2 items-center">
            {i18n.t("Wymagana ilość punktów")}
            <HoverCard>
              <HoverCardTrigger asChild>
                <Button variant="ghost" size="icon" leftIcon={<Info size={20} />} />
              </HoverCardTrigger>
              <HoverCardContent className="max-w-3xl z-500">
                <p className="text-sm text-muted-foreground flex flex-row flex-wrap">
                  {i18n.t(
                    `Punkty naliczają się poprzez wzięcie wartości netto z umowy i podzieleniu jej przez 1000 bez reszty np. dla umowy o wartości netto równej 59 999zł użytkownik otrzyma 59 punktów`,
                  )}
                </p>
              </HoverCardContent>
            </HoverCard>
          </div>
        );
      },
      cell: ({ row }) => (row.original.required_points ? `${row.original.required_points} ${i18n.t("punktów")}` : "-"),
    },
    {
      id: "comission_percentage",
      name: "Procentowy udział w prowizji",
      accessorKey: "comission_percentage",
      header: i18n.t("Procentowy udział w prowizji"),
      cell: ({ row }) => row.getValue("comission_percentage") + "%",
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const userLevel = row.original;
        if (!userLevelPolicy.destroy(userLevel) && !userLevelPolicy.update()) return null;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {userLevelPolicy.update() && (
                <UserLevelEditDialog
                  userLevel={userLevel}
                  trigger={
                    <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                      <Pencil size={16} className="mr-1 text-primary" />
                      <span className="font-semibold">{i18n.t("Edytuj")}</span>
                    </DropdownMenuItem>
                  }
                />
              )}
              {userLevelPolicy.destroy(userLevel) && (
                <>
                  <DropdownMenuSeparator />
                  <DeleteAlertDialog
                    onConfirm={() => handleDelete(userLevel.id)}
                    message="Czy na pewno chcesz usunąć ten status? Tej operacji nie można cofnąć"
                    trigger={
                      <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                        <Trash size={16} className="mr-1 text-primary" />
                        <span className="font-semibold">{i18n.t("Usuń")}</span>
                      </DropdownMenuItem>
                    }
                  />
                </>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
