import apiClient from "@/api/apiClient";
import mapPinInstallation from "@/assets/icons/mapPin/mapPinInstallation.svg";
import mapPinInstallationService from "@/assets/icons/mapPin/mapPinInstallationService.svg";
import { InstallationPopup } from "@/components/Installations/Map/InstallationPopup";
import { InstallationServicePopup } from "@/components/Installations/Map/InstallationServicePopup";
import { Loader } from "@/components/Loader";
import { useInstallationPolicy } from "@/policies/installation/useInstallationPolicy";
import { useInstallationServicePolicy } from "@/policies/installation/useInstallationServicePolicy";
import { useQuery } from "@tanstack/react-query";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";

export const InstallationsServicesMap = () => {
  const installationPolicy = useInstallationPolicy();
  const installationServicePolicy = useInstallationServicePolicy();

  const installationIcon = new Icon({
    iconUrl: mapPinInstallation,
    iconSize: [38, 38],
    iconAnchor: [19, 38],
    popupAnchor: [0, -19],
  });

  const installationServiceIcon = new Icon({
    iconUrl: mapPinInstallationService,
    iconSize: [38, 38],
    iconAnchor: [19, 38],
    popupAnchor: [0, -19],
  });

  const { isLoading, data: response } = useQuery({
    queryKey: ["installations"],
    queryFn: apiClient.getInstallations,
    enabled: installationPolicy.viewAny(),
  });

  const { isLoading: isLoadingInstallationServices, data: responseInstallationServices } = useQuery({
    queryKey: ["installationServices"],
    queryFn: apiClient.getInstallationServices,
    enabled: installationServicePolicy.viewAny(),
  });

  const installations = response?.data ?? [];

  const installationServices = responseInstallationServices?.data ?? [];

  return isLoading || isLoadingInstallationServices ? (
    <Loader />
  ) : (
    <MapContainer center={[53.482892, 18.749418]} zoom={13}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <MarkerClusterGroup chunkedLoading>
        {installations.map((installation) => {
          if (installation?.contract?.address) {
            const { latitude, longitude } = installation.contract.address;
            if (!latitude || !longitude) return null;
            return (
              <Marker
                key={installation.id}
                position={[parseFloat(latitude), parseFloat(longitude)]}
                icon={installationIcon}
              >
                <Popup maxWidth={500}>
                  <InstallationPopup installation={installation} />
                </Popup>
              </Marker>
            );
          }
        })}
        {installationServices.map((installationService) => {
          if (installationService?.contract?.address) {
            const { latitude, longitude } = installationService.contract.address;
            if (!latitude || !longitude) return null;
            return (
              <Marker
                key={installationService.id}
                position={[parseFloat(latitude), parseFloat(longitude)]}
                icon={installationServiceIcon}
              >
                <Popup maxWidth={500}>
                  <InstallationServicePopup installationService={installationService} />
                </Popup>
              </Marker>
            );
          }
        })}
      </MarkerClusterGroup>
    </MapContainer>
  );
};
