import apiClient from "@/api/apiClient";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { ClientEditForm } from "@/components/Clients/ClientEditForm";
import { Layout } from "@/components/layout/Layout";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const ClientEditPage = () => {
  const { id: clientId } = useParams();

  const { isLoading, data: response } = useQuery({
    queryKey: ["client", clientId],
    queryFn: () => apiClient.getClient(clientId),
  });
  useDocumentTitle(response?.data ? `Edycja klienta ${response?.data?.name}` : null);

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col justify-between items-center sm:flex-row">
        <Breadcrumbs idAlias={response?.data?.name} />
      </div>
      <ClientEditForm client={response?.data} />
    </Layout>
  );
};
