import { Button } from "@/components/Button";
import { CardRow } from "@/components/ui/CardRow";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { isAddressNotEmpty } from "@/helpers/address/isAddressNotEmpty";
import { capitalize } from "@/helpers/capitalize";
import { formatPhoneNumber } from "@/helpers/formatPhoneNumber";
import i18n from "@/i18n";
import { useContractPolicy } from "@/policies/contracts/useContractPolicy";
import { routes } from "@/routes";
import { format, parse } from "date-fns";
import { Link } from "react-router-dom";

export const InstallationServiceInformationCard = ({ installationService }) => {
  const contractPolicy = useContractPolicy();
  const installationServiceContract = installationService.contract;

  return (
    <div className="flex-1 flex gap-5 flex-col">
      <Card className="shadow-md">
        <CardHeader>
          <CardTitle>{i18n.t("Informacje")}</CardTitle>
          <CardDescription>{i18n.t("Informacje o serwisie")}</CardDescription>
        </CardHeader>
        <CardContent className="divide-y divide-y-border">
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Nazwa")}</p>
            <p>{installationService.name}</p>
          </CardRow>
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Data serwisu")}</p>
            <p>
              {installationService.service_date
                ? format(parse(installationService.service_date, "yyyy-MM-dd", new Date()), "dd MMM yyyy")
                : i18n.t("Nie ustalono")}
            </p>
          </CardRow>
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Powtarza się co")}</p>
            <p>{`${installationService.repeat_interval} ${i18n.t("miesięcy")}`}</p>
          </CardRow>
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Data utworzenia serwisu")}</p>
            <p title={installationService.created_at}>
              {format(parse(installationService.created_at, "yyyy-MM-dd HH:mm:ss", new Date()), "dd MMM yyyy")}
            </p>
          </CardRow>
          {installationService.description && (
            <CardRow className="flex lg:flex-col lg:items-start gap-1">
              <p className="text-xs sm:text-base font-medium">{i18n.t("Opis")}</p>
              <p className="text-muted-foreground">{installationService.description}</p>
            </CardRow>
          )}
        </CardContent>
      </Card>
      <Card className="shadow-md">
        <CardHeader>
          <CardTitle>{i18n.t("Umowa")}</CardTitle>
          <CardDescription>{i18n.t("Informacje o umowie")}</CardDescription>
        </CardHeader>
        <CardContent className="divide-y divide-y-border">
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Umowa")}</p>
            {contractPolicy.view() ? (
              <Link to={`${routes.contracts}/${installationServiceContract.id}`}>
                <Button variant="link" size="xs">
                  {installationServiceContract.identifier}
                </Button>
              </Link>
            ) : (
              <p>{installationServiceContract.identifier}</p>
            )}
          </CardRow>
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Imię i nazwisko klienta")}</p>
            <p>{installationServiceContract.name}</p>
          </CardRow>
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Numer telefonu klienta")}</p>
            {installationServiceContract.phone_number ? (
              <a className="font-semibold" href={`tel:${installationServiceContract.phone_number}`}>
                {formatPhoneNumber(installationServiceContract.phone_number)}
              </a>
            ) : (
              <p>{i18n.t("none")}</p>
            )}
          </CardRow>
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Email klienta")}</p>
            {installationServiceContract.email ? (
              <a className="font-semibold" href={`mailto:${installationServiceContract.email}`}>
                {installationServiceContract.email}
              </a>
            ) : (
              <p>{i18n.t("none")}</p>
            )}
          </CardRow>
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Adres")}</p>
            {isAddressNotEmpty(installationService.contract) ? (
              <div className="flex flex-col justify-end sm:items-end">
                <span className="flex gap-1 items-center">
                  <p className="italic text-xs font-bold">{i18n.t("Adres: ")}</p>
                  {installationService?.contract?.address?.street_number ?? i18n.t("Brak")}
                </span>
                <span className="flex gap-1 items-center">
                  <p className="italic text-xs font-bold">{i18n.t("Kod pocztowy: ")}</p>
                  {installationService?.contract?.address?.postal_code ?? i18n.t("Brak")}
                </span>
                <span className="flex gap-1 items-center">
                  <p className="italic text-xs font-bold">{i18n.t("Miasto: ")}</p>
                  {installationService?.contract?.address?.city ?? i18n.t("Brak")}
                </span>
                <span className="flex gap-1 items-center">
                  <p className="italic text-xs font-bold">{i18n.t("Woj. ") + ":"}</p>
                  {installationService?.contract?.address?.voivodeship
                    ? capitalize(installationService?.contract?.address?.voivodeship)
                    : i18n.t("Brak")}
                </span>
              </div>
            ) : (
              <p>{i18n.t("Brak")}</p>
            )}
          </CardRow>
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Data podpisania umowy")}</p>
            <p>{format(parse(installationServiceContract.signed_at, "yyyy-MM-dd", new Date()), "dd MMM yyyy")}</p>
          </CardRow>
          {installationServiceContract.calculation && (
            <>
              <CardRow>
                <p className="text-xs sm:text-base font-medium">{i18n.t("Kalkulacja")}</p>
                <Link
                  to={`${routes.contracts}/${installationServiceContract.id}/kalkulacja`}
                  state={{ contract: installationServiceContract }}
                >
                  <Button variant="link" size="xs">
                    {installationServiceContract.calculation.name}
                  </Button>
                </Link>
              </CardRow>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};
