import { ChevronsUpDown, LetterText, PencilRuler, SquareCheck, Text, TextCursorInput } from "lucide-react";

export const SurveyQuestionType = Object.freeze({
  LIST: {
    value: "LIST",
    name: "Lista rozwijana",
    icon: () => <ChevronsUpDown className="mr-2 h-5 w-5" />,
  },
  INPUT: {
    value: "INPUT",
    name: "Pole tekstowe",
    icon: () => <TextCursorInput className="mr-2 h-5 w-5" />,
  },
  CHECKBOX: {
    value: "CHECKBOX",
    name: "Checkbox",
    icon: () => <SquareCheck className="mr-2 h-5 w-5" />,
  },
  DRAWING: {
    value: "DRAWING",
    name: "Rysunek",
    icon: () => <PencilRuler className="mr-2 h-5 w-5" />,
  },
  TEXTAREA: {
    value: "TEXTAREA",
    name: "Długie pole tekstowe",
    icon: () => <Text className="mr-2 h-5 w-5" />,
  },
  PLAIN_TEXT: {
    value: "PLAIN_TEXT",
    name: "Blok tekstu",
    icon: () => <LetterText className="mr-2 h-5 w-5" />,
  },

  getValues() {
    return Object.values(this).filter((value) => typeof value === "object" && value !== null);
  },

  getNames() {
    return this.getValues().map((status) => status.name);
  },
});
