import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { Loader } from "@/components/Loader";
import { downloadFile } from "@/helpers/files/downloadFile";
import i18n from "@/i18n";
import { useQuery } from "@tanstack/react-query";
import { ChevronLeft, ChevronRight, Download, FileX, X } from "lucide-react";
import { cloneElement, useEffect, useState } from "react";

export const FileViewer = ({ fileIds, currentFile, children }) => {
  const [currentFileIndex, setCurrentFileIndex] = useState(currentFile ?? 0);
  const [isOpen, setIsOpen] = useState(false);
  const [fileName, setFileName] = useState(null);

  const currentFileId = fileIds ? fileIds[currentFileIndex] : null;

  const { data: file, isLoading } = useQuery({
    queryKey: ["file", currentFileId],
    queryFn: () => apiClient.downloadFile(currentFileId),
    enabled: isOpen && !!currentFileId,
  });

  useEffect(() => {
    if (file) {
      const contentDisposition = file.headers["content-disposition"];
      const matchedFileName = contentDisposition?.match(/filename\*?=(?:UTF-8''|"?)([^;"\n]*)/);
      setFileName(matchedFileName ? matchedFileName[1] : "");
    }
  }, [file]);

  const renderFileContent = () => {
    if (!file) return null;
    const fileData = file.data;

    const fileType = fileData.type;

    if (fileType.includes("pdf")) {
      return <embed src={URL.createObjectURL(fileData)} type="application/pdf" className="w-full h-screen" />;
    } else if (fileType.includes("image")) {
      return <img src={URL.createObjectURL(fileData)} alt="Wyświetlany plik" className="max-w-full max-h-full" />;
    } else {
      return (
        <span className="flex flex-col gap-3 text-center items-center text-lg font-semibold justify-center text-background dark:text-foreground">
          <FileX size={36} />
          {i18n.t("Podgląd niedostępny. Nieobsługiwany typ pliku.")}
          <Button
            title="Pobierz plik"
            onClick={() => downloadFile({ id: currentFileId, original_name: fileName })}
            leftIcon={<Download size={20} />}
          />
        </span>
      );
    }
  };

  const handlePrevious = () => {
    if (currentFileIndex > 0) {
      setCurrentFileIndex((prev) => prev - 1);
    }
  };

  const handleNext = () => {
    if (currentFileIndex < fileIds.length - 1) {
      setCurrentFileIndex((prev) => prev + 1);
    }
  };

  const handleBackgroundClick = (e) => {
    if (e.target.id === "file-viewer-overlay") {
      setIsOpen(false);
    }
  };

  return (
    <>
      {cloneElement(children, { onClick: () => setIsOpen(true) })}
      {isOpen && (
        <div
          id="file-viewer-overlay"
          className="fixed inset-0 bg-black bg-opacity-90 z-40"
          onClick={handleBackgroundClick}
        >
          <div className="absolute top-4 left-4 right-4 lg:left-1/2 lg:transform lg:-translate-x-1/2 flex justify-between lg:justify-center items-center w-full px-5">
            <div className="hidden lg:flex lg:flex-1"></div>
            <div className="text-white text-lg font-bold truncate lg:absolute lg:transform lg:translate-x-4">
              {fileName || "Plik"}
            </div>
            <div className="flex gap-2">
              <Button
                variant="ghost"
                size="icon"
                className="text-background dark:text-foreground"
                tooltip="Pobierz plik"
                onClick={() => downloadFile({ id: currentFileId, original_name: fileName })}
              >
                <Download size={24} />
              </Button>
              <Button
                variant="ghost"
                size="icon"
                className="text-background dark:text-foreground"
                tooltip="Zamknij"
                onClick={() => setIsOpen(false)}
              >
                <X size={24} />
              </Button>
            </div>
          </div>
          <div className="flex justify-center items-center px-5 lg:w-1/2 h-full z-50 mx-auto">
            {isLoading ? (
              <Loader />
            ) : (
              <div className="flex justify-center items-center w-full h-auto">{renderFileContent()}</div>
            )}
          </div>

          {fileIds.length > 1 && (
            <div className="absolute bottom-4 left-0 right-0 flex justify-between px-4 lg:top-1/2 lg:left-4 lg:right-4 lg:bottom-auto lg:transform lg:-translate-y-1/2 lg:flex lg:items-center">
              <Button
                variant="ghost"
                size="icon"
                onClick={handlePrevious}
                disabled={currentFileIndex === 0}
                leftIcon={<ChevronLeft size={24} />}
              />
              <Button
                variant="ghost"
                size="icon"
                onClick={handleNext}
                leftIcon={<ChevronRight size={24} />}
                disabled={currentFileIndex === fileIds.length - 1}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};
