import apiClient from "@/api/apiClient";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { Layout } from "@/components/layout/Layout";
import { LeadFileCreateDialog } from "@/components/Leads/LeadDetails/Files/LeadFileCreateDialog";
import { LeadDetails } from "@/components/Leads/LeadDetails/LeadDetails";
import { TaskCreateDialog } from "@/components/Tasks/TaskCreateDialog";
import { ButtonContext } from "@/context/ButtonContext";
import { LeadStatus } from "@/enums/LeadStatusEnum";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useContractPolicy } from "@/policies/contracts/useContractPolicy";
import { useLeadPolicy } from "@/policies/lead/useLeadPolicy";
import { routes } from "@/routes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FilePlus2, Pencil, Trash } from "lucide-react";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const LeadDetailsPage = () => {
  const { id: leadId } = useParams();
  const navigate = useNavigate();
  const { buttons } = useContext(ButtonContext);
  const leadPolicy = useLeadPolicy();
  const contractPolicy = useContractPolicy();
  const queryClient = useQueryClient();

  const { isLoading, data: response } = useQuery({
    queryKey: ["lead", leadId],
    queryFn: () => apiClient.getLead(leadId),
  });
  useDocumentTitle(response?.data ? `Kontakt ${response?.data?.name}` : null);

  const deleteLeadMutation = useMutation({
    mutationFn: apiClient.deleteLead,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["leads"] });
      if (res.ok) {
        return navigate(routes.leads);
      }
    },
  });

  const handleEdit = () => {
    if (leadPolicy.update()) {
      return navigate(`${routes.leads}/${leadId}/edytuj`);
    }
  };

  const handleAddContract = () => {
    return navigate(`${routes.contracts}/dodaj`, { state: { lead: response.data } });
  };

  const handleDelete = () => {
    if (leadPolicy.destroy(response.data)) {
      deleteLeadMutation.mutate(leadId);
    }
  };

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs idAlias={response?.data?.name !== " " ? response?.data?.name : "Brak"} />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {buttons["addLeadFiles"] && <LeadFileCreateDialog />}
          {response?.data?.status?.name === LeadStatus.CONTRACT_SIGNED && contractPolicy.create() && (
            <Button title="Dodaj umowę" leftIcon={<FilePlus2 size={20} />} onClick={handleAddContract} />
          )}
          <TaskCreateDialog taskableId={leadId} taskableType="Lead" withUser={true} buttonVariant="outline" />
          {leadPolicy.update() && (
            <Button variant="outline" title="Edytuj kontakt" leftIcon={<Pencil size={20} />} onClick={handleEdit} />
          )}
          {leadPolicy.destroy(response?.data) && (
            <DeleteAlertDialog
              trigger={
                <Button
                  variant="outline"
                  title="Usuń kontakt"
                  isLoading={deleteLeadMutation.isPending}
                  leftIcon={<Trash size={20} />}
                />
              }
              onConfirm={handleDelete}
              message="Czy na pewno chcesz usunąć ten kontakt? Tej operacji nie można cofnąć"
            />
          )}
        </div>
      </div>
      <LeadDetails lead={response?.data} />
    </Layout>
  );
};
