import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormField } from "@/components/forms/FormField";
import { FormSelect } from "@/components/forms/FormSelect";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { UserRole } from "@/enums/UserRoleEnum";
import { appendEmptyOption } from "@/helpers/appendEmptyOption";
import { useAuth } from "@/hooks/useAuth";
import i18n from "@/i18n";
import { useBillingSettingPolicy } from "@/policies/billing/useBillingSettingPolicy";
import { useDepartmentPolicy } from "@/policies/useDepartmentPolicy";
import { useUserLevelPolicy } from "@/policies/user/useUserLevelPolicy";
import { routes } from "@/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Check, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

export const UserEditForm = ({ user }) => {
  const { hasPermission, userHasRole } = useAuth();
  const departmentPolicy = useDepartmentPolicy();
  const billingSettingPolicy = useBillingSettingPolicy();
  const userLevelPolicy = useUserLevelPolicy();
  const { id: userId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [billingSettingOptions, setBillingSettingOptions] = useState([]);
  const [levelOptions, setLevelOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState(UserRole.getByPermission(hasPermission));

  const validationSchema = Yup.object({
    role: Yup.string().required(i18n.t("Pole jest wymagane")),
    department_id: Yup.string().required(i18n.t("Pole jest wymagane")),
    level_id: Yup.string().required(i18n.t("Pole jest wymagane")),
    billing_setting_id: Yup.string().optional().nullable(),
    comission_percentage: Yup.number().optional().nullable(),
  });

  const defaultValues = {
    role: user.roles[0].name,
    department_id: user.department ? user.department.id : "null",
    level_id: user.level ? user.level.id : "null",
    billing_setting_id: user.billingSetting ? user.billingSetting.id : "null",
    comission_percentage: user.comission_percentage,
  };

  const onSubmit = (data) => {
    editUserMutation.mutate({ id: userId, data });
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const editUserMutation = useMutation({
    mutationFn: apiClient.updateUser,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      queryClient.invalidateQueries({ queryKey: ["user", userId] });
      if (res.ok) {
        return navigate(`${routes.users}/${user.id}`);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const {
    isLoading: isLoadingDepartments,
    data: departments,
    error: errorDepartments,
  } = useQuery({
    queryKey: ["departments"],
    queryFn: apiClient.getDepartments,
    staleTime: 1000 * 60 * 5,
    enabled: departmentPolicy.viewAny(),
  });

  const {
    isLoading: isLoadingLevels,
    data: levels,
    error: errorLevels,
  } = useQuery({
    queryKey: ["userLevels"],
    queryFn: apiClient.getUserLevels,
    staleTime: 1000 * 60 * 5,
    enabled: userLevelPolicy.viewAny(),
  });

  const {
    isLoading: isLoadingBillingSettings,
    data: billingSettings,
    error: errorBillingSettings,
  } = useQuery({
    queryKey: ["billingSettings"],
    queryFn: apiClient.getBillingSettings,
    staleTime: 1000 * 60 * 5,
    enabled: billingSettingPolicy.viewAny(),
  });

  useEffect(() => {
    if (!isLoadingDepartments && !errorDepartments && departments) {
      setDepartmentOptions(departments.data.map((department) => ({ name: department.name, value: department.id })));
    }
    if (!isLoadingBillingSettings && !errorBillingSettings && billingSettings) {
      setBillingSettingOptions(billingSettings.data.map((setting) => ({ name: setting.name, value: setting.id })));
    }
    if (!isLoadingLevels && !errorLevels && levels) {
      setLevelOptions(levels.data.map((level) => ({ name: level.name, value: level.id })));
    }
  }, [
    isLoadingDepartments,
    departments,
    errorDepartments,
    isLoadingBillingSettings,
    billingSettings,
    errorBillingSettings,
    isLoadingLevels,
    levels,
    errorLevels,
  ]);

  useEffect(() => {
    if (roleOptions.length === 0) {
      setRoleOptions(UserRole.getByPermission(hasPermission));
    }
  }, []);

  return (
    <Card className="mt-5 w-full xl:w-1/2">
      <Form form={form} onSubmit={onSubmit}>
        <CardContent className="pt-5">
          <div className="flex flex-col gap-4">
            <FormSelect name="role" label="Rola" options={roleOptions} />
            {departmentPolicy.viewAny() && (
              <FormCombobox
                name="department_id"
                label="Oddział"
                options={appendEmptyOption(departmentOptions)}
                isLoading={isLoadingDepartments}
              />
            )}
            {userLevelPolicy.create() && (
              <FormSelect
                name="level_id"
                label="Poziom"
                options={appendEmptyOption(levelOptions)}
                isLoading={isLoadingLevels}
              />
            )}
            {billingSettingPolicy.viewAny() && (
              <>
                <FormCombobox
                  name="billing_setting_id"
                  label="Model rozliczeń"
                  options={appendEmptyOption(billingSettingOptions)}
                  isLoading={isLoadingBillingSettings}
                />
                {userHasRole(user, UserRole.MANAGER.value) && (
                  <FormField
                    name="comission_percentage"
                    label="Procentowy udział w prowizji użytkowników w oddziale"
                    placeholder="Np. 10%"
                  />
                )}
              </>
            )}
          </div>
        </CardContent>
        <CardFooter className="gap-3 items-center border-t px-6 py-4">
          <Button type="submit" title="Zapisz" leftIcon={<Check size={20} />} isLoading={editUserMutation.isPending} />
          <Button
            title="Anuluj"
            type="button"
            leftIcon={<X size={20} />}
            variant="destructive"
            onClick={() => navigate(-1)}
          />
        </CardFooter>
      </Form>
    </Card>
  );
};
