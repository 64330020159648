import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { ClickAwayListener } from "@/components/ClickAwayListener";
import { EmptyState } from "@/components/EmptyState";
import { Loader } from "@/components/Loader";
import { Note } from "@/components/Notes/Note";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "@/components/ui/use-toast";
import i18n from "@/i18n";
import { useInstallationServiceNotePolicy } from "@/policies/installation/useInstallationServiceNotePolicy";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { StickyNote } from "lucide-react";
import { useState } from "react";
import { useParams } from "react-router-dom";

export const InstallationServiceNotesCard = () => {
  const { id: installationServiceId } = useParams();
  const installationNotePolicy = useInstallationServiceNotePolicy();
  const [create, setCreate] = useState(false);
  const [note, setNote] = useState("");

  const queryClient = useQueryClient();
  const { isLoading, data: response } = useQuery({
    queryKey: ["installationService", installationServiceId, "notes"],
    queryFn: () => apiClient.getInstallationServiceNotes(installationServiceId),
  });

  const deleteInstallationServiceNoteMutation = useMutation({
    mutationFn: apiClient.deleteInstallationServiceNote,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["installationService", installationServiceId, "notes"] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });
  const updateInstallationServiceNoteMutation = useMutation({
    mutationFn: apiClient.updateInstallationServiceNote,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["installationService", installationServiceId, "notes"] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });
  const createInstallationServiceNoteMutation = useMutation({
    mutationFn: apiClient.createInstallationServiceNote,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["installationService", installationServiceId, "notes"] });
        setCreate(false);
        setNote("");
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const handleEdit = (noteId, note) => {
    updateInstallationServiceNoteMutation.mutate({ installationServiceId, noteId, note });
  };

  const handleDelete = (noteId) => {
    deleteInstallationServiceNoteMutation.mutate({ installationServiceId, noteId });
  };

  const handleCreate = () => {
    if (note.length > 0) createInstallationServiceNoteMutation.mutate({ installationServiceId, note });
    else setCreate(false);
  };

  return (
    <Card className="flex-1 h-fit shadow-md pr-2">
      <CardHeader className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-1.5">
          <CardTitle>{i18n.t("Notatki")}</CardTitle>
          <CardDescription>{i18n.t("Notatki napisane do tego serwisu")}</CardDescription>
        </div>
        {installationNotePolicy.create() && (
          <Button
            variant="outline"
            title="Dodaj notatkę"
            leftIcon={<StickyNote size={20} />}
            onClick={() => setCreate(!create)}
          />
        )}
      </CardHeader>
      <ScrollArea className="w-full flex max-h-96 flex-col">
        <CardContent>
          <div className="flex flex-col gap-3 justify-center items-center">
            {create && (
              <Card className="w-full mt-1">
                <ClickAwayListener onClickAway={handleCreate}>
                  <Textarea value={note} onChange={(e) => setNote(e.target.value)} />
                </ClickAwayListener>
              </Card>
            )}
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {!create && !response.data.length && <EmptyState title="Brak notatek" />}
                {response.data.map((note) => {
                  return (
                    <Note
                      key={note.id}
                      note={note}
                      handleDelete={handleDelete}
                      handleEdit={handleEdit}
                      canDelete={installationNotePolicy.destroy(note)}
                      canEdit={installationNotePolicy.update(note)}
                    />
                  );
                })}
              </>
            )}
          </div>
        </CardContent>
      </ScrollArea>
    </Card>
  );
};
