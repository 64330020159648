import mapPinAudit from "@/assets/icons/mapPin/mapPinAudit.svg";
import { AuditPopup } from "@/components/Audits/Map/AuditPopup";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";

export const AuditsMap = ({ audits }) => {
  const auditIcon = new Icon({
    iconUrl: mapPinAudit,
    iconSize: [45, 45],
    iconAnchor: [22, 45],
    popupAnchor: [0, -19],
  });

  return (
    <MapContainer center={[53.482892, 18.749418]} zoom={13}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <MarkerClusterGroup chunkedLoading>
        {audits.map((audit) => {
          if (audit?.lead?.address) {
            const { latitude, longitude } = audit.lead.address;
            if (!latitude || !longitude) return null;
            return (
              <Marker key={audit.id} position={[parseFloat(latitude), parseFloat(longitude)]} icon={auditIcon}>
                <Popup maxWidth={500}>
                  <AuditPopup audit={audit} />
                </Popup>
              </Marker>
            );
          }
        })}
      </MarkerClusterGroup>
    </MapContainer>
  );
};
