import apiClient from "@/api/apiClient";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { Layout } from "@/components/layout/Layout";
import { SurveyDetails } from "@/components/Surveys/SurveyDetails";
import { SurveyEditDialog } from "@/components/Surveys/SurveyEditDialog";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useSurveyPolicy } from "@/policies/useSurveyPolicy";
import { routes } from "@/routes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Trash } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";

export const SurveyDetailsPage = () => {
  const { id: surveyId } = useParams();
  const surveyPolicy = useSurveyPolicy();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { isLoading, data: survey } = useQuery({
    queryKey: ["survey", surveyId],
    queryFn: () => apiClient.getSurvey(surveyId),
    staleTime: 1000 * 60 * 10,
  });
  useDocumentTitle(survey?.data ? `Ankieta ${survey.data.name}` : null);

  const deleteSurveyMutation = useMutation({
    mutationFn: apiClient.deleteSurvey,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["surveys"] });
        toast({ title: <SuccessToast title="Pomyślnie usunięto" /> });
        return navigate(routes.surveysSettings);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const handleDelete = () => {
    deleteSurveyMutation.mutate({ surveyId });
  };

  if (!surveyPolicy.update()) return null;

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs idAlias={survey?.data?.name} />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          <SurveyEditDialog survey={survey?.data} />
          {surveyPolicy.destroy() && (
            <DeleteAlertDialog
              message="Czy na pewno chcesz usunąć tę ankietę? Tej operacji nie można cofnąć"
              onConfirm={handleDelete}
              trigger={
                <Button
                  variant="destructive"
                  title="Usuń ankietę"
                  leftIcon={<Trash size={20} />}
                  isLoading={deleteSurveyMutation.isLoading}
                />
              }
            />
          )}
        </div>
      </div>
      <SurveyDetails survey={survey?.data} />
    </Layout>
  );
};
