import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCheckbox } from "@/components/forms/FormCheckbox";
import { FormNumberField } from "@/components/forms/FormNumberField";
import { FormSelect } from "@/components/forms/FormSelect";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { ContractStatusContext } from "@/context/ContractStatusContext";
import i18n from "@/i18n";
import { useBillingSettingPolicy } from "@/policies/billing/useBillingSettingPolicy";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Plus, X } from "lucide-react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

const validationSchema = Yup.object({
  contract_status_id: Yup.string().required(i18n.t("Pole jest wymagane")),
  amount: Yup.string().nullable(),
  percentage: Yup.string().nullable(),
  from_comission: Yup.boolean().required(i18n.t("Pole jest wymagane")),
});

export const BillingSettingRuleCreateDialog = ({ billingSetting }) => {
  const queryClient = useQueryClient();
  const billingSettingPolicy = useBillingSettingPolicy();
  const { statusOptions } = useContext(ContractStatusContext);

  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = (data) => {
    if (type === "percentage") {
      data.amount = null;
      data.from_comission = true;
    } else if (type === "amount") {
      data.percentage = null;
    }
    createBillingSettingRuleMutation.mutate({ billingSettingId: billingSetting.id, data });
  };

  const defaultValues = {
    contract_status_id: "",
    amount: undefined,
    percentage: undefined,
    from_comission: false,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onSubmit",
  });
  const { type } = form.watch();

  const createBillingSettingRuleMutation = useMutation({
    mutationFn: apiClient.createBillingSettingRule,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["billingSetting", billingSetting.id] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Reguła dodana." /> });
        form.reset();
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  if (!billingSettingPolicy.create()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <DialogTrigger asChild>
        <Button variant="outline" title="Dodaj nową regułę" leftIcon={<Plus size={20} />} />
      </DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Dodaj nową regułę")}</DialogTitle>
          <DialogDescription>{billingSetting.name}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            <FormSelect label="Status" options={statusOptions} name="contract_status_id" autoComplete="off" />
            <FormSelect
              options={[
                { name: "Kwotowe", value: "amount" },
                { name: "Procentowe", value: "percentage" },
              ]}
              name="type"
              label="Rodzaj rozliczenia"
            />
            {type === "percentage" && (
              <FormNumberField label="Wartość procentowa prowizji" placeholder="Np. 10%" name="percentage" />
            )}
            {type === "amount" && (
              <>
                <FormNumberField label="Kwota" placeholder="Np. 1000zł" name="amount" />
                <FormCheckbox
                  name="from_comission"
                  label="Wartość ma być brana z prowizji"
                  description="Jeśli opcja jest zaznaczona to wartość rozliczenia będzie się liczyć z prowizji na umowie"
                />
              </>
            )}
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Dodaj"
              leftIcon={<Plus size={20} />}
              isLoading={createBillingSettingRuleMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
