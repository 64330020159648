import { Button } from "@/components/Button";
import { EmptyState } from "@/components/EmptyState";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import i18n from "@/i18n";
import { useInstallationCrewPolicy } from "@/policies/installation/useInstallationCrewPolicy";
import { routes } from "@/routes";
import { Link } from "react-router-dom";

export const InstallationServiceCrewCard = ({ installationService }) => {
  const installationServiceCrewPolicy = useInstallationCrewPolicy();

  return (
    <Card className="flex-1 h-fit shadow-md pr-2">
      <CardHeader>
        <CardTitle>{i18n.t("Ekipa montażowa")}</CardTitle>
        <CardDescription>{i18n.t("Ekipa obsługująca ten serwis")}</CardDescription>
      </CardHeader>
      <CardContent>
        {installationService?.installationCrew && (
          <Card className="p-4 shadow-md border-l-4 border-l-primary">
            <div className="flex flex-row justify-between items-start w-full gap-3">
              <div className="flex flex-col gap-2 items-center sm:items-start w-full whitespace-nowrap">
                {installationServiceCrewPolicy.view() ? (
                  <Link to={`${routes.installationCrews}/${installationService.installationCrew.id}`}>
                    <Button
                      variant="link"
                      title={installationService.installationCrew.name}
                      className="m-0 p-0 h-fit text-lg font-semibold"
                    />
                  </Link>
                ) : (
                  <span className="font-semibold text-lg">{installationService.installationCrew.name}</span>
                )}
                <div className="text-muted-foreground">{installationService.installationCrew.phone_number}</div>
                <div className="text-muted-foreground">{installationService.installationCrew.email}</div>
              </div>
            </div>
          </Card>
        )}
        {!installationService.installationCrew && <EmptyState title="Serwis nie posiada przypisanej ekipy" />}
      </CardContent>
    </Card>
  );
};
