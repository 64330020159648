import apiClient from "@/api/apiClient";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { Layout } from "@/components/layout/Layout";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { WarehouseInformationCard } from "@/components/Warehouse/WarehouseDetails/WarehouseInformationCard";
import { WarehouseOperationsTable } from "@/components/Warehouse/WarehouseDetails/WarehouseOperationsTable";
import { WarehouseProductsTable } from "@/components/Warehouse/WarehouseDetails/WarehouseProductsTable";
import { WarehouseEditDialog } from "@/components/Warehouse/WarehouseEditDialog";
import { WarehouseProductCreateDialog } from "@/components/WarehouseProduct/WarehouseProductCreateDialog";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import i18n from "@/i18n";
import { useWarehouseOperationPolicy } from "@/policies/warehouse/useWarehouseOperationPolicy";
import { useWarehousePolicy } from "@/policies/warehouse/useWarehousePolicy";
import { useWarehouseProductPolicy } from "@/policies/warehouse/useWarehouseProductPolicy";
import { routes } from "@/routes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { PackagePlus, Pencil, Trash } from "lucide-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const WarehouseDetailsPage = () => {
  const { id: warehouseId } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const warehousePolicy = useWarehousePolicy();
  const warehouseProductPolicy = useWarehouseProductPolicy();
  const warehouseOperationsPolicy = useWarehouseOperationPolicy();

  const [activeTab, setActiveTab] = useState("information");

  const queryClient = useQueryClient();

  const { isLoading, data: response } = useQuery({
    queryKey: ["warehouse", warehouseId],
    queryFn: () => apiClient.getWarehouse(warehouseId),
  });
  useDocumentTitle(response?.data ? `Magazyn ${response?.data?.name}` : null);

  const deleteWarehouseMutation = useMutation({
    mutationFn: apiClient.deleteWarehouse,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["warehouses"] });
        return navigate(routes.warehouses);
      }
    },
  });

  const handleDelete = () => {
    if (warehousePolicy.destroy(response.data)) {
      deleteWarehouseMutation.mutate(warehouseId);
    }
  };

  const handleCreateProducts = () => {
    return navigate(`${routes.warehouses}/${response.data.id}/dodaj-produkty`, { state: { warehouse: response.data } });
  };

  useEffect(() => {
    if (state?.tab) {
      setActiveTab(state.tab);
    }
  }, [state]);

  return (
    <Layout isLoading={isLoading} response={response}>
      {response && !isLoading && (
        <>
          <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
            <Breadcrumbs idAlias={response.data.name} />
            <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
              {activeTab === "information" && (
                <WarehouseEditDialog
                  trigger={<Button variant="outline" title="Edytuj" leftIcon={<Pencil size={16} />} />}
                  warehouse={response.data}
                />
              )}
              {activeTab === "information" && warehousePolicy.destroy(response.data) && (
                <DeleteAlertDialog
                  trigger={
                    <Button
                      variant="destructive"
                      title="Usuń magazyn"
                      isLoading={deleteWarehouseMutation.isPending}
                      leftIcon={<Trash size={16} />}
                    />
                  }
                  onConfirm={handleDelete}
                  message="Czy na pewno chcesz usunąć ten magazyn? Tej operacji nie można cofnąć"
                />
              )}
              {activeTab === "products" && <WarehouseProductCreateDialog warehouse={response.data} />}
              {activeTab === "products" && (
                <Button
                  variant="outline"
                  title="Dodaj wiele produktów"
                  onClick={handleCreateProducts}
                  leftIcon={
                    <>
                      <PackagePlus size={16} />
                      <PackagePlus size={16} />
                    </>
                  }
                />
              )}
            </div>
          </div>
          <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value)}>
            {warehouseProductPolicy.viewAny() && (
              <TabsList className="mb-6">
                <TabsTrigger value="information">{i18n.t("Informacje")}</TabsTrigger>
                <TabsTrigger value="products">{i18n.t("Produkty")}</TabsTrigger>
                <TabsTrigger value="operations">{i18n.t("Operacje")}</TabsTrigger>
              </TabsList>
            )}
            <TabsContent value="information">
              <div className="flex flex-col lg:flex-row w-full gap-5">
                <WarehouseInformationCard warehouse={response.data} />
              </div>
            </TabsContent>
            {warehouseProductPolicy.viewAny() && (
              <TabsContent value="products">
                <div className="flex flex-col lg:flex-row w-full gap-5">
                  <WarehouseProductsTable warehouse={response.data} />
                </div>
              </TabsContent>
            )}
            {warehouseOperationsPolicy.viewAny() && (
              <TabsContent value="operations">
                <div className="flex flex-col lg:flex-row w-full gap-5">
                  <WarehouseOperationsTable />
                </div>
              </TabsContent>
            )}
          </Tabs>
        </>
      )}
    </Layout>
  );
};
