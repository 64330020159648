import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { EmptyState } from "@/components/EmptyState";
import { FullPageLoader } from "@/components/FullPageLoader";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { UserTooltip } from "@/components/Users/UserTooltip";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { useQuery } from "@tanstack/react-query";
import { format, parse } from "date-fns";
import { Link, useParams } from "react-router-dom";

export const ClientSurveyEntriesTab = () => {
  const { id: clientId } = useParams();

  const { isLoading, data: surveyEntries } = useQuery({
    queryKey: ["client", clientId, "surveyEntires"],
    queryFn: () => apiClient.getClientSurveyEntries(clientId),
  });

  const groupedSurveyEntries = surveyEntries?.data?.reduce((groups, entry) => {
    const calculationName = entry.calculation.name || "Brak nazwy kalkulacji";
    if (!groups[calculationName]) {
      groups[calculationName] = [];
    }
    groups[calculationName].push(entry);
    return groups;
  }, {});

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <div className="w-full grid grid-cols-1 gap-5">
      {surveyEntries?.data?.length === 0 && <EmptyState title="Brak ankiet" />}
      {groupedSurveyEntries &&
        Object.entries(groupedSurveyEntries).map(([calculationName, entries]) => (
          <Card className="shadow-md" key={calculationName}>
            <CardHeader>
              <CardTitle>{calculationName}</CardTitle>
              <CardDescription>{i18n.t("Wypełnione ankiety")}</CardDescription>
            </CardHeader>
            <CardContent className="flex flex-col gap-3 items-start w-full">
              <div className="flex flex-row gap-3 items-center font-semibold text-sm text-muted-foreground w-full">
                <div className="grid grid-cols-4 items-end gap-3 w-full">
                  <span>{i18n.t("Ankieta")}</span>
                  <span>{i18n.t("Wypełniona przez")}</span>
                  <span>{i18n.t("Data wypełnienia")}</span>
                  <span>{i18n.t("Data podpisania")}</span>
                </div>
              </div>
              {entries.map((surveyEntry) => (
                <div className="grid grid-cols-4 items-end gap-3 w-full font-medium" key={surveyEntry.id}>
                  <Link
                    to={`${routes.calculations}/${surveyEntry.calculation.id}/ankiety`}
                    state={{ tab: surveyEntry.survey.id }}
                    className="w-fit"
                  >
                    <Button
                      title={surveyEntry.survey.name}
                      variant="link"
                      className="m-0 p-0 h-fit truncate max-w-[100px] sm:max-w-[200px] xl:max-w-[500px]"
                    />
                  </Link>
                  <div>
                    <UserTooltip user={surveyEntry.user} />
                  </div>
                  <div>
                    {format(parse(surveyEntry.created_at, "yyyy-MM-dd HH:mm:ss", new Date()), "dd MMM yyyy HH:mm")}
                  </div>
                  <div>
                    {surveyEntry.signature ? (
                      <span>
                        {format(
                          parse(surveyEntry.signature.created_at, "yyyy-MM-dd HH:mm:ss", new Date()),
                          "dd MMM yyyy HH:mm",
                        )}
                      </span>
                    ) : (
                      i18n.t("-")
                    )}
                  </div>
                </div>
              ))}
            </CardContent>
          </Card>
        ))}
    </div>
  );
};
