import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { AreaFileUploadInput } from "@/components/forms/AreaFileUploadInput";
import { Text } from "@/components/Text";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { useProductPolicy } from "@/policies/useProductPolicy";
import { routes } from "@/routes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FileInput, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const ProductImportForm = () => {
  const productPolicy = useProductPolicy();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [files, setFiles] = useState(null);

  const importProductsMutation = useMutation({
    mutationFn: apiClient.importProducts,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["products"] });
      if (res.ok) {
        setFiles([]);
        toast({
          title: (
            <SuccessToast title="Zlecenie importu zostało wysłane na serwer, otrzymasz powiadomienie gdy import się zakończy." />
          ),
        });
        return navigate(routes.products);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const handleSubmit = () => {
    if (files.length) {
      const data = new FormData();
      data.append("products", files[0]);
      importProductsMutation.mutate({ data });
    }
  };

  useEffect(() => {
    if (!productPolicy.importProducts()) return navigate(-1);
  }, []);

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <Text className="font-semibold" translate="Instrukcja importu" />
        </CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-5">
        <div className="flex flex-col gap-3">
          <Text className="font-semibold" translate="1. Wykonaj eksport plików na stronie z produktami." />
        </div>
        <div className="flex flex-col gap-3">
          <Text className="font-semibold" translate="2. Wprowadź zmiany w pliku z eksportem." />
          <div>
            <Text className="font-semibold mb-2" translate="Zwróć uwagę:" />
            <ul className="list-disc pl-8 space-y-2">
              <li>
                <Text translate="Nazwy kolumn (nagłówki np. SKU, Nazwa, Widoczny itd.) nie mogą być zmieniane." />
              </li>
              <li>
                <Text translate="Produkty powiązane muszą być zapisane w formie SKU i oddzielone średnikiem." />
              </li>
              <li>
                <Text translate="Jeśli chcesz dodać też nowe produkty to pole SKU pozostaw puste, zostanie ono automatycznie wygenerowane." />
              </li>
              <li>
                <Text translate={`Pole z ceną powinno być w formie samej liczby np. 1000 a nie "1000zł".`} />
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <Text className="font-semibold" translate="3. Wyślij plik w celu zaimportowania." />
          <div className="w-1/3 pl-3">
            <AreaFileUploadInput multiple={false} onFilesSelect={setFiles} accept={{ ".csv": [] }} />
          </div>
        </div>
      </CardContent>
      <CardFooter className="gap-3 items-center border-t px-6 py-4">
        <Button
          title="Importuj"
          leftIcon={<FileInput size={20} />}
          onClick={handleSubmit}
          isLoading={importProductsMutation.isPending}
        />
        <Button
          title="Anuluj"
          type="button"
          leftIcon={<X size={20} />}
          onClick={() => navigate(routes.products)}
          variant="destructive"
        />
      </CardFooter>
    </Card>
  );
};
