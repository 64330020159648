import apiClient from "@/api/apiClient";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { CalculationDetails } from "@/components/Calculations/CalculationDetails/CalculationDetails";
import { Layout } from "@/components/layout/Layout";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useLeadCalculationPolicy } from "@/policies/lead/useLeadCalculationPolicy";
import { useCalculationPolicy } from "@/policies/useCalculationPolicy";
import { routes } from "@/routes";
import { useQuery } from "@tanstack/react-query";
import { saveAs } from "file-saver";
import { ClipboardPen, FileDown } from "lucide-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const CalculationDetailsPage = () => {
  const { id: calculationId } = useParams();
  const [offerState, setOfferState] = useState("idle");
  const leadCalculationPolicy = useLeadCalculationPolicy();
  const calculationPolicy = useCalculationPolicy();
  const navigate = useNavigate();

  const { isLoading: isLoadingCalculation, data: calculation } = useQuery({
    queryKey: ["calculation", calculationId],
    queryFn: () => apiClient.getCalculation(calculationId),
  });
  useDocumentTitle(calculation?.data ? `Kalkulacja ${calculation.data.name}` : null);

  const handleDownloadPdf = async () => {
    setOfferState(() => "loading");
    const response = await apiClient.downloadCalculationOffer(calculation?.data?.id);
    if (response.ok) {
      saveAs(response?.data, calculation?.data?.name.replace("Kalkulacja", "Oferta") + ".pdf");
      toast({ title: <SuccessToast title="Oferta została pobrana" /> });
    } else {
      toast({ title: <ErrorToast title="Błąd podczas pobierania oferty." /> });
    }
    setOfferState(() => "idle");
  };

  const handleRedirectToSurveys = () => {
    return navigate(`${routes.calculations}/${calculationId}/ankiety`);
  };

  return (
    <Layout isLoading={isLoadingCalculation}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-8">
        <Breadcrumbs idAlias={calculation?.data?.name} noParent={true} />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {calculationPolicy.view(calculation?.data) && (
            <Button
              leftIcon={<FileDown size={20} />}
              title="Pobierz PDF"
              variant="outline"
              onClick={handleDownloadPdf}
              isLoading={offerState === "loading"}
            />
          )}
          {calculationPolicy.view(calculation?.data) && calculation?.data?.surveys_count > 0 && (
            <Button
              title="Ankiety"
              variant="outline"
              leftIcon={<ClipboardPen size={20} />}
              onClick={handleRedirectToSurveys}
            />
          )}
        </div>
      </div>
      {leadCalculationPolicy.viewAny() && <CalculationDetails calculation={calculation?.data} />}
    </Layout>
  );
};
