import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCheckbox } from "@/components/forms/FormCheckbox";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { ContractProductContext } from "@/context/ContractProductContext";
import { FileCategoryContext } from "@/context/FileCategoryContext";
import { appendEmptyOption } from "@/helpers/appendEmptyOption";
import i18n from "@/i18n";
import { useContractFileCategoryPolicy } from "@/policies/contracts/useContractFileCategoryPolicy";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Plus, X } from "lucide-react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

const validationSchema = Yup.object({
  file_category_id: Yup.string().required(i18n.t("Pole jest wymagane")),
  contract_product_id: Yup.string().optional().nullable(),
  required: Yup.boolean().required(i18n.t("Pole jest wymagane")),
});

export const ContractFileCategoryCreateDialog = () => {
  const queryClient = useQueryClient();
  const contractFileCategoryPolicy = useContractFileCategoryPolicy();
  const { categoryOptions, isLoading: isLoadingCategories } = useContext(FileCategoryContext);
  const { productOptions, isLoading: isLoadingProducts } = useContext(ContractProductContext);

  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = (data) => {
    createContractFileCategoryMutation.mutate({ data });
  };

  const defaultValues = {
    file_category_id: undefined,
    contract_product_id: undefined,
    required: false,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "all",
  });

  const createContractFileCategoryMutation = useMutation({
    mutationFn: apiClient.createContractFileCategory,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["contractFileCategories"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Kategoria dodana." /> });
        form.reset();
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  if (!contractFileCategoryPolicy.create()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <DialogTrigger asChild>
        <Button variant="outline" title="Dodaj kategorię" leftIcon={<Plus size={20} />} />
      </DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Dodaj kategorie pliku do tworzenia umów")}</DialogTitle>
          <DialogDescription>{i18n.t("Dodawanie nowego kategorii plików do tworzenia umowy.")}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            <FormCombobox
              label="Kategoria"
              name="file_category_id"
              options={categoryOptions}
              isLoading={isLoadingCategories}
            />
            <FormCombobox
              label="Produkt"
              description="Produkt umowy przy którym ta kategoria występuje"
              name="contract_product_id"
              options={appendEmptyOption(productOptions, "-")}
              isLoading={isLoadingProducts}
            />
            <FormCheckbox name="required" label="Kategoria jest wymagana" />
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Dodaj"
              leftIcon={<Plus size={20} />}
              isLoading={createContractFileCategoryMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
