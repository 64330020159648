import { InstallationServiceCrewCard } from "@/components/Installations/InstallationDetails/Services/InstallationServiceCrewCard";
import { InstallationServiceInformationCard } from "@/components/Installations/InstallationDetails/Services/InstallationServiceInformationCard";
import { InstallationServiceNotesCard } from "@/components/Installations/InstallationDetails/Services/InstallationServiceNotesCard";
import { useInstallationCrewPolicy } from "@/policies/installation/useInstallationCrewPolicy";
import { useInstallationServiceNotePolicy } from "@/policies/installation/useInstallationServiceNotePolicy";

export const InstallationServiceDetails = ({ installationService }) => {
  const installationNotePolicy = useInstallationServiceNotePolicy();
  const installationCrewPolicy = useInstallationCrewPolicy();

  return (
    <div className="flex flex-col lg:flex-row w-full gap-5">
      <InstallationServiceInformationCard installationService={installationService} />
      <div className="flex flex-1 flex-col gap-5 h-fit">
        {installationNotePolicy.viewAny() && <InstallationServiceNotesCard />}
        {installationCrewPolicy.viewAny() && <InstallationServiceCrewCard installationService={installationService} />}
      </div>
    </div>
  );
};
