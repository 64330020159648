import apiClient from "@/api/apiClient";
import { LeadFilters } from "@/components/Leads/LeadFilters";
import { LeadsMap } from "@/components/Leads/LeadsMap";
import { Table } from "@/components/Table/Table";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { useLeadsTableColumns } from "@/hooks/table/useLeadsTableColumns";
import { useLeadPolicy } from "@/policies/lead/useLeadPolicy";
import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo, useState } from "react";

export const LeadsTable = ({ showMap }) => {
  const queryClient = useQueryClient();
  const leadPolicy = useLeadPolicy();
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState({});

  const handleDelete = (leadId) => {
    deleteLeadMutation.mutate(leadId);
  };

  const deleteLeadMutation = useMutation({
    mutationFn: apiClient.deleteLead,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["leads"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie usunięto." /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const {
    isFetching,
    isLoading,
    data: response,
  } = useQuery({
    queryKey: ["leads", pagination, sorting, columnFilters],
    queryFn: () => apiClient.getLeads({ pagination, sorting, columnFilters }),
    placeholderData: keepPreviousData,
  });

  const { columns } = useLeadsTableColumns(handleDelete);
  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: isLoading ? [] : response.data,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    rowCount: response?.meta?.total ?? response?.data?.length,
    onPaginationChange: setPagination,
    manualPagination: true,
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id,
    onSortingChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: 10 });
      setSorting((old) => (updater instanceof Function ? updater(old) : updater));
    },
    onColumnFiltersChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: 10 });
      setColumnFilters((old) => (updater instanceof Function ? updater(old) : updater));
    },
    state: {
      pagination,
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  return (
    <div className="w-full flex-col flex">
      <div className="flex flex-col gap-5 w-full">
        <Table
          table={table}
          Filters={LeadFilters}
          meta={response?.meta}
          isLoading={isLoading}
          isFetching={isFetching}
        />
        {leadPolicy.viewMap() && showMap && <LeadsMap leads={response.data} />}
      </div>
    </div>
  );
};
