import mapPinClient from "@/assets/icons/mapPin/mapPinClient.svg";
import mapPinClientSelected from "@/assets/icons/mapPin/mapPinClientSelected.svg";
import { LeadPopup } from "@/components/Leads/LeadPopup";
import { LeadSelectedContext } from "@/context/LeadSelectedContext";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import { useContext } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";

export const LeadsMap = ({ leads }) => {
  const { selected } = useContext(LeadSelectedContext);
  const customIcon = new Icon({
    iconUrl: mapPinClient,
    iconSize: [38, 38],
    iconAnchor: [19, 38],
    popupAnchor: [0, -19],
  });
  const selectedCustomIcon = new Icon({
    iconUrl: mapPinClientSelected,
    iconSize: [38, 38],
    iconAnchor: [19, 38],
    popupAnchor: [0, -19],
  });

  return (
    <MapContainer center={[53.482892, 18.749418]} zoom={13}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <MarkerClusterGroup chunkedLoading>
        {leads.map((lead) => {
          if (lead.address) {
            const isSelected = !!selected.find((v) => v === lead.id);
            const { latitude, longitude } = lead.address;
            if (!latitude || !longitude) return null;
            return (
              <Marker
                key={lead.id}
                position={[parseFloat(latitude), parseFloat(longitude)]}
                icon={isSelected ? selectedCustomIcon : customIcon}
              >
                <Popup maxWidth={500}>
                  <LeadPopup lead={lead} />
                </Popup>
              </Marker>
            );
          }
        })}
      </MarkerClusterGroup>
    </MapContainer>
  );
};
