import { ContractProductContext } from "@/context/ContractProductContext";
import { ProductsContext } from "@/context/ProductsContext";
import { CalculatorFieldType } from "@/enums/CalculatorFieldTypeEnum";
import { format } from "date-fns";
import { useContext } from "react";

const getCalculationSchema = (calculation, products) => {
  if (!calculation) return {};

  const calculationFields = calculation.calculator.sections.flatMap((section) => section.fields);

  const schema = calculationFields.reduce((schema, field) => {
    let value = " ";

    switch (field.type) {
      case CalculatorFieldType.LIST.value:
        value = field.value;
        break;
      case CalculatorFieldType.PRODUCT.value: {
        const product = products.find((product) => product.id === field.value);
        value = product?.name;
        break;
      }
      case CalculatorFieldType.MULTIPLE.value:
        value = field.value.join(", ");
        break;
      case CalculatorFieldType.ADDITIONAL.value:
        value = field.value.map((val) => val.name).join(", ");
        break;
      case CalculatorFieldType.NUMBER.value:
        value = field.value;
        break;
    }

    schema[field.identifier] = () => value;

    if (field.has_quantity) {
      schema[`${field.identifier}_ilosc`] = () => field.quantity;
    }

    return schema;
  }, {});

  const customFields = calculation.calculator.customFields;
  Object.keys(customFields).forEach((key) => {
    if (!schema[key]) {
      const customField = customFields[key];
      schema[key] = () => `${customField.value} ${customField.unit}`;
    }
  });

  return schema;
};

export const useContractFillSchema = (calculation) => {
  const { productOptions: contractProductOptions, isLoading: isLoadingContractProducts } =
    useContext(ContractProductContext);
  const { products, isLoading: isLoadingProducts } = useContext(ProductsContext);

  if (isLoadingContractProducts || isLoadingProducts) return {};

  const calculationSchema = getCalculationSchema(calculation, products);

  const groupedSchema = {
    Klient: {
      imie: (values) => values.first_name,
      nazwisko: (values) => values.last_name,
      imie_nazwisko: (values) => `${values.first_name} ${values.last_name}`,
      email: (values) => values.email,
      numer_telefonu: (values) => values.phone_number,
      adres: (values) => `${values.street_number}, ${values.postal_code} ${values.city}`,
      ulica_numer: (values) => values.street_number,
      kod_pocztowy: (values) => values.postal_code,
      miasto: (values) => values.city,
      wojewodztwo: (values) => values.voivodeship,
      pesel: (values) => values.personal_identity_number,
      numer_umowy: (values) => values.identifier,
      data_podpisania: (values) => format(values.signed_at, "dd.MM.YYYY"),
      produkty: (values) =>
        values.contract_product_ids
          .map((id) => contractProductOptions.find((product) => product.value === id)?.name)
          .filter(Boolean)
          .join(", "),
    },
    Płatność: {
      wartosc_netto: (values) => values.net_price,
      wartosc_brutto: (values) => values.gross_price,
      vat: (values) => `${values.vat}%`,
      typ_platnosci: (values) => values.payment_type,
      liczba_transz: (values) => values.tranche_count,
      transza_pierwsza: (values) => values.tranches[0] || "-",
      transza_druga: (values) => values.tranches[1] || "-",
      transza_trzecia: (values) => values.tranches[2] || "-",
      wplata_wlasna_klienta: (values) => values.down_payment || "-",
    },
    Kalkulacja: calculationSchema,
  };
  const flatSchema = Object.assign({}, ...Object.values(groupedSchema));

  return { flatSchema, groupedSchema };
};
