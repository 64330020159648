import { InstallationStatusCreateDialog } from "@/components/Installations/Settings/Statuses/InstallationStatusCreateDialog";
import { InstallationStatuses } from "@/components/Installations/Settings/Statuses/InstallationStatuses";
import { InstallationTypeCreateDialog } from "@/components/Installations/Settings/Types/InstallationTypeCreateDialog";
import { InstallationTypes } from "@/components/Installations/Settings/Types/InstallationTypes";
import { ProcessCreateDialog } from "@/components/Process/ProcessCreateDialog";
import { ProcessSettings } from "@/components/Process/ProcessSettings";
import { SettingsTable } from "@/components/Settings/SettingsTable";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { SettingGroup } from "@/enums/SettingGroupEnum";
import { useSettings } from "@/hooks/useSettings";
import i18n from "@/i18n";
import { useInstallationStatusPolicy } from "@/policies/installation/useInstallationStatusPolicy";
import { useInstallationTypePolicy } from "@/policies/installation/useInstallationTypePolicy";
import { useProcessPolicy } from "@/policies/useProcessPolicy";
import { useSettingPolicy } from "@/policies/useSettingPolicy";
import { useState } from "react";

export const InstallationSettings = () => {
  const [activeTab, setActiveTab] = useState("statuses");
  const { getSettingGroup } = useSettings();
  const installationStatusPolicy = useInstallationStatusPolicy();
  const installationTypePolicy = useInstallationTypePolicy();
  const processPolicy = useProcessPolicy();
  const settingPolicy = useSettingPolicy();

  return (
    <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value)}>
      <TabsList className="mb-6">
        {installationStatusPolicy.create() && <TabsTrigger value="statuses">{i18n.t("Statusy")}</TabsTrigger>}
        {installationTypePolicy.create() && <TabsTrigger value="types">{i18n.t("Typy")}</TabsTrigger>}
        {processPolicy.create() && <TabsTrigger value="processes">{i18n.t("Procesy")}</TabsTrigger>}
        {settingPolicy.update() && <TabsTrigger value="settings">{i18n.t("Pozostałe")}</TabsTrigger>}
      </TabsList>
      {installationStatusPolicy.create() && (
        <TabsContent value="statuses">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Statusy")}</h2>
            <InstallationStatusCreateDialog />
          </div>
          <InstallationStatuses />
        </TabsContent>
      )}
      {installationTypePolicy.create() && (
        <TabsContent value="types">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Typy")}</h2>
            <InstallationTypeCreateDialog />
          </div>
          <InstallationTypes />
        </TabsContent>
      )}
      {processPolicy.create() && (
        <TabsContent value="processes">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Procesy")}</h2>
            <ProcessCreateDialog appliesTo="Installation" />
          </div>
          <ProcessSettings model="Installation" />
        </TabsContent>
      )}
      {settingPolicy.update() && (
        <TabsContent value="settings">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Pozostałe ustawienia")}</h2>
          </div>
          <SettingsTable settings={getSettingGroup(SettingGroup.INSTALLATION)} />
        </TabsContent>
      )}
    </Tabs>
  );
};
