import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { EditableCell } from "@/components/Table/EditableCell";
import { EditableColorCell } from "@/components/Table/EditableColorCell";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import i18n from "@/i18n";
import { useContractStatusPolicy } from "@/policies/contracts/useContractStatusPolicy";
import { ArrowDown, ArrowUp, ArrowUpDown, MoreHorizontal, Trash } from "lucide-react";

export const useContractStatusesTableColumns = (handleEdit, handleDelete) => {
  const contractStatusPolicy = useContractStatusPolicy();
  const columns = [
    {
      id: "name",
      name: "Nazwa statusu",
      size: 10,
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Nazwa statusu")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const contractStatus = row.original;
        const onSave = (name) => {
          handleEdit(contractStatus.id, { name });
        };

        if (contractStatusPolicy.updateName(contractStatus)) {
          return <EditableCell value={contractStatus.name} onSave={onSave} />;
        } else {
          return (
            <div className="flex-1 ms-3 truncate" title={row.original.name}>
              {row.getValue("name")}
            </div>
          );
        }
      },
    },
    {
      id: "color",
      name: "Kolor",
      accessorKey: "color",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Kolor")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const contractStatus = row.original;
        const onSave = (color) => {
          handleEdit(contractStatus.id, { color });
        };

        const formatter = (color) => {
          return <span className="h-5 w-5" style={{ backgroundColor: color }}></span>;
        };

        if (contractStatusPolicy.update()) {
          return <EditableColorCell type="color" value={contractStatus.color} onSave={onSave} id={contractStatus.id} />;
        } else return contractStatus.color ? formatter(contractStatus.color) : i18n.t("Brak");
      },
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const contractStatus = row.original;
        if (!contractStatusPolicy.destroy(contractStatus)) return null;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {contractStatusPolicy.destroy(contractStatus) && (
                <DeleteAlertDialog
                  onConfirm={() => handleDelete(contractStatus.id)}
                  message="Czy na pewno chcesz usunąć ten status? Tej operacji nie można cofnąć"
                  trigger={
                    <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                      <Trash size={16} className="mr-1 text-primary" />
                      <span className="font-semibold">{i18n.t("Usuń")}</span>
                    </DropdownMenuItem>
                  }
                />
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
