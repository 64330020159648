import { ClipboardList, Mail, MessageSquare, Phone, User, Wrench } from "lucide-react";

export const ActionType = Object.freeze({
  PHONE_CALL: {
    value: "PHONE_CALL",
    name: "Telefon",
    color: "bg-sky-500 hover:bg-sky-500/90",
    icon: (props) => <Phone {...props} />,
  },
  MEETING: {
    value: "MEETING",
    name: "Spotkanie",
    color: "bg-green-500 hover:bg-green-500/90",
    icon: (props) => <User {...props} />,
  },
  EMAIL: {
    value: "EMAIL",
    name: "Email",
    color: "bg-yellow-500 hover:bg-yellow-500/90",
    icon: (props) => <Mail {...props} />,
  },
  SMS: {
    value: "SMS",
    name: "Sms",
    color: "bg-lime-500 hover:bg-lime-500/90",
    icon: (props) => <MessageSquare {...props} />,
  },
  AUDIT_EVENT: {
    value: "AUDIT_EVENT",
    name: "Działanie audytu",
    color: "bg-red-500 hover:bg-red-500/90",
    icon: (props) => <ClipboardList {...props} />,
  },
  INSTALLATION_EVENT: {
    value: "INSTALLATION_EVENT",
    name: "Działanie montażu",
    color: "bg-pink-500 hover:bg-pink-500/90",
    icon: (props) => <Wrench {...props} />,
  },

  getValues() {
    return Object.values(this).filter((value) => typeof value === "object" && value !== null);
  },

  getNames() {
    return this.getValues().map((status) => status.name);
  },

  getForLead() {
    return [this.PHONE_CALL, this.MEETING, this.EMAIL, this.SMS];
  },

  getForClient() {
    return [this.PHONE_CALL, this.MEETING, this.EMAIL, this.SMS];
  },
});
