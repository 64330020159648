import { FormCheckboxGroup } from "@/components/forms/FormCheckboxGroup";
import { FormField } from "@/components/forms/FormField";
import { FormSelect } from "@/components/forms/FormSelect";
import { FormSketchCanvas } from "@/components/forms/FormSketchCanvas";
import { FormTextarea } from "@/components/forms/FormTextarea";
import { sanitizeSchema } from "@/constants/markdownSanitizeSchema";
import { SurveyContext } from "@/context/SurveyContext";
import { SurveyQuestionType } from "@/enums/SurveyQuestionTypeEnum";
import { useContext } from "react";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";

export const SurveyQuestion = ({ question }) => {
  const { survey } = useContext(SurveyContext);

  const renderFieldByType = (question) => {
    let isDisabled = survey.entries?.length > 0;
    switch (question.type) {
      case SurveyQuestionType.LIST.value: {
        let options = question.question_options.map((option) => ({
          name: option.name,
          value: option.name,
          default: option.default,
        }));
        if (!question.required) {
          options = [{ name: "-", value: "-" }, ...options];
        }
        return (
          <FormSelect
            disabled={isDisabled}
            options={options}
            defaultValue={options.find((option) => option.default)?.name ?? undefined}
            label={question.name}
            name={question.identifier}
            description={question.description}
          />
        );
      }
      case SurveyQuestionType.INPUT.value:
        return (
          <FormField
            disabled={isDisabled}
            label={question.name}
            name={question.identifier}
            description={question.description}
          />
        );
      case SurveyQuestionType.CHECKBOX.value: {
        let options = question.question_options.map((option) => ({
          name: option.name,
          value: option.name,
          default: option.default,
        }));
        if (!question.required) {
          options = [{ name: "-", value: "-" }, ...options];
        }
        return (
          <FormCheckboxGroup
            disabled={isDisabled}
            options={options}
            defaultValue={options.filter((option) => !!option.default).map(({ value }) => value)}
            label={question.name}
            description={question.description}
            name={question.identifier}
          />
        );
      }
      case SurveyQuestionType.DRAWING.value: {
        return (
          <FormSketchCanvas
            disabled={isDisabled}
            name={question.identifier}
            description={question.description}
            label={question.name}
          />
        );
      }
      case SurveyQuestionType.TEXTAREA.value: {
        return (
          <FormTextarea
            disabled={isDisabled}
            name={question.identifier}
            description={question.description}
            label={question.name}
          />
        );
      }
      case SurveyQuestionType.PLAIN_TEXT.value: {
        return (
          <Markdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, [rehypeSanitize, sanitizeSchema]]}>
            {question.description}
          </Markdown>
        );
      }
      default:
        return null;
    }
  };

  const renderField = (question) => {
    const renderedField = renderFieldByType(question);
    if (renderedField) {
      return (
        <div className="flex flex-row items-start gap-3">
          <div className="flex-1">{renderedField}</div>
        </div>
      );
    }
  };

  return renderField(question);
};
