import { ErrorMessage } from "@/components/forms/ErrorMessage";
import { SketchCanvas } from "@/components/forms/SketchCanvas";
import { Label } from "@/components/ui/label";
import i18n from "@/i18n";
import { Controller, useFormContext } from "react-hook-form";

export const FormSketchCanvas = ({ name, label, description, disabled, ...rest }) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <div className="grid gap-2">
          <Label className={errors[name] && "text-destructive"} htmlFor={name}>
            {i18n.t(label)}
          </Label>
          <SketchCanvas id={name} onChange={onChange} value={value} disabled={disabled} {...rest} />
          {description && (
            <span className="text-sm text-muted-foreground mt-1">
              {typeof description === "string" ? i18n.t(description) : description}
            </span>
          )}
          <ErrorMessage error={errors[name]?.message} />
        </div>
      )}
    />
  );
};
