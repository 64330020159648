import { Input as ShadInput } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import i18n from "@/i18n";
import { forwardRef } from "react";

const Input = forwardRef(({ name, description, isError, placeholder, label, className, ...rest }, ref) => {
  return (
    <div className="w-full">
      <div className="grid gap-2">
        {label && (
          <Label htmlFor={name} className={isError ? "text-destructive" : undefined}>
            {i18n.t(label)}
          </Label>
        )}
        <ShadInput
          ref={ref}
          name={name}
          id={name}
          placeholder={placeholder ? i18n.t(placeholder) : i18n.t(label)}
          className={className}
          {...rest}
        />
        {description && (
          <span className="text-sm text-muted-foreground">
            {typeof description === "string" ? i18n.t(description) : description}
          </span>
        )}
      </div>
    </div>
  );
});

Input.displayName = "Input";

export { Input };
