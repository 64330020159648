import { Button } from "@/components/Button";
import { Badge } from "@/components/ui/badge";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { Check, CirclePlus } from "lucide-react";

export function MultipleSelectFilter({
  value,
  setValue,
  title,
  options,
  className,
  disabled = false,
  isLoading = false,
  ...rest
}) {
  const selectedValues = new Set(value);

  const handleSelect = (optionValue) => {
    const newSelectedValues = new Set(selectedValues);
    if (newSelectedValues.has(optionValue)) {
      newSelectedValues.delete(optionValue);
    } else {
      newSelectedValues.add(optionValue);
    }
    setValue(Array.from(newSelectedValues));
  };

  if (!options || options?.length === 0) return null;

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className={cn(selectedValues.size > 0 ? "border-primary" : "", "flex-1 lg:flex-initial", className)}
          disabled={disabled || isLoading}
          isLoading={isLoading}
        >
          <>
            {selectedValues.size === 0 && <CirclePlus className="h-4 w-4" />}
            {title}
            {selectedValues?.size > 0 && (
              <>
                <Separator orientation="vertical" className="h-4" />
                <Badge variant="secondary" className="rounded-sm px-1 font-normal lg:hidden">
                  {selectedValues.size}
                </Badge>
                <div className="hidden space-x-1 lg:flex">
                  {selectedValues.size > 2 ? (
                    <Badge
                      variant="secondary"
                      className="rounded-full flex flex-row justify-center h-5 w-5 items-center px-1 font-normal"
                    >
                      {selectedValues.size}
                    </Badge>
                  ) : (
                    options
                      .filter((option) => selectedValues.has(option.value))
                      .map((option) => (
                        <Badge variant="secondary" key={option.value} className="rounded-sm px-1 font-normal">
                          {option.name}
                        </Badge>
                      ))
                  )}
                </div>
              </>
            )}
          </>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0" align="start">
        <Command
          filter={(v, search) => {
            const item = options.find((item) => item.value === v);
            if (!item) return 0;
            if (item.name.toLowerCase().includes(search.toLowerCase())) return 1;
          }}
          {...rest}
          onChange={() => {}}
        >
          <CommandInput placeholder={i18n.t("Wyszukaj...")} />
          <CommandList>
            <CommandEmpty>{i18n.t("Brak wyników")}</CommandEmpty>
            <CommandGroup>
              {options.map((option) => {
                const isSelected = selectedValues.has(option.value);
                return (
                  <CommandItem key={option.value} onSelect={() => handleSelect(option.value)}>
                    <div
                      className={cn(
                        "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                        isSelected ? "bg-primary text-primary-foreground" : "opacity-50 [&_svg]:invisible",
                      )}
                    >
                      <Check className={cn("h-4 w-4")} />
                    </div>
                    {option.icon && <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />}
                    <span>{option.content ? option.content : option.name}</span>
                  </CommandItem>
                );
              })}
            </CommandGroup>
            {selectedValues.size > 0 && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem onSelect={() => setValue([])} className="justify-center text-center">
                    {i18n.t("Wyczyść")}
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
