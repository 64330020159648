/* eslint-disable no-unused-vars */
import apiClient from "@/api/apiClient";
import { useAuth } from "@/hooks/useAuth";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createContext, useEffect, useState } from "react";

export const SettingsContext = createContext();

export function SettingsProvider({ children }) {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const [settings, setSettings] = useState([]);

  const {
    isLoading,
    data: response,
    error,
  } = useQuery({
    queryKey: ["settings"],
    queryFn: apiClient.getSettings,
    enabled: user !== null,
  });

  const updateSettingMutation = useMutation({
    mutationFn: apiClient.createSetting,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["settings"] });
    },
  });

  const getSetting = (key, defaultValue = null) => {
    const setting = settings.find((setting) => setting.key === key);
    return setting?.value ?? defaultValue;
  };

  const setSetting = (key, value) => {
    updateSettingMutation.mutate({ key, value });
  };

  const getSettingGroup = (group) => {
    return settings.filter((setting) => setting.group === group);
  };

  useEffect(() => {
    if (!isLoading && !error && response) {
      setSettings(response.data);
    }
  }, [response, isLoading, error]);

  return (
    <SettingsContext.Provider value={{ settings, isLoading, getSetting, setSetting, getSettingGroup }}>
      {children}
    </SettingsContext.Provider>
  );
}
