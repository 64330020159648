import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { SurveyQuestion } from "@/components/Surveys/Configuration/Questions/SurveyQuestion";
import { SurveyQuestionCreateDialog } from "@/components/Surveys/Configuration/Questions/SurveyQuestionCreateDialog";
import { SurveySectionEditDialog } from "@/components/Surveys/Configuration/Sections/SurveySectionEditDialog";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { DndContext } from "@dnd-kit/core";
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GripVertical, Trash } from "lucide-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const SurveySection = ({ section }) => {
  const { id: surveyId } = useParams();
  const queryClient = useQueryClient();
  const [questions, setQuestions] = useState(section.questions);
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: section.id });

  const style = {
    transform: transform ? `translate(${transform.x}px, ${transform.y}px)` : undefined,
    transition,
  };

  const updateSurveyQuestionOrderMutation = useMutation({
    mutationFn: apiClient.updateSurveyQuestionOrder,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["survey", surveyId] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const deleteSurveySectionMutation = useMutation({
    mutationFn: apiClient.deleteSurveySection,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["survey", surveyId] });
        toast({ title: <SuccessToast title="Pomyślnie usunięto" /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const handleQuestionDragEnd = (event) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const oldIndex = questions.findIndex((item) => item.id === active.id);
      const newIndex = questions.findIndex((item) => item.id === over.id);
      const newQuestions = arrayMove(questions, oldIndex, newIndex);
      setQuestions(newQuestions);
      updateSurveyQuestionOrderMutation.mutate({
        surveySectionId: section.id,
        data: { question_ids: newQuestions.map((question) => question.id) },
      });
    }
  };

  const handleDelete = () => {
    deleteSurveySectionMutation.mutate({ surveyId, surveySectionId: section.id });
  };

  useEffect(() => {
    if (section) setQuestions(section.questions);
  }, [section]);

  return (
    <div ref={setNodeRef} style={style} className="flex flex-col gap-2 w-full">
      <Card className="w-full h-fit">
        <CardHeader className="flex flex-col justify-center items-center">
          <div className="flex flex-col gap-1.5">
            <CardTitle>
              <div
                className="flex flex-row gap-1 items-center cursor-move font-medium w-fit text-center"
                {...attributes}
                {...listeners}
              >
                <GripVertical size={16} className="shrink-0" />
                {section.name}
              </div>
            </CardTitle>
            <CardDescription className="text-center">{section.description}</CardDescription>
          </div>
          <div className="flex flex-row gap-2 items-start">
            <SurveySectionEditDialog section={section} />
            <DeleteAlertDialog
              message="Czy na pewno chcesz usunąć tą sekcję? Tej operacji nie można cofnąć. Pola w tej sekcji również zostaną usunięte"
              onConfirm={handleDelete}
              trigger={
                <Button
                  variant="outline"
                  title="Usuń sekcję"
                  className=""
                  leftIcon={<Trash size={16} />}
                  isLoading={deleteSurveySectionMutation.isPending}
                />
              }
            />
          </div>
        </CardHeader>
        <CardContent className="flex flex-col gap-2 justify-center">
          <DndContext onDragEnd={handleQuestionDragEnd}>
            <SortableContext items={questions.map((question) => question.id)} strategy={verticalListSortingStrategy}>
              <div className="flex flex-col divide-y divide-y-border">
                {questions.map((question) => (
                  <SurveyQuestion question={question} key={question.id} />
                ))}
              </div>
            </SortableContext>
          </DndContext>
          <SurveyQuestionCreateDialog surveySectionId={section.id} />
        </CardContent>
      </Card>
    </div>
  );
};
