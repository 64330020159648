import apiClient from "@/api/apiClient";
import { EmptyState } from "@/components/EmptyState";
import { SurveySection } from "@/components/Surveys/Configuration/Sections/SurveySection";
import { SurveySectionCreateDialog } from "@/components/Surveys/Configuration/Sections/SurveySectionCreateDialog";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { toast } from "@/components/ui/use-toast";
import { DndContext } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export const SurveyDetails = ({ survey }) => {
  const [sections, setSections] = useState(survey.sections);

  const updateSurveySectionOrderMutation = useMutation({
    mutationFn: apiClient.updateSurveySectionOrder,
    onSuccess: (res) => {
      if (!res.ok) {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const handleSectionDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = sections.findIndex((section) => section.id === active.id);
      const newIndex = sections.findIndex((section) => section.id === over.id);
      const newSections = arrayMove(sections, oldIndex, newIndex);
      setSections(newSections);
      updateSurveySectionOrderMutation.mutate({
        surveyId: survey.id,
        data: {
          section_ids: newSections.map((section) => section.id),
        },
      });
    }
  };

  useEffect(() => {
    if (survey) {
      setSections(survey.sections);
    }
  }, [survey]);

  return (
    <div className="flex-1 flex flex-col lg:flex-row gap-3 justify-center">
      <DndContext onDragEnd={handleSectionDragEnd}>
        <SortableContext items={sections?.map((section) => section.id)} strategy={verticalListSortingStrategy}>
          <div className="flex flex-col gap-4 items-center justify-center w-full lg:w-1/2">
            {sections.length === 0 && <EmptyState title="Brak sekcji" />}
            {sections?.map((section) => (
              <SurveySection section={section} key={section.id} />
            ))}
            <SurveySectionCreateDialog />
          </div>
        </SortableContext>
      </DndContext>
    </div>
  );
};
